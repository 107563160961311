<template>
  <div class="heading-element" data-cy="blog-heading-elem">
    <h1 :class="getAlignClassName(data.data.align)"
        v-text="data.data.blogTitle" />
    <div :class="getAlignClassName(data.data.align)">
      <span v-if="data.data.author"
            class="heading-element__author">
        by
        <span class="heading-element__author-name" v-text="data.data.author" />
      </span>
      <span v-if="data.data.createdAt"
            class="heading-element__created-at"
            v-text="createdAt" />
    </div>
  </div>
</template>

<script>
import alignMixin from '@landing/common/mixins/alignMixin'
import locale from '@landing/common/consts/locale'
import format from '@landing/common/utils/format'
import fromUnixTime from 'date-fns/fromUnixTime'

export default {
  name: 'BlogHeadingElement',
  mixins: [alignMixin],
  props: {
    data: Object
  },
  computed: {
    createdAt() {
      const date = fromUnixTime(this.data.data.createdAt)
      return format(date, 'yyyy 年 M 月 d 日', { locale })
    }
  }
}
</script>

<style lang="scss" scoped>
.heading-element {
  h1 {
    font-family: 'Noto Sans TC', sans-serif;
    color: #4d4d4d;
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 3.5rem;
    margin-top: 26.8px;
    margin-bottom: 26.8px;
  }
  overflow: auto;
  padding: 25px $article-horizontal-padding;
}
.heading-element__author {
  margin-right: 14px;
}
.heading-element__author-name {
  font-weight: 900;
}
.heading-element__created-at,
.heading-element__author {
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 1rem;
  color: rgb(33, 37, 41);
  font-weight: 400;
}
@media (max-width: $screen-sm) {
  .heading-element h1 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
</style>
