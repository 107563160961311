import { setOnlineStatus } from '../redux/reducers/app'

export default {
  beforeCreate() {
    this.mapDispatch({ setOnlineStatus })
  },
  mounted() {
    this.on(window, 'online', () => this.setOnlineStatus(true))
    this.on(window, 'offline', () => this.setOnlineStatus(false))
  }
}
