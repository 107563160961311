import IntlAddressBase from './Base'

class MacauAddress extends IntlAddressBase {

  constructor(props) {
    super(props)
  }
}

export default MacauAddress
