export const CLASS_NAME_ADDRESS = 'AddressElem'
export const CLASS_NAME_BLOG_HEADING = 'BlogHeadingElem'
export const CLASS_NAME_FB_PAGE = 'FbPageElem'
export const CLASS_NAME_IG_POST = 'IgPostElem'
export const CLASS_NAME_IMAGE = 'FullPhoto'
export const CLASS_NAME_IMAGE_SLIDER = 'ImageSlider'
export const CLASS_NAME_LIST = 'ListElem'
export const CLASS_NAME_PARAGRAPH = 'ParagraphElem'
export const CLASS_NAME_QUOTE = 'QuoteElem'
export const CLASS_NAME_TITLE = 'TitleElem'
export const CLASS_NAME_YOUTUBE = 'YoutubeElem'
export const CLASS_NAME_BTN = 'BtnElem'

export const VALID_CLASSNAMES = [
  CLASS_NAME_ADDRESS,
  CLASS_NAME_BLOG_HEADING,
  CLASS_NAME_FB_PAGE,
  CLASS_NAME_IG_POST,
  CLASS_NAME_IMAGE,
  CLASS_NAME_IMAGE_SLIDER,
  CLASS_NAME_LIST,
  CLASS_NAME_PARAGRAPH,
  CLASS_NAME_QUOTE,
  CLASS_NAME_TITLE,
  CLASS_NAME_YOUTUBE,
  CLASS_NAME_BTN
]

export const TYPE_NAVBAR_EMPTY = 'empty'
export const TYPE_NAVBAR_STATIC = 'static'
export const TYPE_NAVBAR_COUNTDOWN = 'countdown'

// image element
export const TYPE_IMAGE_LINK_NONE = 'none'
export const TYPE_IMAGE_LINK_SHOPPING_CART = 'cart'
export const TYPE_IMAGE_LINK_SALES_PAGE = 'page'
export const TYPE_IMAGE_LINK_STATIC_PAGE = 'static-page'

export const IMAGE_MARGIN_NONE = 'none'
export const IMAGE_MARGIN_SM = 'sm'
export const IMAGE_MARGIN_MD = 'md'
export const IMAGE_MARGIN_LG = 'lg'
export const IMAGE_MARGIN_CUSTOM = 'custom'

export const IMAGE_WIDTH_SM = 'sm'
export const IMAGE_WIDTH_MD = 'md'
export const IMAGE_WIDTH_LG = 'lg'
export const IMAGE_WIDTH_CUSTOM = 'custom'
