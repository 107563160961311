<template>
  <div v-if="futureTimestamps.length === 0" />
  <div v-else class="preorder-info" :class="`preorder-info--${mode}`">
    <app-badge class="preorder-info__badge">預購</app-badge>
    <span v-if="info" class="preorder-info__info txt-preorder" v-text="info" />
  </div>
</template>

<script>
import AppBadge from '@landing/landing-common/components/AppBadge'
import format from 'date-fns/format'
import fromUnixTime from 'date-fns/fromUnixTime'
import isFuture from 'date-fns/isFuture'
import isThisYear from 'date-fns/isThisYear'

export default {
  name: 'PreorderInfo',
  components: {
    AppBadge
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  computed: {
    futureTimestamps() {
      return (this.item.preorderShippingTimestamps || [])
        .filter(t => isFuture(fromUnixTime(t)))
    },
    info() {
      const dateList = this.futureTimestamps.slice()
        .sort((a, b) => a - b)
        .map(t =>  {
          const d = fromUnixTime(t)
          const pattern = isThisYear(d) ? 'M/d' : 'yyyy/M/d'
          return format(d, pattern)
        })
        .join(', ')
      return `預計出貨 ${dateList}`
    }
  }
}
</script>

<style lang="scss" scoped>
.preorder-info {
  display: flex;
  align-items: center;
  &.preorder-info--grid {
    display: block;
    .preorder-info__info {
      color: #555;
      margin-top: .4em;
      margin-left: 0;
    }
  }
}
.preorder-info__info {
  margin-left: .5em;
}
</style>
