<template>
  <tr class="cart-modal-list-item card" data-cy="cart-item">
    <td class="cart-modal-list-item__content">
      <cart-modal-list-item-image v-if="hasImageCol"
                                  class="cart-modal-list-item__image"
                                  :item="item" />
      <div class="cart-modal-list-item__info">
        <div class="cart-modal-list-item__heading">
          {{ heading }}
          <strong v-if="item.addtional">[加購]</strong>
          <strong v-else-if="item.isDiscountLevelItem" class="free-product-label">[滿額贈贈品]</strong>
          <strong v-else-if="item.type === 'free_product'" class="free-product-label">[贈品]</strong>
        </div>
        <div v-if="item.spec" class="cart-modal-list-item__desc">
          {{ item.spec.title }} {{ item.spec.desc }}
        </div>
        <ul v-if="item.items && (item.items.length > 0)" class="cart-modal-list-item__list">
          <li v-for="row in item.items" :key="row.id">
            <span class="cart-modal-list-item__qty">{{ row.qty }} {{ row.unitName }}</span>
            <span v-if="row.withSpec" class="cart-modal-list-item__spec">{{ row.spec.title }}</span>
            <span>{{ row.title }}</span>
            <strong v-if="row.type === 'free_product'" class="free-product-label">[贈品]</strong>
          </li>
        </ul>
      </div>
    </td>
    <td class="cart-modal-list-item__qty-cell no-wrap">{{ item.qty }} {{ unitName }}</td>
    <td class="cart-modal-list-item__subtotal no-wrap">
      <span v-if="item.subtotal === 0">-</span>
      <strong v-else>{{ dollar(item.subtotal) }}</strong>
    </td>
    <td class="cart-modal-list-item__btn-cell">
      <remove-btn v-if="canRemove" data-cy="remove-btn" @click="handleRemove" />
    </td>
  </tr>
</template>

<script>
import dollar from '@landing/landing-common/utils/dollar'
import CartModalListItemImage from './CartModalListItemImage'
import RemoveBtn from './RemoveBtn'

export default {
  name: 'CartModalListItem',
  components: {
    CartModalListItemImage,
    RemoveBtn
  },
  props: {
    item: Object,
    hasImageCol: Boolean
  },
  computed: {
    unitName() {
      switch (this.item.type) {
        case 'product':
          return this.item.unitName
        case 'promo':
          return '組'
        default:
          return ''
      }
    },
    heading() {
      const { title } = this.item
      return title
    },
    isLoading() {
      return this.item.isLoading
    },
    btnText() {
      return this.isLoading ? '刪除中...' : '刪除'
    },
    canRemove() {
      const { item } = this
      if (item.isDiscountLevelItem) {
        return false
      }
      if (item.type === 'free_product') {
        return false
      }
      return true
    }
  },
  methods: {
    dollar,
    handleRemove() {
      this.$emit('remove-item', this.item)
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-modal-list-item {
  position: relative;
  margin: 14px 14px 0 14px;
  padding: 7px;
  &:hover {
    background-color: $gray12;
  }
}
.cart-modal-list-item__content {
  display: flex;
}
.cart-modal-list-item__info {
  line-height: 1.9;
  position: relative;
  top: -5px;
}
.cart-modal-list-item__qty {
  white-space: nowrap;
  color: $blue6;
}
.cart-modal-list-item__spec {
  white-space: nowrap;
  color: $purple1;
}
.cart-modal-list-item__heading {
  font-size: 1.2em;
  color: $black3;
  strong {
    color: $green3;
  }
  .free-product-label {
    white-space: nowrap;
    color: $purple2;
  }
}
.cart-modal-list-item__subtotal {
  text-align: right;
  padding-top: 18px;
  vertical-align: top;
  strong {
    font-size: 1rem;
    color: $red1;
  }
}
.cart-modal-list-item__list {
  font-size: .8125em;
  color: $black3;
  > li {
    margin: 4px 0;
  }
}
.cart-modal-list-item__desc {
  line-height: 1.8em;
  font-size: .8125em;
  color: $purple1;
}
.cart-modal-list-item__cancel-btn {
  display: block;
  margin-right: 21px;
  border: 1px solid $white1;
  background-color: transparent;
  padding: 12px 24px;
  border-radius: 2px;
}
.cart-modal-list-item__confirm {
  padding: 14px;
  color: $white1;
  position: absolute;
  background-color: rgba(0, 0, 0, .7);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @include flex-center;
}
.cart-modal-list-item__confirm-content {
  display: flex;
}
.free-product-label {
  white-space: nowrap;
  color: $purple2;
}
td {
  padding: 14px 16px;
}
.cart-modal-list-item__qty-cell {
  vertical-align: top;
  padding-top: 16px;
}
.cart-modal-list-item__btn-cell {
  vertical-align: top;
  padding-top: 7px;
}
.cart-modal-list-item__image {
  flex-shrink: 0;
  margin-right: 14px;
}
</style>
