<template>
  <footer>
    <div class="app-footer">
      <div v-if="data.enabled"
           class="app-footer__merchant-info"
           data-cy="merchant-footer">
        <app-image v-if="siteLogoUrl" class="app-footer__merchant-logo"
                   :src="siteLogoUrl" :alt="data.companyName" />
        <ul>
          <li class="app-footer__merchant-title">{{ data.companyName }}</li>
          <li v-if="data.companyCode">統一編號 {{ data.companyCode }}</li>
          <li v-if="data.companyTel">聯絡電話 {{ data.companyTel }}</li>
          <li v-if="data.companyEmail">
            Email
            <a class="app-footer__anchor"
               data-cy="mail-to-link"
               :href="`mailto:${data.companyEmail}`">{{ data.companyEmail }}</a>
          </li>
        </ul>
      </div>
      <div v-if="hasCopyright" class="app-footer__landing-info" data-cy="landing-footer">
        <img class="app-footer__logo"
             src="../assets/images/sr-brand-logo-black.svg"
             alt="一頁商店">
        <ul>
          <li>本系統由<a href="https://www.super-landing.com/"><strong>一頁商店</strong></a>維護 © {{ year }}</li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import AppImage from './AppImage'

export default {
  name: 'AppFooter',
  components: {
    AppImage
  },
  props: {
    page: Object
  },
  computed: {
    year() {
      return new Date().getFullYear()
    },
    data() {
      const { site } = this.page
      return site ? site.footer : {}
    },
    siteLogoUrl() {
      const { site } = this.page
      return site && site.logoUrl.desktop
    },
    hasCopyright() {
      return this.get(this, 'page.copyright') || this.get(this, 'page.site.copyright')
    }
  }
}
</script>

<style lang="scss" scoped>
.app-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
  padding: 20px 26px 34px;
  border-top: 1px solid $gray1;

  @media (max-width: $screen-md) {
    display: block;
  }
}
.no-flexbox .app-footer {
  white-space: nowrap;
  .app-footer__merchant-info {
    margin-right: 200px;
    display: inline-block;
    vertical-align: middle;
    .app-footer__merchant-logo,
    ul {
      display: inline-block;
      vertical-align: middle;
    }
  }
  .app-footer__landing-info {
    display: inline-block;
    vertical-align: middle;
    .app-footer__logo,
    ul {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.app-footer__merchant-info {
  font-size: .8125em;
  color: $black3;
  display: flex;
  @media (max-width: $screen-md) {
    margin-bottom: 28px;
  }
  > ul {
    line-height: 1.8em;
    align-self: center;
  }
}
.app-footer__merchant-title {
  font-size: 1.1em;
  font-weight: 900;
}
.app-footer__merchant-logo {
  filter: grayscale(1);
  align-self: center;
  margin-right: 14px;
  max-width: 60px;
  height: auto;
}
.app-footer__logo {
  filter: grayscale(1);
  margin-right: 10px;
  width: 20px;
  height: auto;
}
.app-footer__landing-info {
  display: flex;
  color: $black7;
  > ul {
    font-size: .75em;
    line-height: 1.7em;
    align-self: center;
  }
  strong {
    margin: 0 2px;
  }
  a {
    color: $black7;
    &:hover {
      text-decoration: underline;
    }
  }
}
.app-footer__anchor {
  color: $black3;
  text-decoration: underline;
}
</style>
