<template>
  <div class="nav-dropdown">
    <button ref="toggle"
            class="nav-dropdown__toggle"
            :class="{ 'nav-dropdown__toggle--opened': opened }"
            type="button"
            @click.prevent="opened = ! opened">
      {{ item.title }}
    </button>
    <div class="nav-dropdown__menu"
         :class="{ 'nav-dropdown__menu--opened': opened }">
      <ul class="nav-dropdown__menu-ul" @click.stop>
        <li v-for="page in item.pages" :key="page.id">
          <a :href="page.url"
             @click="handleAnchorClick($event, page.url)">
            <app-image class="nav-dropdown__img"
                       :class="{ 'nav-dropdown__img--error': hasImageError(page.image) }"
                       :src="page.image"
                       :alt="page.title"
                       @error="saveErrorImageSrc(page.image)" />
          </a>
          <a class="menu__title"
             :href="page.url"
             @click="handleAnchorClick($event, page.url)">{{ page.title }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import domMixin from '@landing/common/mixins/domMixin'
import imageErrorMixin from '@landing/landing-desktop/mixins/imageErrorMixin'
import AppImage from './AppImage'

export default {
  name: 'NavDropdown',
  components: {
    AppImage
  },
  mixins: [domMixin, imageErrorMixin],
  props: {
    item: Object
  },
  data() {
    return {
      opened: false
    }
  },
  mounted() {
    this.on(document, 'click', this.handleDocumentClick)
  },
  methods: {
    handleDocumentClick(event) {
      if (event.target !== this.$refs.toggle) {
        this.opened = false
      }
    },
    handleAnchorClick(event, url) {
      this.$emit('anchor-click', { event, url })
      this.opened = false
    }
  }
}
</script>

<style lang="scss" scoped>
.no-flexbox .nav-dropdown__menu {
  &.nav-dropdown__menu--opened {
    display: table-cell;
  }
  .nav-dropdown__menu-ul {
    white-space: pre-wrap;
    > li {
      display: inline-block;
    }
  }
}
.nav-dropdown__menu {
  display: none;
  z-index: 1;
  > li {
    padding: 0 14px 14px 14px;
  }
  &.nav-dropdown__menu--opened {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
  .nav-dropdown__menu-ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 18px 0 10px 18px;
    border: 1px solid $gray3;
    border-radius: 4px;
    background-color: $white1;
    > li {
      margin-right: 18px;
      margin-bottom: 10px;
    }
  }
}
.menu__title {
  text-align: left;
  display: block;
  padding-top: 4px;
  color: $black3;
}
.nav-dropdown__toggle {
  transition: .2s all;
  user-select: none;
  cursor: pointer;
  display: inline-block;
  padding: 15px 18px;
  outline: 0;
  background-color: transparent;
  border: 0;
}
.nav-dropdown__toggle:after {
  transition: .2s all;
  content: '';
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-top: -6px;
  border-right: 1px solid $black7;
  border-bottom: 1px solid $black7;
  transform: rotate(45deg);
  vertical-align: middle;
  margin-left: 7px;
}
.nav-dropdown__toggle.nav-dropdown__toggle--opened {
  color: $black0;
}
.nav-dropdown__toggle.nav-dropdown__toggle--opened:after {
  margin-top: 0;
  transform: rotate(0);
  width: 0;
  height: 0;
  border-top: 5px dashed $black7;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-bottom: 0;
}
.nav-dropdown__img {
  max-width: 270px;
  height: auto;
  &.nav-dropdown__img--error {
    width: 270px;
    height: 100px;
  }
}
</style>
