// appNavMixin requires domMixin to work

import { setSiteNavHeight } from '../redux/reducers/app'
import { setPage } from '../redux/reducers/page'
import { getCartData, setPageToCart } from '../redux/reducers/cart'
import { TYPE_NAVBAR_EMPTY } from '@common/consts/component'
import http from '../plugins/http'
import Vue from '../consts/vue'
import Tracker from '@landing/landing-common/models/Tracker'
import { get, getScrollTop, throttle, toPixel } from '../utils'
import processIgEmbeds from '@landing/landing-common/utils/processIgEmbeds'
import setConst from '@landing-desktop/utils/setConst'

let loadedPage = null

const getPage = async to => {
  if (to.name === 'SalesPage') {
    const alias = to.path.slice(1)
    const res = await http.get(`/${alias}.json`)
    return res.data
  }
  if (to.name === 'StaticPage') {
    const [, id] = to.path.match(/^\/contents\/(\w+)$/)
    const res = await http.get(`/contents/${id}.json`)
    return res.data
  }
}

export default {
  data() {
    return {
      shrank: false,
      ...this.mapState({
        page: 'page.page',
        siteNavHeight: 'app.siteNavHeight',
        shrankSiteNavHeight: 'app.shrankSiteNavHeight',
        navbarHeight: 'app.navbarHeight'
      })
    }
  },
  beforeCreate() {
    this.mapDispatch({ setSiteNavHeight, getCartData, setPage, setPageToCart })
  },
  async beforeRouteEnter(to, from, next) {
    if (from.name === null) {
      return next()
    }
    if (typeof window === 'undefined') {
      return next()
    }
    try {
      loadedPage = await getPage(to)
    }
    catch (err) {
      location.href = to.path
      return
    }
    next(() => {
      if (from.name && (['SalesPage', 'StaticPage'].includes(to.name))) {
        processIgEmbeds()
      }
    })
  },
  async beforeRouteUpdate (to, from, next) {
    try {
      const page = await getPage(to)
      this._setPage(page)
    }
    catch (err) {
      location.href = to.path
      return
    }
    if (to.name === 'SalesPage') {
      this.getCartData()
    }
    next()

    if (['SalesPage', 'StaticPage'].includes(to.name)) {
      this.$nextTick(() => {
        processIgEmbeds()
        this.$tracker.pageView()

        // 切到靜態頁要重設 scroll event
        if (to.name === 'StaticPage') {
          this.bindScrollEvent()
        }
        // 切到銷售頁要重設 viewContent watchers
        if (to.name === 'SalesPage') {
          const { productList } = this.$refs
          productList && productList.initViewContent()
        }
      })
    }
  },
  created() {
    // this hook needs to call before cartMixin's created
    if (this.isClient && loadedPage) {
      this._setPage(loadedPage)
      loadedPage = null
    }
  },
  mounted() {
    if (this.hasSiteNav) {
      this.on(window, 'scroll', throttle(this._handleNavScroll, 100))
    }
  },
  methods: {
    _setPage(page) {
      this.setPage(page)
      this.setPageToCart(page)
      Vue.prototype.$tracker = new Tracker({ page })
      setConst(page)
      document.title = page.title
    },
    _handleNavScroll() {
      if (document.body.classList.contains('modal-open')) {
        return
      }
      const scrollTop = getScrollTop()
      const navHeight = this.siteNavHeight || this.shrankSiteNavHeight
      const scrollBoundary = navHeight + this.navbarHeight
      this.shrank = scrollTop > scrollBoundary
    },
    setNavHeight({ siteNav, navbar }) {
      const shrank = this.shrank
      this.setSiteNavHeight({
        shrank,
        siteNavHeight: siteNav ? siteNav.offsetHeight : 0,
        navbarHeight: navbar ? navbar.$el.offsetHeight : 0
      })
    }
  },
  computed: {
    hasSiteNav() {
      return get(this, 'page.site.navbar.enabled', false)
    },
    hasNavbar() {
      const type = get(this, 'page.content.navbar.type')
      return type && (type !== TYPE_NAVBAR_EMPTY)
    },
    containerStyle() {

      const { hasSiteNav, navbarHeight } = this

      if ((! hasSiteNav) && this.hasNavbar) {
        return { paddingTop: toPixel(navbarHeight || 24) }
      }
      if (! hasSiteNav) {
        return {}
      }
      if (this.shrank) {
        const navHeight = this.siteNavHeight || this.shrankSiteNavHeight
        return { paddingTop: toPixel(navHeight + navbarHeight) }
      }
      return {}
    }
  }
}
