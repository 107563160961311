<template>
  <div class="image-slide">
    <img class="image-slide__img"
         :src="src"
         loading="lazy"
         alt="">
  </div>
</template>

<script>
export default {
  name: 'ImageSlide',
  props: {
    src: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.image-slide {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-slide__img {
  max-width: 100%;
  height: auto;
}
</style>
