import hypernova, { serialize, load } from 'hypernova'
import { createRenderer } from 'vue-server-renderer'

const onRouterReady = router => {
  return new Promise((resolve) => {
    router.onReady(resolve)
  })
}

const getVue = (component, context, options) => {
  const { createRouter, Vue } = options
  const { props } = context
  const config = {
    render: h => h(component, { props })
  }
  let router = null
  if (createRouter) {
    router = createRouter(props)
    config.router = router
  }
  return {
    vm: new Vue(config),
    router
  }
}

const renderVue = (name, component, options) => hypernova({
  server() {
    return async context => {
      const { vm, router } = getVue(component, context, options)
      if (router) {
        router.push(context.url)
        await onRouterReady(router)
      }
      const renderer = createRenderer()
      const contents = await renderer.renderToString(vm)
      return serialize(name, contents, context)
    }
  },
  client() {
    const payloads = load(name);
    if (payloads) {
      payloads.forEach((payload) => {
        const { node, data: serverContext } = payload
        const { vm, router } = getVue(component, serverContext, options)
        const mount = () => {
          // fallback to client render when node.js is down
          if (! node.children[0]) {
            node.appendChild(document.createElement('div'))
          }
          vm.$mount(node.children[0])
        }
        if (router) {
          router.push(serverContext.url)
          onRouterReady(router)
            .then(() => mount())
        }
        else {
          mount()
        }
      })
    }
    return component
  }
})

export default renderVue
