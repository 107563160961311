<template>
  <div class="price-list">
    <discount-lv-list v-if="page.discountLevels.length > 0"
                      class="price-list__discount-lv-list"
                      :items="page.discountLevels"
                      :layout-discount="$consts.layoutDiscount"
                      :total-order-amount-with-coupon="cart.totalOrderAmountWithCoupon" />
    <table>
      <tbody>
        <tr v-if="cart.orderAmount !== cart.totalOrderAmountWithDiscount">
          <td>總計</td>
          <td class="price-list__value">{{ dollar(cart.orderAmount) }}</td>
        </tr>
        <tr v-if="cart.shippingFee > 0">
          <td>運費</td>
          <td class="price-list__value">{{ dollar(cart.shippingFee) }}</td>
        </tr>
        <tr v-if="(couponDiscount < 0)">
          <td>折價卷 <strong v-if="cart.couponCode">{{ cart.couponCode }}</strong></td>
          <td class="price-list__value text-free">{{ dollar(couponDiscount) }}</td>
        </tr>
        <tr v-if="(paymentDiscount < 0)">
          <td>滿額優惠</td>
          <td class="price-list__value text-free">{{ dollar(paymentDiscount) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import DiscountLvList from '@landing/landing-common/components/DiscountLvList'
import Price from '@landing/landing-common/models/Price'
import dollar from '@landing/landing-common/utils/dollar'

export default {
  name: 'PriceList',
  components: {
    DiscountLvList
  },
  props: {
    cart: Object,
    page: Object
  },
  computed: {
    couponDiscount() {
      return Price.getCouponDiscount(this.cart)
    },
    paymentDiscount() {
      return Price.getPaymentDiscount(this.cart)
    }
  },
  methods: {
    dollar
  }
}
</script>

<style lang="scss">
.price-list__discount-lv-list .txt-danger {
  color: $red2;
}
</style>

<style lang="scss" scoped>
.price-list {
  color: $black5;
  background-color: $white1;
  border-bottom: 1px solid $gray11;
  > table {
    tr:hover {
      background-color: $gray12;
    }
    width: 100%;
    td {
      vertical-align: top;
      padding: 14px;
    }
  }
}
.price-list__value {
  text-align: right;
}
.price-list__final-price {
  width: 100px;
  color: $red4;
  font-size: 1.2em;
  font-weight: 900;
}
.price-list__discount-lv-list {
  margin-top: 1em;
  margin-bottom: 1em;
}
</style>
