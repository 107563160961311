<template>
  <transition-group v-if="mode === 'list'"
                    name="item-group"
                    class="product-list__list-mode"
                    tag="div">
    <div v-for="item in items" :key="item.key">
      <app-image v-if="isProductBannerVisible(item)"
                 class="product-list__banner"
                 :src="item.banner.desktop"
                 lazy
                 alt="banner" />
      <product-list-item :ref="item.key"
                         class="product-list__item"
                         :mode="mode"
                         :data="item"
                         :is-additional-purchase="item.isAdditionalPurchase"
                         @primary-btn-click="$emit('primary-btn-click', item)"
                         @secondary-btn-click="$emit('secondary-btn-click', item)" />
    </div>
  </transition-group>
  <transition-group v-else-if="mode === 'grid'"
                    name="item-group"
                    class="product-list--grid-mode"
                    tag="div">
    <product-list-item v-for="item in items"
                       :key="item.key"
                       :ref="item.key"
                       :mode="mode"
                       class="product-list__item"
                       :data="item"
                       :is-additional-purchase="item.isAdditionalPurchase"
                       @primary-btn-click="$emit('primary-btn-click', item)"
                       @secondary-btn-click="$emit('secondary-btn-click', item)" />
  </transition-group>
</template>

<script>
import AppImage from './AppImage'
import ProductListItem from './ProductListItem'

export default {
  name: 'ProductListItemGroup',
  components: {
    AppImage,
    ProductListItem
  },
  props: {
    items: Array,
    mode: {
      type: String,
      default: 'list'
    }
  },
  methods: {
    isProductBannerVisible(item) {
      return this.get(item, 'banner.desktop')
    }
  }
}
</script>

<style lang="scss" scoped>
.product-list__item {
  margin-bottom: 14px;
}
.product-list__list-mode {
  margin-left: 14px;
  margin-right: 14px;
}
.product-list--grid-mode {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;

  .product-list__item {

    flex-shrink: 1;
    flex-grow: 0;
    flex-basis: calc(25% - 18px);
    width: calc(25% - 18px);
    margin-bottom: 14px;
    margin-right: 24px;

    &:nth-child(4n) {
      margin-right: 0;
    }

    @media (max-width: 1000px) {
      flex-basis: calc(33.33% - 16px);
      width: calc(33.33% - 16px);
      &:nth-child(4n) {
        margin-right: 24px;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    @media (max-width: 600px) {
      flex-basis: calc(50% - 12px);
      width: calc(50% - 12px);
      &:nth-child(3n) {
        margin-right: 24px;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
.no-flexbox .product-list--grid-mode {
  .product-list__item {
    display: inline-block;
  }
}
.product-list__heading-bar {
  padding: 0 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-list__banner {
  display: block;
  max-width: 100%;
  margin: 30px auto;
  height: auto;
}
.product-list__product-banner-box {
  padding: 14px 14px 0;
}
.product-list__additional-product-banner-box {
  padding: 0 14px 14px;
}
</style>
