<template>
  <component :is="tag"
             ref="component"
             v-bind="tagProps"
             data-cy="image-elem"
             class="image-element"
             :class="{ 'image-element--loaded': loaded }"
             :style="wrapperStyle"
             @click="handleComponentClick">
    <img v-if="containerReady"
         class="image-element__image"
         v-bind="attrs"
         :alt="data.data.photoInfo.dataFileName"
         :style="style"
         @load="handleLoad"
         @error="handleError">
    <img v-if="lazyThumb && (! loaded) && containerReady"
         v-observe-visibility="{ callback: handleVisibilityChange, once: true }"
         class="image-element__thumb"
         v-bind="thumbAttrs"
         :style="style"
         alt="thumb">
    <img v-else-if="(! loaded) && containerReady"
         v-observe-visibility="{ callback: handleVisibilityChange, once: true }"
         class="image-element__thumb"
         :src="thumbUrl"
         :style="style"
         alt="thumb">
  </component>
</template>

<script>
import parsePaddingStr from '@landing/common/utils/parsePaddingStr'
import setHttpsIfNeeded from '@landing/common/utils/setHttpsIfNeeded'
import {
  TYPE_IMAGE_LINK_SHOPPING_CART,
  TYPE_IMAGE_LINK_SALES_PAGE,
  TYPE_IMAGE_LINK_STATIC_PAGE,
  IMAGE_MARGIN_NONE,
  IMAGE_MARGIN_SM,
  IMAGE_MARGIN_MD,
  IMAGE_MARGIN_LG,
  IMAGE_MARGIN_CUSTOM,
  IMAGE_WIDTH_SM,
  IMAGE_WIDTH_MD,
  IMAGE_WIDTH_LG,
  IMAGE_WIDTH_CUSTOM
} from '@common/consts/component'

export default {
  name: 'ImageElement',
  props: {
    data: Object,
    lazyThumb: Boolean,
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      contentWidth: 0,
      loaded: false,
      attrs: {},
      thumbAttrs: {}
    }
  },
  computed: {
    imageWidth() {
      const { contentWidth } = this
      const { widthType, customWidth } = this.data.data
      switch (widthType) {
        case IMAGE_WIDTH_SM:
          return contentWidth * 0.8
        case IMAGE_WIDTH_MD:
          return contentWidth * 0.9
        case IMAGE_WIDTH_LG:
          return contentWidth * 1
        case IMAGE_WIDTH_CUSTOM:
          return customWidth
        default:
          return contentWidth
      }
    },
    desktopPadding() {
      const { marginType, customDesktopMargin } = this.data.data
      switch (marginType) {
        case IMAGE_MARGIN_NONE:
          return '0'
        case IMAGE_MARGIN_SM:
          return '10px 36px'
        case IMAGE_MARGIN_MD:
          return '20px 46px'
        case IMAGE_MARGIN_LG:
          return '30px 56px'
        case IMAGE_MARGIN_CUSTOM:
          return customDesktopMargin
        default:
          return '0'
      }
    },
    mobilePadding() {
      const { marginType, customMobileMargin } = this.data.data
      switch (marginType) {
        case IMAGE_MARGIN_NONE:
          return '0'
        case IMAGE_MARGIN_SM:
          return '10px 15px'
        case IMAGE_MARGIN_MD:
          return '20px 25px'
        case IMAGE_MARGIN_LG:
          return '30px 35px'
        case IMAGE_MARGIN_CUSTOM:
          return customMobileMargin
        default:
          return '0'
      }
    },
    padding() {
      return this.mobile ? this.mobilePadding : this.desktopPadding
    },
    wrapperStyle() {
      return {
        padding: this.padding
      }
    },
    containerReady() {
      return (this.contentWidth > 0)
    },
    thumbUrl() {
      const { urlThumb, urlBlur } = this.data.data.photoInfo
      return urlBlur || urlThumb
    },
    tag() {
      return [
        TYPE_IMAGE_LINK_SHOPPING_CART,
        TYPE_IMAGE_LINK_SALES_PAGE,
        TYPE_IMAGE_LINK_STATIC_PAGE
      ].includes(this.data.data.type) ? 'a' : 'div'
    },
    tagProps() {
      const { type } = this.data.data
      if (type === TYPE_IMAGE_LINK_SHOPPING_CART) {
        return {
          href: '#product-list-heading'
        }
      }
      if (type === TYPE_IMAGE_LINK_SALES_PAGE) {
        return {
          href: setHttpsIfNeeded(this.data.data.pageLink, this.$url)
        }
      }
      if (type === TYPE_IMAGE_LINK_STATIC_PAGE) {
        return {
          href: setHttpsIfNeeded(this.data.data.pageLink, this.$url)
        }
      }
      return {}
    },
    style() {
      const { width, height } = this.data.data.photoInfo
      const { contentWidth, padding } = this
      let { imageWidth } = this

      if (contentWidth === 0) {
        return {}
      }

      const paddingData = parsePaddingStr(padding)

      const isStr = (typeof imageWidth === 'string')

      if (isStr && imageWidth.endsWith('%')) {
        const ratio = parseFloat(imageWidth) / 100
        imageWidth = parseInt(width * ratio, 10)
      }
      else if (isStr) {
        imageWidth = parseInt(imageWidth, 10)
      }

      if (paddingData) {
        imageWidth = this.getMaxImageWidth(imageWidth, contentWidth, paddingData)
      }

      // https://github.com/superlanding/landing/issues/714
      const ratio = imageWidth / width
      const imageHeight = parseInt(height * ratio, 10)

      return {
        width: `${imageWidth}px`,
        height: `${imageHeight}px`
      }
    }
  },
  watch: {
    data: {
      handler() {
        if (this.data.data.src !== this.attrs.src) {
          this.loaded = false
          this.loadImage()
        }
      },
      deep: true
    }
  },
  mounted() {
    this.setContentWidth()
  },
  methods: {
    setContentWidth() {
      const { component } = this.$refs
      if (component) {
        this.contentWidth = component.clientWidth
      }
    },
    getMaxImageWidth(imageWidth, contentWidth, paddingData) {
      if ((imageWidth + paddingData.left + paddingData.right) > contentWidth) {
        return contentWidth - paddingData.left - paddingData.right
      }
      return imageWidth
    },
    handleLoad() {
      this.loaded = true
      this.$emit('load', this.data)
    },
    handleError(err) {
      this.loaded = true
      this.$emit('error', this.data, err)
    },
    loadImage() {
      if (this.lazyThumb) {
        this.thumbAttrs = { src: this.thumbUrl }
      }
      this.attrs = { src: this.data.data.src }
    },
    handleVisibilityChange(isVisible) {
      if (isVisible) {
        this.loadImage()
      }
    },
    handleComponentClick(event) {
      const { type } = this.data.data
      this.$emit('click', { event, type })
    }
  }
}
</script>

<style lang="scss" scoped>
.image-element {
  overflow: hidden;
  display: block;
  position: relative;
  width: 100%;
}
.image-element img {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: auto;
}
.image-element__image {
  opacity: 0;
}
.image-element__thumb {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.image-element--loaded {
  .image-element__image {
    opacity: 1
  }
  .image-element__thumb {
    display: none;
  }
}
</style>
