<template>
  <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.1875 4L1.5 2.6875L3.6875 4.875L8.5 0.0625L9.8125 1.375L3.6875 7.5L0.1875 4Z" fill="#FFFFFF" />
  </svg>
</template>

<script>
export default {
  name: 'CheckSvg'
}
</script>
