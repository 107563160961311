// discountHintMixin requires domMixin
import { throttle, toPixel } from '@landing-desktop/utils'

export default {
  data() {
    return {
      discountHintVisible: false,
      ...this.mapState({
        shrankSiteNavHeight: 'app.shrankSiteNavHeight',
        navbarHeight: 'app.navbarHeight'
      })
    }
  },
  mounted() {
    this.on(window, 'scroll', throttle(() => {
      const list = this.$refs.discountLvList
      if (! list) {
        return
      }
      const dom = list.$el
      const { bottom } = dom.getBoundingClientRect()
      const navHeight = this.navbarHeight + this.shrankSiteNavHeight
      this.discountHintVisible = bottom < navHeight
    }, 100))
  },
  computed: {
    applicableDiscountItem() {
      const amount = this.cart.totalOrderAmountWithCoupon
      return this.page.discountLevels.find(item => item.amount > amount)
    },
    discountItemStyle() {
      return {
        top: toPixel(this.navbarHeight + this.shrankSiteNavHeight)
      }
    }
  }
}
