<template>
  <svg width="48px" height="49px" viewBox="0 0 48 49">
    <!-- Generator: Sketch 55.2 (78181) - https://sketchapp.com -->
    <title>Whatsapp</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <linearGradient id="linearGradient-1" x1="50.0000408%" y1="100%" x2="50.0000408%" y2="0.000171081715%">
        <stop stop-color="#20B038" offset="0%" />
        <stop stop-color="#60D66A" offset="100%" />
      </linearGradient>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="whatsapp-icon-seeklogo.com">
        <path id="Shape" d="M41.0063606,7.00666723 C36.4937866,2.49098395 30.4934078,0.00275512137 24.1004242,0 C10.9266933,0 0.205925643,10.7173434 0.200651553,23.8904839 C0.199116557,28.1013327 1.2993546,32.2117376 3.39060979,35.8349584 L0,48.2152537 L12.6695437,44.8931284 C16.1602431,46.7968385 20.0905418,47.7999782 24.0901515,47.8015919 L24.1003061,47.8015919 L24.1004242,47.8015919 C37.2720297,47.8015919 47.994411,37.0823593 48,23.9098485 C48.0022041,17.5255238 45.5183441,11.5226654 41.0063606,7.00666723 Z M24.1004242,43.7664018 L24.0926705,43.7664018 C20.5276221,43.7645126 17.0316093,42.8073834 13.9822409,40.9979378 L13.256542,40.5677452 L5.73816999,42.5389559 L7.7454727,35.2112776 L7.27265452,34.4600348 C5.28404727,31.2981 4.23422796,27.6440613 4.235722,23.8920189 C4.24009243,12.9429304 13.1507847,4.03534755 24.1079417,4.03534755 C29.4139906,4.03755165 34.4010358,6.1057819 38.1517399,9.85908374 C41.902208,13.6123462 43.9661481,18.6017923 43.9645746,23.9081561 C43.9599294,34.8575988 35.0489223,43.7664018 24.1004242,43.7664018 L24.1004242,43.7664018 Z" fill="#E0E0E0" fill-rule="nonzero" />
        <path id="Path" d="M1.09712869,46.8415502 L4.3328613,35.0268026 C2.33661843,31.5690465 1.28683847,27.6461079 1.28809796,23.6283932 C1.29333269,11.0572467 11.5244759,0.829763839 24.0953469,0.829763839 C30.1966025,0.83251896 35.9230042,3.20696127 40.2284717,7.51648276 C44.5348839,11.8259649 46.9050361,17.5542164 46.9025584,23.6462227 C46.8973217,36.2176841 36.6655488,46.4460722 24.0962521,46.4460722 C24.0953469,46.4460722 24.0968819,46.4460722 24.0962521,46.4460722 L24.0860975,46.4460722 C20.2691918,46.4448521 16.5187238,45.4866996 13.1877033,43.6712714 L1.09712869,46.8415502 L1.09712869,46.8415502 Z" fill="url(#linearGradient-1)" fill-rule="nonzero" />
        <path id="Path" d="M18.194546,13.7478195 C17.7523884,12.7647528 17.2868516,12.7450734 16.8661839,12.7278735 C16.5221086,12.713114 16.128205,12.7140084 15.7349311,12.7140084 C15.3413423,12.7140084 14.7017606,12.8619299 14.1608909,13.4526279 C13.6197063,14.0433259 12.0945499,15.4713053 12.0945499,18.3755575 C12.0945499,21.2801245 14.2100895,24.0866092 14.5049662,24.4807884 C14.8001578,24.8743771 18.5887645,31.0248314 24.5888285,33.3909689 C29.5754014,35.3573778 30.5901519,34.9662293 31.672521,34.8678321 C32.7548901,34.7694349 35.1649916,33.4401675 35.6569775,32.0616622 C36.1489635,30.6834718 36.1489635,29.5021151 36.0013677,29.2551775 C35.8537719,29.0091846 35.4601832,28.8615888 34.8698,28.5663972 C34.2794169,28.2712056 31.3773295,26.8429113 30.8361449,26.646117 C30.2949603,26.4493226 29.9013716,26.3509254 29.5077828,26.9419383 C29.114194,27.5323214 27.9835316,28.8615888 27.6391414,29.2551775 C27.2947512,29.6496716 26.9503611,29.6988702 26.3599779,29.4036786 C25.7695948,29.1075817 23.8683642,28.484885 21.6129432,26.4738825 C19.8580883,24.9093672 18.6733074,22.9770825 18.3289172,22.3861089 C17.984527,21.7957258 18.2919986,21.4759349 18.5881348,21.1816486 C18.8531775,20.9171963 19.1785179,20.4925534 19.4737095,20.1478483 C19.7682713,19.8031433 19.8666685,19.5571503 20.0634629,19.1635616 C20.2602573,18.769343 20.1618601,18.4246774 20.0142643,18.1294858 C19.8666685,17.8342942 18.719436,14.9150463 18.194546,13.7478195 L18.194546,13.7478195 Z" fill="#FFFFFF" />
        <path id="Shape" d="M40.8111405,6.93074396 C36.3503629,2.46689631 30.4188621,0.00728139219 24.0993221,0.00448691194 C11.0767687,0.00448691194 0.479076247,10.5987945 0.473841517,23.6207182 C0.47230652,27.7832736 1.55991036,31.8464479 3.62715664,35.4281057 L0.275512137,47.6662367 L12.7996248,44.3822108 C16.2502962,46.2640374 20.1354503,47.2557236 24.0891675,47.2572586 L24.099204,47.2572586 L24.0993221,47.2572586 C37.1197108,47.2572586 47.7189383,36.6611012 47.7244893,23.6397679 C47.7266526,17.3288475 45.2713278,11.3949065 40.8111405,6.93074396 L40.8111405,6.93074396 Z M24.0993221,43.268512 L24.0916471,43.268512 C20.5674927,43.2666621 17.1115865,42.3205141 14.0972476,40.5318499 L13.3798533,40.1065773 L5.94779537,42.0551565 L7.93203378,34.8115882 L7.46464712,34.0690043 C5.49886802,30.9433585 4.46109252,27.3312369 4.46262582,23.6222925 C4.46691763,12.7989163 13.2753162,3.99358778 24.1067216,3.99358778 C29.3519217,3.99575252 34.281621,6.04024937 37.9893452,9.75045324 C41.6967546,13.4606571 43.7369613,18.3927967 43.7354272,23.6383116 C43.730782,34.4619633 34.9220686,43.268512 24.0993221,43.268512 Z" fill="#FFFFFF" fill-rule="nonzero" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'WhatsappIconSvg'
}
</script>
