<template>
  <div id="product-list"
       v-observe-visibility="{ callback: handleVisibilityChange }"
       class="product-list">
    <div v-if="productBanner" class="product-list__product-banner-box">
      <app-image class="product-list__banner"
                 :class="{ 'product-list__banner--error': hasImageError(productBanner) }"
                 :src="productBanner"
                 alt="product banner"
                 lazy
                 @error="saveErrorImageSrc(productBanner)" />
    </div>

    <template v-for="type in types">
      <product-list-item-group v-if="type === 'promo'"
                               :key="`${type}-promoGroup`"
                               ref="promoGroup"
                               data-cy="promo-list"
                               :items="promos"
                               :mode="mode"
                               @primary-btn-click="handleItemClick"
                               @secondary-btn-click="handleAdditionalPurchaseItemClick" />

      <div v-if="(type === 'product') && hasProducts"
           :key="`${type}-headingBar`"
           class="product-list__heading-bar">
        <h2 class="product-list__heading">全部商品</h2>
        <dropdown-btn class="product-list__dropdown-btn"
                      data-cy="sort-btn"
                      aria-label="排序商品"
                      :options="sortBtnOptions"
                      :value="sortMethod"
                      @change="handleSortBtnChange" />
      </div>
      <product-list-item-group v-if="(type === 'product') && hasProducts"
                               :key="`${type}-productGroup`"
                               ref="productGroup"
                               data-cy="product-list"
                               :items="sortedProducts"
                               :mode="mode"
                               @primary-btn-click="handleItemClick"
                               @secondary-btn-click="handleAdditionalPurchaseItemClick" />
      <div v-if="(type === 'product') && addtionalProductBanner"
           :key="`${type}-addtionalProductBanner`"
           class="product-list__additional-product-banner-box">
        <app-image class="product-list__banner"
                   :class="{ 'product-list__banner--error': hasImageError(addtionalProductBanner) }"
                   :src="addtionalProductBanner"
                   alt="additional product banner"
                   lazy
                   @error="saveErrorImageSrc(addtionalProductBanner)" />
      </div>
      <product-list-item-group v-if="(type === 'product') && hasAdditionalProducts"
                               :key="`${type}-additionalProductGroup`"
                               ref="additionalProductGroup"
                               data-cy="additional-product-list"
                               :items="sortedAdditionalProducts"
                               :mode="mode"
                               @primary-btn-click="handleItemClick"
                               @secondary-btn-click="handleAdditionalPurchaseItemClick" />
    </template>
  </div>
</template>

<script>
import AppImage from '@landing/landing-desktop/components/AppImage'
import ProductListItemGroup from '@landing/landing-desktop/components/ProductListItemGroup'
import DropdownBtn from '@landing/landing-desktop/components/DropdownBtn.vue'
import { showOrderItemMenu, setSortMethod } from '@landing/landing-desktop/redux/reducers/app'
import { setCenterToast } from '@landing/landing-desktop/redux/reducers/toast'
import * as sortMethods from '@landing/landing-common/consts/sortMethods'
import domMixin from '@landing/common/mixins/domMixin'
import imageErrorMixin from '@landing/landing-desktop/mixins/imageErrorMixin'
import { sortBy, throttle } from '@landing/landing-desktop/utils'

export default {
  name: 'ProductList',
  components: {
    AppImage,
    DropdownBtn,
    ProductListItemGroup
  },
  mixins: [domMixin, imageErrorMixin],
  props: {
    promos: Array,
    products: Array,
    addtionalProducts: Array,
    productBanner: String,
    addtionalProductBanner: String,
    mode: {
      type: String,
      default: 'list'
    },
    priority: String
  },
  data() {
    return {
      contentViewed: false,
      ...this.mapState({
        sortMethod: 'app.sortMethod',
        cartItems: 'cart.cart.items',
        trackerLoaded: 'app.trackerLoaded'
      })
    }
  },
  computed: {
    hasProducts() {
      return (this.products.length > 0)
    },
    hasAdditionalProducts() {
      return (this.addtionalProducts.length > 0)
    },
    types() {
      if (this.priority === 'promos') {
        return ['promo', 'product']
      }
      return ['product', 'promo']
    },
    sortBtnOptions() {
      return sortMethods.SORT_METHOD_ROWS
    },
    sortBtnText() {
      const row = sortMethods.SORT_METHOD_ROWS.find(row => row.value === this.sortMethod)
      return row ? row.text : ''
    },
    sortedProducts() {
      return this.sort(this.products)
    },
    sortedAdditionalProducts() {
      return this.sort(this.addtionalProducts)
    }
  },
  beforeCreate() {
    this.mapDispatch({ showOrderItemMenu, setSortMethod, setCenterToast })
  },
  mounted() {
    this.initViewContent()
    this.on(window, 'scroll', throttle(this.handleScroll, 100))
  },
  methods: {
    resetViewContent() {
      (this.unbinds || []).forEach(unbind => unbind())
      this.contentViewed = false
    },
    initViewContent() {
      this.resetViewContent()
      const fn = () => this.viewContent()
      this.unbinds = []
      this.unbinds.push(this.$watch('trackerLoaded', fn))
      this.unbinds.push(this.$watch('contentViewed', fn))
    },
    viewContent() {
      if (this.trackerLoaded && this.contentViewed) {
        this.$tracker.viewContent({
          sortedProductMap: this.getSortedProductMap()
        })
        this.resetViewContent()
      }
    },
    handleScroll() {
      this.setCenterToast('')
    },
    sort(rows) {
      const { sortMethod } = this
      if (sortMethod === sortMethods.NEWEST_FIRST) {
        return sortBy(rows, row => row.priority)
      }
      if (sortMethod === sortMethods.PRICE_ASC) {
        return sortBy(rows, row => row.price)
      }
      if (sortMethod === sortMethods.PRICE_DESC) {
        return sortBy(rows, row => row.price).reverse()
      }
      return rows
    },
    handleSortBtnChange(value) {
      this.setSortMethod(value)
    },
    getSortedProductMap() {
      return this.sortedProducts.reduce((obj, row, index) => {
        obj[row.id] = (index + 1)
        return obj
      }, {})
    },
    handleVisibilityChange(visible) {
      if (visible) {
        this.contentViewed = true
      }
    },
    getDomFromGroups(key) {
      const groups = ['productGroup', 'additionalProductGroup', 'promoGroup']
      return groups.map(group => this.get(this, `$refs[${group}][0].$refs[${key}][0].$el`))
        .find(Boolean)
    },
    emitSendToCartIfNeeded(item) {
      const unitList = (item.unitList || '').trim()
      const specs = item.specs || []

      if ((unitList === '1') && (specs.length === 0)) {
        const dom = this.getDomFromGroups(item.key)
        this.$emit('send-to-cart', { item, dom })
        return true
      }
      return false
    },
    handleItemClick(item) {
      const emitted = this.emitSendToCartIfNeeded(item)
      if (! emitted) {
        this.showOrderItemMenu(item)
      }
    },
    handleAdditionalPurchaseItemClick(item) {
      if (this.cartItems.length === 0) {
        if (this.centerToastTimer) {
          clearTimeout(this.centerToastTimer)
          this.centerToastTimer = null
        }
        this.setCenterToast('請先選擇產品，才可加購')
        this.centerToastTimer = setTimeout(() => this.setCenterToast(''), 2000)
        return
      }
      const emitted = this.emitSendToCartIfNeeded(item)
      if (! emitted) {
        this.showOrderItemMenu(item)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.product-list {
  padding-top: 21px;
}
.product-list__heading {
  margin: 0;
  font-size: 1.125em;
  color: $black5;
}
.product-list__heading-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.no-flexbox .product-list__heading {
  line-height: 50px; // IE9
  display: inline;  // IE9
}
.no-flexbox .product-list__heading-bar {
  display: block;
  @include clearfix;
  .product-list__dropdown-btn {
    float: right;
  }
}
.product-list__banner {
  display: block;
  max-width: 100%;
  margin: 30px auto;
  height: auto;
  &.product-list__banner--error {
    width: 750px;
    height: 200px;
  }
}
.product-list__product-banner-box {
  padding: 14px 14px 0;
}
.product-list__additional-product-banner-box {
  padding: 0 14px 14px;
}
</style>
