<template>
  <svg width="48px" height="48px" viewBox="0 0 48 48">
    <!-- Generator: Sketch 55.2 (78181) - https://sketchapp.com -->
    <title>LINE</title>
    <desc>Created with Sketch.</desc>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-2.000000, -65.000000)">
        <g id="btn-line" transform="translate(0.000000, 65.000000)">
          <g transform="translate(2.000000, 0.000000)">
            <g>
              <g transform="translate(8.000000, 9.600000)" fill-rule="nonzero">
                <path d="M31.964,12.96 C31.964,5.816 24.8,0 16,0 C7.2,0 0.036,5.816 0.036,12.96 C0.036,19.36 5.716,24.728 13.388,25.74 C13.908,25.852 14.616,26.084 14.792,26.54 C14.9191991,27.0110736 14.9369859,27.5049973 14.844,27.984 L14.616,29.348 C14.548,29.748 14.296,30.948 16,30.208 C17.704,29.468 25.2,24.8 28.544,20.928 L28.544,20.928 C30.856,18.4 31.964,15.816 31.964,12.96" fill="#FFFFFF" />
                <path d="M12.756,9.508 L11.636,9.508 C11.4652372,9.508 11.3261893,9.64525128 11.324,9.816 L11.324,16.772 C11.324,16.9443128 11.4636872,17.084 11.636,17.084 L12.756,17.084 C12.9283128,17.084 13.068,16.9443128 13.068,16.772 L13.068,9.816 C13.0658107,9.64525128 12.9267628,9.508 12.756,9.508" fill="#7EC163" />
                <path d="M20.464,9.508 L19.344,9.508 C19.1732372,9.508 19.0341893,9.64525128 19.032,9.816 L19.032,13.948 L15.832,9.644 L15.82,9.6 L15.82,9.6 L15.82,9.58 L15.82,9.58 C15.82,9.58 15.82,9.58 15.82,9.58 L15.82,9.58 L15.82,9.58 L15.82,9.58 L15.82,9.58 L15.82,9.58 L15.82,9.58 L14.468,9.58 C14.2972372,9.57998597 14.1581893,9.71725128 14.156,9.888 L14.156,16.772 C14.156,16.9443128 14.2956872,17.084 14.468,17.084 L15.6,17.084 C15.6823845,17.084 15.7613378,17.0510085 15.8192154,16.9923793 C15.877093,16.9337501 15.9090699,16.8543775 15.908,16.772 L15.908,12.644 L19.108,16.952 C19.1305546,16.9813372 19.1575454,17.0069785 19.188,17.028 L19.188,17.028 L19.188,17.028 L19.188,17.028 L19.188,17.028 L19.212,17.028 L19.232,17.028 L19.232,17.028 C19.2585019,17.0321924 19.2854981,17.0321924 19.312,17.028 L20.432,17.028 C20.6043128,17.028 20.744,16.8883128 20.744,16.716 L20.744,9.816 C20.7418107,9.64525128 20.6027628,9.508 20.432,9.508" fill="#7EC163" />
                <path d="M10.056,15.34 L7.016,15.34 L7.016,9.816 C7.01381073,9.64525128 6.87476276,9.508 6.704,9.508 L5.6,9.508 C5.42923724,9.508 5.29018927,9.64525128 5.288,9.816 L5.288,16.772 L5.288,16.772 C5.28911511,16.8525068 5.32053756,16.9296347 5.376,16.988 L5.376,16.988 C5.43343591,17.0448855 5.51116351,17.0765523 5.592,17.0760071 L10.056,17.0760071 C10.2283128,17.0760071 10.368,16.9363128 10.368,16.764 L10.368,15.644 C10.368,15.4716872 10.2283128,15.332 10.056,15.332" fill="#7EC163" />
                <path d="M26.648,11.248 C26.7303845,11.2480069 26.8093378,11.2150085 26.8672154,11.1563793 C26.925093,11.0977501 26.9570699,11.0183775 26.956026,10.936 L26.956026,9.82 C26.9570699,9.73762248 26.925093,9.65824994 26.8672154,9.5996207 C26.8093378,9.54099147 26.7303845,9.50798391 26.648,9.50798391 L22.172,9.50798391 C22.0922835,9.50716367 22.015691,9.53895677 21.96,9.596 L21.96,9.596 L21.96,9.596 C21.9062534,9.6535364 21.8762474,9.72926584 21.8759929,9.808 L21.8759929,9.808 L21.8759929,16.772 L21.8759929,16.772 C21.8754477,16.8528365 21.9071145,16.9305641 21.964,16.988 L21.964,16.988 C22.0202069,17.044277 22.0964619,17.076 22.176,17.076 L26.648,17.076 C26.7303845,17.076 26.8093378,17.0430085 26.8672154,16.9843793 C26.925093,16.9257501 26.9570699,16.8463775 26.956026,16.764 L26.956026,15.644 C26.9570699,15.5616225 26.925093,15.4822499 26.8672154,15.4236207 C26.8093378,15.3649915 26.7303845,15.3319931 26.648,15.332 L23.6,15.332 L23.6,14.168 L26.644,14.168 C26.7263845,14.1680069 26.8053378,14.1350085 26.8632154,14.0763793 C26.921093,14.0177501 26.9530699,13.9383775 26.952,13.856 L26.952,12.736 C26.9530699,12.6536225 26.921093,12.5742499 26.8632154,12.5156207 C26.8053378,12.4569915 26.7263845,12.4239931 26.644,12.424 L23.6,12.424 L23.6,11.248 L26.648,11.248 Z" fill="#7EC163" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'LineIconSvg'
}
</script>
