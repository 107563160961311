import axios from 'axios'
import qs from 'qs'

axios.interceptors.request.use(config => {
  config.paramsSerializer = params => {
    return qs.stringify(params, { arrayFormat: 'brackets', encode: false })
  }
  return config
})

export default axios
