const SCRIPT_URL = 'https://www.googletagmanager.com/gtag/js'

class Gtag {

  constructor(id) {
    this.id = id
  }

  config() {
    if (this.hasScriptEmbed() == false) {
      this.embedScript()
    }
    window.gtag = function() {
      dataLayer.push(arguments)
    }
    window.dataLayer = window.dataLayer || []
    gtag('js', new Date())
    gtag('config', this.id)
  }

  hasScriptEmbed() {
    return document.querySelectorAll(`script[src^="${SCRIPT_URL}"`).length > 0
  }

  embedScript() {
    const script = document.createElement('script')
    script.async = true
    script.src = `${SCRIPT_URL}?id=${this.id}`
    document.head.appendChild(script)
  }
}

export default Gtag
