<template>
  <div class="cart-modal-empty">
    <empty-cart-icon-svg />
    <div class="cart-modal-empty__heading">購物車是空的!</div>
    <button class="btn" @click="$emit('close')">繼續購物</button>
  </div>
</template>

<script>
import EmptyCartIconSvg from './EmptyCartIconSvg'

export default {
  name: 'CartModalEmpty',
  components: {
    EmptyCartIconSvg
  }
}
</script>

<style lang="scss" scoped>
.cart-modal-empty {
  text-align: center;
}
.cart-modal-empty__heading {
  color: $gray4;
  font-size: 1.625em;
  margin: 14px 0;
}
</style>
