import hasValue from '@landing/common/utils/hasValue'
import comma from '@superlanding/commaseparate'
import cloneDeep from 'lodash.clonedeep'
import extend from '@superlanding/extend'
import fromChineseDiscount from '@superlanding/fromchinesediscount'
import getChineseDiscount from '@superlanding/getchinesediscount'
import isPlainObject from 'lodash.isplainobject'
import pick from 'lodash.pick'

import {
  DISCOUNT_LV_TYPE_NONE,
  DISCOUNT_LV_TYPE_AMOUNT,
  DISCOUNT_LV_TYPE_RATE
} from '@landing/common/consts/discountLv'

const defaultProps = {
  id: null,
  amount: null,
  desc: '',
  discountType: DISCOUNT_LV_TYPE_NONE,
  items: [],
  discountsAmount: null,
  discountsRate: null,
  _discountsRate: null
}

class DiscountLv {

  constructor(props) {
    extend(this, defaultProps, props)
  }

  clone(props) {
    return new DiscountLv(extend({}, this, props))
  }

  static from(raw = {}) {
    const props = cloneDeep(raw)
    const { discount } = props
    if (isPlainObject(discount)) {
      props.discountType = discount.type
      props.discountsAmount = discount.amount
      props.discountsRate = discount.rate
      delete props.discount
    }
    const { discountsRate } = props
    if (hasValue(discountsRate)) {
      props.discountsRate = getChineseDiscount(discountsRate)
      props._discountsRate = discountsRate
    }
    return new DiscountLv(props)
  }

  get title() {
    // 純文字版
    const { desc } = this
    const appendedDesc = desc ? ` + ${desc}` : ''
    if (this.isDiscountAmount) {
      return `滿 ${comma(this.amount)} 元 ➔ 折 ${comma(this.discountsAmount)} 元${appendedDesc}`
    }
    if (this.isDiscountRate) {
      return `滿 ${comma(this.amount)} 元 ➔ 打 ${comma(this.discountsRate)} 折${appendedDesc}`
    }
    return `滿 ${comma(this.amount)} 元 ➔ ${desc}`
  }

  get isDiscountNone() {
    return this.discountType === DISCOUNT_LV_TYPE_NONE
  }

  get isDiscountAmount() {
    return this.discountType === DISCOUNT_LV_TYPE_AMOUNT
  }

  get isDiscountRate() {
    return this.discountType === DISCOUNT_LV_TYPE_RATE
  }

  toPostData() {
    const data = pick(this, ['amount', 'desc', 'discountType'])

    if (this.isDiscountAmount) {
      data.discountsAmount = this.discountsAmount
    }
    if (this.isDiscountRate) {
      data.discountsRate = fromChineseDiscount(this.discountsRate)
    }
    data.items = this.items.map(item => {
      const subItem = { freeProductId: item.id, qty: item.qty }
      if (item.refId) {
        subItem.id = item.refId
      }
      if (item._destroy) {
        subItem._destroy = true
      }
      return subItem
    })
    return data
  }
}

export default DiscountLv
