<template>
  <div class="cart-modal-error">
    <div class="cart-modal-error__heading" v-html="errorMessage" />
    <button class="btn" @click="$emit('reload')">重新讀取</button>
  </div>
</template>

<script>
import HumanError from '@landing/landing-common/models/HumanError'

export default {
  name: 'CartModalError',
  props: {
    error: Error
  },
  computed: {
    errorMessage() {
      const message = HumanError.getMessage(this.error)
      return message ? message : '讀取錯誤'
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-modal-error {
  text-align: center;
}
.cart-modal-error__heading {
  color: $gray4;
  font-size: 1.625em;
  margin: 14px;
}
</style>
