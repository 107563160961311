import range from 'lodash.range'

// 回傳指定日期的 unix timestamp 區間陣列
// 例如 今日的每 8 小時可以這樣設定: getSecondsArr(8 * 3600, new Date())
export default function getSecondsArr(step, d = new Date()) {

  // today at 00:00:00
  const date = new Date(d.getTime())
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)

  const start = parseInt(+date / 1000, 10)
  const end = start + 86400

  return range(start, end, step)
}
