export const TAB_KEY = 'tab'

export const ENTER_KEY = 'enter'

export const SHIFT_KEY = 'shift'

export const CONTROL_KEY = 'ctrl'

export const ESC_KEY = 'esc'

export const SPACE_KEY = 'space'

export const UP_KEY = 'up'

export const DOWN_KEY = 'down'

export const F_KEY = 'f'

export const LEFT_META_KEY = 'left-meta'

export const RIGHT_META_KEY = 'right-meta'

const KEY_MAP = {
  9: TAB_KEY,
  13: ENTER_KEY,
  16: SHIFT_KEY,
  17: CONTROL_KEY,
  27: ESC_KEY,
  32: SPACE_KEY,
  38: UP_KEY,
  40: DOWN_KEY,
  70: F_KEY,
  91: LEFT_META_KEY,
  93: RIGHT_META_KEY
}

export default class Keyboard {

  static getKey(event) {
    return KEY_MAP[event.keyCode] || ''
  }
}
