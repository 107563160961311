import range from 'lodash.range'

export default function toTagMarks(cssArrs) {
  return cssArrs.reduce((tags, cssArr, line) => {
    if (cssArr.length === 0) {
      return tags
    }
    if (cssArr.length === 1) {
      const css = cssArr[0]
      return tags.concat({ line, fromCh: 0, toCh: 1, css })
    }
    const length = cssArr.length
    const css = cssArr[0]
    let tag = { line, css, fromCh: 0, toCh: 1 }

    return range(1, length)
      .reduce((t, i) => {

        const prev = cssArr[i - 1]
        const current = cssArr[i]
        const isLast = (i === length - 1)
        const isEqualed = (prev === current)

        if (isLast && isEqualed) {
          tag.toCh = i + 1
          return t.concat(tag)
        }
        if (isLast) {
          t = t.concat(tag)
          tag = { line, css: current, fromCh: i, toCh: i + 1 }
          return t.concat(tag)
        }
        if (isEqualed) {
          tag.toCh = i + 1
          return t
        }
        t = t.concat(tag)
        tag = { line, css: current, fromCh: i, toCh: i + 1 }
        return t
      }, tags)
  }, [])
}
