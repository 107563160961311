import get from 'lodash.get'
import isPlainObject from 'lodash.isplainobject'

const toStr = err => {

  // PromiseRejectionEvent
  if (err.reason) {
    return toStr(err.reason)
  }

  const response = get(err, 'payload.response.data.errors.response')

  if (Array.isArray(response)) {
    return response[0]
  }

  const message = get(err, 'payload.response.data.message')

  if (message) {
    return message
  }

  const data = get(err, 'payload.response.data')

  if (isPlainObject(data)) {
    return Object.values(data)
      .map(value => {
        if (typeof value === 'string') {
          return value
        }
        return JSON.stringify(value)
      })
      .join('\n')
  }

  const body = get(err, 'payload.body')

  // { status: 500, error: '...' }
  if (body && body.error) {
    return body.error
  }
  if (body) {
    return body
  }

  const statusText = get(err, 'payload.statusText')

  if (statusText) {
    return statusText
  }

  const payloadMessage = get(err, 'payload.message')
  if (payloadMessage) {
    return payloadMessage
  }
  return String(err)
}

export default function errToStr(err) {
  const errorStr = toStr(err)
  if (errorStr === 'Request failed with status code 500') {
    return '伺服器故障了，請稍後再試'
  }
  if (errorStr === 'Network Error') {
    return '網路連線錯誤，請檢查網路狀態'
  }
  if (errorStr === 'Request failed with status code 404') {
    return '伺服器回應 404'
  }
  if (/timeout of \d+ms exceeded/.test(errorStr)) {
    return '網路連線逾時'
  }
  return errorStr
}
