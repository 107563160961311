import splitMark from './splitMark'
import styleToObj from './styleToObj'

// 1. string style to object
// 2. split multi-line mark to single line marks

export default function toLineMarks(marks, lines) {
  return marks.map(mark => {
    const css = styleToObj(mark.css)
    return Object.assign({}, mark, { css, tagRow: mark.tagRow })
  })
  .reduce((arr, mark) => {
    const isMultiLine = (mark.from.line < mark.to.line)

    if (isMultiLine) {
      return [...arr, ...splitMark(mark, lines)]
    }
    return arr.concat(mark)
  }, [])
}
