import TrackerAffiliates from './TrackerAffiliates'
import TrackerBase from './TrackerBase'
import TrackerCustom from './TrackerCustom'
import TrackerFacebook from './TrackerFacebook'
import TrackerGa4 from './TrackerGa4'
import TrackerAds from './TrackerAds'
import TrackerGtm from './TrackerGtm'
import TrackerHotjar from './TrackerHotjar'
import TrackerLine from './TrackerLine'
import cacheTrackerMixin from '../mixins/cacheTrackerMixin'

const TrackerClasses = [
  TrackerAffiliates,
  TrackerCustom,
  TrackerFacebook,
  TrackerGa4,
  TrackerAds,
  TrackerGtm,
  TrackerHotjar,
  TrackerLine
]

class Tracker extends TrackerBase {

  constructor(params) {
    super()
    const { page, site, cart } = params
    this.page = page
    this.cart = cart
    this.setSite(site)

    this.trackers = TrackerClasses.map(TrackerClass => {
      try {
        return new TrackerClass(params).init()
      }
      catch (err) {
        console.error(err)
        return null
      }
    })
    .filter(tracker => tracker && tracker.enabled)

    this.pageParams = this._getPageParams(this.page)
    this.enabled = true

    cacheTrackerMixin(this)
  }

  enable() {
    this.enabled = true
  }

  disable() {
    this.enabled = false
  }

  setSite(site) {
    if (site) {
      this.site = site
    }
    else if (this.page) {
      this.site = this.page.site
    }
  }

  setCart(cart) {
    this.cart = cart
  }

  pageView(options) {
    this._run('pageView', {
      ...this.pageParams,
      page: this.page,
      cart: this.cart,
      site: this.site,
      step: options ? options.step : null,
      isOrderPage: options ? options.isOrderPage : null
    })
  }

  viewContent(options) {
    this._run('viewContent', {
      ...this.pageParams,
      page: this.page,
      cart: this.cart,
      site: this.site,
      sortedProductMap: options ? options.sortedProductMap : null
    })
  }

  addToCart({ productId, title, price, qty, totalAmount, currency, cart }) {
    this._run('addToCart', {
      productId,
      title,
      price,
      qty,
      totalAmount,
      currency,
      page: this.page,
      cart,
      site: this.site
    })
  }

  checkout({ title, cartId, totalOrderAmount, step, cart }) {
    this._run('checkout', {
      cartId,
      totalOrderAmount,
      step,
      title,
      page: this.page,
      cart,
      site: this.site
    })
  }

  purchase({ pageId, currency, pageTitle, orderId, orderKey,
    totalOrderAmount, orderMobile, orderEmail, order }) {

    this._run('purchase', {
      pageId,
      orderId,
      orderKey,
      totalOrderAmount,
      orderMobile,
      orderEmail,
      pageTitle,
      currency,
      page: this.page,
      cart: this.cart,
      site: this.site,
      order
    })
  }

  _run(method, ...args) {
    if (this.enabled) {
      this.trackers.forEach(tracker => {
        try {
          tracker[method](...args)
        }
        catch (err) {
          console.error(err)
        }
      })
    }
  }

  _getPageParams(page) {
    const { id, idPrefix, title, aliasName } = page
    return {
      title,
      pageAliasName: aliasName,
      pageId: idPrefix,
      pageDbId: id
    }
  }
}

export default Tracker
