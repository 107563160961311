export const SHOW_ALERT = '@landing-desktop/alert/SHOW_ALERT'
export const HIDE_ALERT = '@landing-desktop/alert/HIDE_ALERT'

export const showAlert = data => {
  return {
    type: SHOW_ALERT,
    data
  }
}

export const hideAlert = () => {
  return {
    type: HIDE_ALERT
  }
}

const initialState = {
  isVisible: false,
  data: {}
}

export default function alert(state = initialState, action) {

  switch (action.type) {
    case SHOW_ALERT:
      return { ...state, isVisible: true, data: action.data }
    case HIDE_ALERT: {
      const noop = () => {}
      const data = Object.assign({}, state.data, { cb: noop })
      return { ...state, isVisible: false, data }
    }
    default:
      return state
  }
}
