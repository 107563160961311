const PREFIX = '@common'

export const getKey = key => `${PREFIX}::${key}`

class Cache {

  static supported() {
    if (typeof localStorage === 'undefined') {
      return false
    }
    try {
      localStorage.setItem(PREFIX, PREFIX)
      localStorage.removeItem(PREFIX)
      return true
    }
    catch (err) {
      console.error(err)
      return false
    }
  }

  static set(key, value) {
    return localStorage.setItem(getKey(key), JSON.stringify(value))
  }

  static get(key) {
    try {
      return JSON.parse(localStorage.getItem(getKey(key)))
    }
    catch (err) {
      return null
    }
  }
}

export default Cache
