import getChineseDiscount from '@superlanding/getchinesediscount'
import {
  LAYOUT_DISCOUNT_CHINESE,
  LAYOUT_DISCOUNT_ENGLISH
} from '@common/consts/pageLayout'
import roundX from '@common/utils/roundX'

const chineseDiscount = value => {
  const text = getChineseDiscount(value)
  return text ? `${text} 折` : ''
}

export const discountPercent = value => roundX((1 - value) * 100)

const englishDiscount = value => {
  if (value < 1) {
    return `${discountPercent(value)}% Off`
  }
  // 沒有折扣 (value = 1) 與其他例外皆不顯示
  return ''
}

export default function discountText(value, type) {
  switch (type) {
    case LAYOUT_DISCOUNT_CHINESE:
      return chineseDiscount(value)
    case LAYOUT_DISCOUNT_ENGLISH:
      return englishDiscount(value)
    default:
      return chineseDiscount(value)
  }
}
