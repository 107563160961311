<template>
  <div class="cart-modal-loading">
    <div class="cart-modal-loading__heading">讀取購物車中...</div>
    <button class="btn" @click="$emit('close')">繼續購物</button>
  </div>
</template>

<script>

export default {
  name: 'CartModalLoading'
}
</script>

<style lang="scss" scoped>
.cart-modal-loading {
  text-align: center;
}
.cart-modal-loading__heading {
  color: $gray4;
  font-size: 1.625em;
  margin: 14px 0;
}
</style>
