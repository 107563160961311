import { TYPE_NAVBAR_EMPTY } from '@common/consts/component'

export const STRIPE_JS_URL = 'https://js.stripe.com/v3/'

export const STRIPE_CARD_STYLE = {
  base: {
    color: '#32325d',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
}

export const DEFAULT_CONTENT_DATA = {
  navbar: {
    type: TYPE_NAVBAR_EMPTY,
    data: {
      text: ''
    }
  },
  elems: []
}

export const DONATE_CODES = {
  '916': '台灣動物輔助活動及治療協會',
  '7995': '高雄市生命線協會',
  '333': '台灣婦女團體全國聯合會',
  '590': '弘道老人福利基金會'
}

export const TIME_ZONE = 'Asia/Taipei'

export const LOCALE = 'zh-TW'
