<template>
  <transition name="fade" appear>
    <div v-if="text" class="center-toast">{{ text }}</div>
  </transition>
</template>

<script>
export default {
  name: 'CenterToast',
  props: {
    text: String
  }
}
</script>

<style lang="scss" scoped>
.center-toast {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  background-color: $black5;
  color: $white1;
  border-radius: 4px;
  padding: 14px;
  max-width: 280px;
  white-space: pre;
}
</style>
