export const SHOW_CONFIRM = '@landing-desktop/confirm/SHOW_CONFIRM'
export const HIDE_CONFIRM = '@landing-desktop/confirm/HIDE_CONFIRM'

export const showConfirm = data => {
  return {
    type: SHOW_CONFIRM,
    data
  }
}

export const hideConfirm = () => {
  return {
    type: HIDE_CONFIRM
  }
}

const initialState = {
  isVisible: false,
  data: {}
}

export default function confirm(state = initialState, action) {

  switch (action.type) {
    case SHOW_CONFIRM:
      return { ...state, isVisible: true, data: action.data }
    case HIDE_CONFIRM: {
      const noop = () => {}
      const mutation = { confirm: noop, cancel: noop }
      const data = Object.assign({}, state.data, mutation)
      return { ...state, isVisible: false, data }
    }
    default:
      return state
  }
}
