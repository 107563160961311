import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'

// 比對兩個物件是否屬性完全一樣
// 可以透過 options.excludes 排除不需要比對的屬性
export default function eq(value1, value2, options = {}) {
  const v1 = cloneDeep(value1)
  const v2 = cloneDeep(value2)

  if (options.excludes) {
    options.excludes.forEach(prop => {
      delete v1[prop]
      delete v2[prop]
    })
    return isEqual(v1, v2)
  }
  return isEqual(v1, v2)
}
