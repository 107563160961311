import { get, isStr } from '@landing/common/utils'
import dollar from '@landing/landing-common/utils/dollar'

export default function currency(value) {
  const currency = get(this, '$consts.currency')

  // https://github.com/superlanding/landing/issues/1610
  if (isStr(currency) && (currency === 'MYR')) {
    return `${dollar(value)} RM`
  }
  // https://github.com/superlanding/landing/issues/1402
  if (isStr(currency) && (currency !== 'TWD')) {
    return `${dollar(value)} ${currency}`
  }
  return dollar(value)
}
