import { parse as parseUrl } from 'url'
import uniq from 'lodash.uniq'
import get from 'lodash.get'

const urlToPathname = url => parseUrl(url).pathname

export default function getSiteAliases(site) {
  if (! site) {
    return []
  }
  const paths = get(site, 'navbar.items', [])
    .map(item => {
      if (item.type === 'Page') {
        return urlToPathname(item.url)
      }
      if (item.type === 'NavCategory') {
        return item.pages.map(page => urlToPathname(page.url))
      }
      return []
    })
    .flat()

  return uniq(paths)
}
