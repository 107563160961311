import keyBy from 'lodash.keyby'
import range from 'lodash.range'
import BusinessHourRow from '@landing/common/models/BusinessHourRow'

export default function fillBusinessHourRow(rows) {
  const data = keyBy(rows, 'weekday')
  return range(7)
    .map(weekday => {
      const existedRow = data[weekday]
      if (existedRow) {
        return new BusinessHourRow(existedRow)
      }
      return new BusinessHourRow({ weekday })
    })
}
