<template>
  <div class="dropdown-btn" @click.stop="handleBtnClick">
    <div class="dropdown-btn__text">{{ text }}</div>
    <button class="dropdown-btn__btn btn-icon"
            :aria-label="ariaLabel">
      <span class="dropdown-btn__arrow" />
    </button>
    <transition name="fade">
      <div v-show="opened" class="dropdown-btn__menu">
        <div v-for="o in options" :key="o.value" @click="handleOptionClick(o)">{{ o.text }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
import domMixin from '@landing/common/mixins/domMixin'

export default {
  name: 'DropdownBtn',
  mixins: [domMixin],
  props: {
    ariaLabel: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    value: String
  },
  data() {
    return {
      opened: false
    }
  },
  computed: {
    text() {
      const row = this.options.find(o => o.value === this.value)
      return row ? row.text : ''
    }
  },
  mounted() {
    this.on(document, 'click', this.handleDocumentClick)
  },
  methods: {
    handleDocumentClick() {
      this.opened = false
    },
    handleBtnClick() {
      this.opened = (! this.opened)
    },
    handleOptionClick(option) {
      if (option.value !== this.value) {
        this.$emit('change', option.value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown-btn {
  user-select: none;
  color: $black7;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  overflow: visible;
  padding: 7px 0;
  line-height: 36px;
}
.no-flexbox .dropdown-btn {
  @include clearfix;
  display: inline-block;
  .dropdown-btn__text,
  .dropdown-btn__btn {
    float: left;
  }
}
.dropdown-btn__btn {
  margin-left: 9px;
  overflow: hidden;
  width: 28px;
  height: 36px;
  position: relative;
  display: inline-block;
}
.dropdown-btn__arrow {
  display: block;
  @include size(7px);
  border-right: 1px solid $black9;
  border-bottom: 1px solid $black9;
  transform: rotate(45deg);
}
.dropdown-btn__text {
  height: 36px;
  display: inline-block; // for IE9
  min-width: 96px;
  padding-left: 14px;
}
.dropdown-btn__menu {
  background-color: $white1;
  border: 1px solid $gray11;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 100%;
  > div {
    transition: .2s all;
    padding: 14px;
    &:hover {
      background-color: $gray10;
    }
  }
}
</style>
