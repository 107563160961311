<template>
  <div class="order-item-menu-product">
    <app-image v-if="item.hasImg"
               class="order-item-menu-product__img"
               :class="{ 'order-item-menu-product__img--error': hasImageError }"
               :src="productImgSrc"
               :alt="item.title"
               @error="hasImageError = true" />
    <form class="order-item-menu-product__form form" @submit.prevent="handleSubmit">
      <div class="order-item-menu-product__title">
        <strong v-if="item.isAdditionalPurchase"
                class="order-item-menu-product__label">[加購]</strong>
        {{ item.title }}
      </div>
      <preorder-info v-if="item.isPreorder"
                     class="order-item-menu-product__preorder-info"
                     :item="item"
                     mode="list" />
      <div v-if="item.strengthening" class="txt-strengthening" v-text="item.strengthening" />
      <div v-if="item.additional" class="txt-additional" v-text="item.additional" />
      <product-desc-links class="order-item-menu-product__product-desc-links"
                          :item="item"
                          :active="activeProductKey"
                          @click="setProductImgRow" />
      <label v-if="item.hasSpec">
        <span>選擇規格</span>
        <select v-model="selectedSpecId" class="select" data-cy="spec-select">
          <option v-for="o in item.specOptions"
                  :key="o.value"
                  :value="o.value"
                  :disabled="o.status === 'out_of_stock'">{{ o.text }}</option>
        </select>
      </label>
      <label>
        <span>選擇數量</span>
        <select v-model="selectedQuantity" class="select" data-cy="qty-select">
          <option v-for="o in item.quantityOptions"
                  :key="o.value"
                  :value="o.value">{{ o.text }}</option>
        </select>
      </label>
      <button class="btn-primary fw" type="submit">加到購物車</button>
    </form>
    <close-btn class="order-item-menu-product__close-btn"
               @click="$emit('close')" />
  </div>
</template>

<script>
import AppImage from './AppImage'
import CloseBtn from './CloseBtn'
import PreorderInfo from '@landing/landing-common/components/PreorderInfo'
import ProductDescLinks from '@landing-common/components/ProductDescLinks'
import productImgMixin from '@landing-common/mixins/productImgMixin'

export default {
  name: 'OrderItemMenuProduct',
  components: {
    AppImage,
    CloseBtn,
    PreorderInfo,
    ProductDescLinks
  },
  mixins: [productImgMixin],
  props: {
    item: Object
  },
  data() {
    const { defaultSelectedSpecId, defaultSelectedQuantity } = this.item
    return {
      hasImageError: false,
      selectedSpecId: defaultSelectedSpecId,
      selectedQuantity: defaultSelectedQuantity
    }
  },
  methods: {
    handleSubmit() {
      const { id, title, image, price, isAdditionalPurchase } = this.item
      const { selectedQuantity, selectedSpecId } = this
      const data = {
        id,
        type: 'product',
        title,
        image,
        qty: selectedQuantity,
        price,
        isAdditionalPurchase
      }
      if (this.item.hasSpec) {
        data.specId = selectedSpecId
      }
      this.$emit('submit', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.order-item-menu-product {
  border-radius: 4px;
  position: relative;
  background-color: $white1;
  display: flex;
  align-items: center;
  max-height: 100vh;
  overflow-y: auto;

  @media (max-width: 810px) {
    display: block;
  }
}
.order-item-menu-product__img {
  display: block;
  max-width: 420px;
  height: auto;
  margin: 0 auto;
}
.order-item-menu-product__form {
  min-width: 340px;
  padding: 24px;
  align-self: center;
  label {
    margin-top: 14px;
    margin-bottom: 14px;
  }
}
.btn-primary {
  margin-top: 48px;
}
.order-item-menu-product__close-btn {
  position: absolute;
  top: 0;
  right: 0;
}
.order-item-menu-product__title {
  font-weight: 400;
  font-size: 1.2em;
  margin-bottom: 4px;
  margin-right: 24px;
}
.order-item-menu-product__label {
  color: $blue6;
}
.txt-strengthening {
  font-size: .675em;
}
.txt-additional {
  white-space: pre-wrap;
  margin-top: 4px;
  max-height: 40vh;
  overflow-y: auto;
  font-size: .875em;
  line-height: 1.6em;
}
.order-item-menu-product__preorder-info {
  margin-top: .2em;
  margin-bottom: .5em;
}
.order-item-menu-product__product-desc-links {
  margin-top: .4em;
}
</style>
