import { getWhatsappUrl } from '@landing/common/utils'

export default {
  computed: {
    fbUrl() {
      const { fanPage } = this.page
      return fanPage ? `https://m.me/${fanPage.id}` : ''
    },
    lineUrl() {
      const { line } = this.page
      return line ? line.url : ''
    },
    whatsappUrl() {
      const { whatsapp } = this.page
      return whatsapp ? getWhatsappUrl(whatsapp.numbers) : ''
    }
  }
}
