<template>
  <span class="badge">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'Badge'
}
</script>

<style lang="scss" scoped>
.badge {
  white-space: nowrap;
  display: inline-block;
  background: #555;
  color: #fff;
  padding: .1em .4em;
  border-radius: 4px;
}
</style>
