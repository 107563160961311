import extend from '@superlanding/extend'
import compact from 'lodash.compact'

const defaultProps = {
  country: '',
  state: '',
  city: '',
  zipcode: '',
  addr1: '',
  addr2: ''
}

class IntlAddressBase {

  constructor(props) {
    extend(this, defaultProps, props)
  }

  clone(props) {
    return new this.constructor(extend({}, this, props))
  }

  static from(props = {}) {
    return new this(props)
  }

  toString(delimiter = ', ') {
    return compact([
      this.addr1,
      this.addr2,
      compact([this.city, this.zipcode]).join(' '),
      compact([this.state, this.country]).join(' ')
    ])
    .join(delimiter)
  }
}

export default IntlAddressBase
