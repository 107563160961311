<template>
  <app-backdrop :is-visible="isVisible" @click="$emit('backdrop-click')">
    <transition name="pop" appear>
      <div v-if="isVisible"
           ref="menu"
           class="popup-menu"
           @click="handleMenuClick">
        <div class="popup-menu__modal">
          <slot />
        </div>
      </div>
    </transition>
  </app-backdrop>
</template>

<script>
import getScrollMixin from '@landing/common/mixins/bodyScrollMixin'
import AppBackdrop from './AppBackdrop'

const bodyScrollMixin  = getScrollMixin('isVisible')

export default {
  name: 'PupupMenu',
  components: {
    AppBackdrop
  },
  mixins: [bodyScrollMixin],
  props: {
    isVisible: Boolean
  },
  methods: {
    handleMenuClick(event) {
      if (this.$refs.menu === event.target) {
        this.$emit('backdrop-click')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-menu {
  @include fixed-center;
  @include flex-center;
}
.no-flexbox .popup-menu {
  @include fixed-center;
  text-align: center;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  .popup-menu__modal {
    text-align: left;
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
