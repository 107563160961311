<template>
  <div class="paragraph-element" data-cy="paragraph-elem">
    <div :class="getAlignClassName(data.data.align)" v-html="html" />
  </div>
</template>

<script>
import alignMixin from '@common/mixins/alignMixin'
import renderMarks from '@common/utils/renderMarks'

export default {
  name: 'ParagraphElement',
  mixins: [alignMixin],
  props: {
    data: Object
  },
  computed: {
    html() {
      const { paragraph, marks } = this.data.data
      return renderMarks(paragraph, marks)
    }
  }
}
</script>

<style lang="scss" scoped>
.paragraph-element {
  font-family: noto sans tc,sans-serif !important;
  color: #4d4d4d;
  font-size: 1.1rem;
  line-height: 2.2rem;
  padding: 15px $article-horizontal-padding;
  font-weight: 400;
}
</style>
