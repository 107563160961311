// https://github.com/superlanding/landing/issues/1254
import Cache from '@landing/common/models/Cache'

export default function cacheTrackerMixin(self) {

  if (! Cache.supported()) {
    return self
  }

  const cacheKey = `${location.hostname}::disabledTrackingPageIds`

  const getDisabledPageIds = () => {
    return Cache.get(cacheKey) || []
  }

  const addDisabledPageId = pageId => {
    const pageIds = getDisabledPageIds()
    if (! pageIds.includes(pageId)) {
      pageIds.push(pageId)
      Cache.set(cacheKey, pageIds)
    }
  }

  const removeDisabledPageId = pageId => {
    const pageIds = getDisabledPageIds()
      .filter(id => id !== pageId)
    Cache.set(cacheKey, pageIds)
  }

  const isDisabledPageId = getDisabledPageIds()
    .includes(self.page.id)

  self.enabled = (! isDisabledPageId)

  const enable = self.enable.bind(self)

  self.enable = (...args) => {
    removeDisabledPageId(self.page.id)
    return enable(...args)
  }

  const disable = self.disable.bind(self)

  self.disable = (...args) => {
    addDisabledPageId(self.page.id)
    return disable(...args)
  }

  return self
}
