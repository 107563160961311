import pageEpics from './page'
import cartEpics from './cart'
import orderEpics from './order'
import toastEpics from './toast'
import { combineEpics } from '@landing/landing-desktop/utils'

export default combineEpics(
  ...pageEpics,
  ...cartEpics,
  ...toastEpics,
  ...orderEpics
)
