<template>
  <div class="navbar-element" data-cy="navbar-elem">{{ text }}</div>
</template>

<script>
import Countdown from '@landing/common/models/Countdown'
import {
  TYPE_NAVBAR_COUNTDOWN,
  TYPE_NAVBAR_STATIC
} from '@common/consts/component'

export default {
  name: 'NavbarElement',
  props: {
    data: Object
  },
  data() {
    return {
      countdownText: ''
    }
  },
  computed: {
    text() {
      const { type } = this.data
      if (type === TYPE_NAVBAR_STATIC) {
        return this.data.data.text
      }
      if (type === TYPE_NAVBAR_COUNTDOWN) {
        return this.countdownText
      }
      return ''
    }
  },
  created() {
    const isClient = (typeof window !== 'undefined')
    if (isClient && (this.data.type === TYPE_NAVBAR_COUNTDOWN)) {
      this.startCountdown()
    }
  },
  beforeDestroy() {
    cancelAnimationFrame(this.timer)
  },
  methods: {
    startCountdown() {
      const { days } = this.data.data
      this.countdownTime = Countdown.getCountdownTime(days)

      let last = 0
      const draw = (now = 0) => {
        // execute every seconds
        if ((last === 0) || ((now - last) > 1000)) {
          last = now
          this.setCountdownText()
        }
        this.timer = requestAnimationFrame(draw)
      }
      draw()
    },
    setCountdownText() {
      const { days, hours, minutes, seconds } = Countdown.getDiffTimeData(this.countdownTime)
      this.countdownText = this.data.data.text.replace(/%D/g, days)
        .replace(/%H/, hours)
        .replace(/%M/, minutes)
        .replace(/%S/, seconds)

      const ended = [days, hours, minutes, seconds].every(value => value === 0)
      if (ended) {
        cancelAnimationFrame(this.timer)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar-element {
  background-color: #d9534f;
  color: #fff;
  font-size: .875em;
  text-align: center;
  padding-top: 7px;
  padding-bottom: 7px;
  min-height: 34px;
}
</style>
