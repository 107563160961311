<template>
  <table class="cart-modal-list">
    <thead>
      <tr>
        <th>商品</th>
        <th class="no-wrap">數量</th>
        <th class="cart-modal-list__subtotal-col no-wrap">小計</th>
        <th class="cart-modal-list__remove-btn-col" />
      </tr>
    </thead>
    <transition-group name="fade" tag="tbody">
      <cart-modal-list-item v-for="item in items"
                            :key="item.key"
                            :item="item"
                            :has-image-col="hasImageCol"
                            @remove-item="$emit('remove-item', item)" />
    </transition-group>
  </table>
</template>

<script>
import CartModalListItem from './CartModalListItem'

export default {
  name: 'CartModalList',
  components: {
    CartModalListItem
  },
  props: {
    items: Array
  },
  computed: {
    hasImageCol() {
      return this.items.some(item => item.source && item.source.image)
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-modal-list {
  color: $black5;
  width: 100%;
}
.cart-modal-list__item-qty {
  color: $blue6;
}
.cart-modal-list__item-spec {
  color: $purple1;
}
thead {
  background-color: $gray12;
}
th {
  font-weight: 400;
  padding: 14px 16px;
  text-align: left;
}
.cart-modal-list__remove-btn-col {
  width: 40px;
}
.cart-modal-list__subtotal-col {
  text-align: right;
  width: 100px;
}
</style>
