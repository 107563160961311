/* global _lt */
import TrackerBase from './TrackerBase'

class TrackerLine extends TrackerBase {

  constructor({ page }) {
    super()
    this.page = page
  }

  init() {
    const { lineTag } = this.page
    this.tagId = lineTag && lineTag.id
    if (! this.tagId) {
      return this
    }
    this.customerType = lineTag.customerType
    this._loadScript()
    this.initLine()
    this.enabled = true
    return this
  }

  pageView() {
    _lt('send', 'pv', [this.tagId])
  }

  viewContent() {
  }

  addToCart() {
  }

  checkout() {
  }

  purchase() {
    _lt('send', 'cv', {
      type: 'Conversion'
    }, [this.tagId])
  }

  initLine() {
    _lt('init', {
      customerType: this.customerType,
      tagId: this.tagId
    })
  }

  _loadScript() {
    // https://tw.linebiz.com/manual/line-official-account/oa-manager-linetag
    // https://help2.line.me/admanager_tw/web/?contentId=20016939
    // 必需要註冊 LINE 官方帳號
    (function(g,d,o){
      g._ltq=g._ltq||[];g._lt=g._lt||function(){g._ltq.push(arguments)};
      var h=location.protocol==='https:'?'https://d.line-scdn.net':'http://d.line-cdn.net';
      var s=d.createElement('script');s.async=1;
      s.src=o||h+'/n/line_tag/public/release/v1/lt.js';
      var t=d.getElementsByTagName('script')[0];t.parentNode.insertBefore(s,t);
    })(window, document);
  }
}

export default TrackerLine
