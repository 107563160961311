<template>
  <transition name="fade">
    <div v-show="isVisible" class="app-backdrop" @click="$emit('click')">
      <div @click.stop>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'AppBackdrop',
  props: {
    isVisible: Boolean
  }
}
</script>

<style lang="scss" scoped>
.app-backdrop {
  background-color: $black11;
  @include fixed-center;
  z-index: 1;
}
</style>
