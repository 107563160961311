import DiscountLv from '@landing/common/models/DiscountLv'

export const SET_ORDER = '@landing-desktop/order/SET_ORDER'
export const SET_INVOICE = '@landing-desktop/order/SET_INVOICE'
export const SET_INVOICE_LOADING = '@landing-desktop/order/SET_INVOICE_LOADING'
export const SET_INVOICE_SUCCESS = '@landing-desktop/order/SET_INVOICE_SUCCESS'
export const SET_INVOICE_FAIL = '@landing-desktop/order/SET_INVOICE_FAIL'

export const setOrder = data => {
  return {
    type: SET_ORDER,
    data
  }
}

export const setInvoice = data => {
  const postData = {
    order: data.data
  }
  return {
    type: SET_INVOICE,
    orderId: data.orderId,
    key: data.key,
    data: postData,
    async: {
      loading: SET_INVOICE_LOADING,
      resolve: SET_INVOICE_SUCCESS,
      reject: SET_INVOICE_FAIL
    }
  }
}

const initialState = {
  order: {},
  isSettingInvoice: false
}

export default function order(state = initialState, action) {

  switch (action.type) {
    case SET_ORDER: {
      if (! action.data) {
        return state
      }
      const order = { ...action.data }
      if (order.discountLevel) {
        order.discountLevel = DiscountLv.from(order.discountLevel)
      }
      return { ...state, order }
    }
    case SET_INVOICE_LOADING:
      return { ...state, isSettingInvoice: true }
    case SET_INVOICE_SUCCESS:
      return { ...state, isSettingInvoice: false, order: { ...action.payload.order } }
    case SET_INVOICE_FAIL:
      return { ...state, isSettingInvoice: false }
    default:
      return state
  }
}
