<template>
  <div class="address-element" data-cy="address-elem">
    <div class="address-element__jumbotron">
      <div v-if="type === $options.TYPE_ADDRESS_IMAGE"
           class="address-element__img">
        <a v-if="href" :href="href" target="_blank">
          <img :src="image" alt="shop image">
        </a>
        <img v-else :src="image" alt="shop image">
      </div>
      <div v-else-if="type === $options.TYPE_ADDRESS_GOOGLE_MAP"
           class="iframe-rwd"
           v-html="html" />
    </div>
    <div class="address-element__content">
      <div class="address-element__heading" v-text="name" />
      <ul class="address-element__list">
        <li>
          <i class="icon-pin" />
          {{ address }}
        </li>
        <li v-if="addressDesc">
          <i class="icon-target" />
          {{ addressDesc }}
        </li>
        <li v-if="info">
          <i class="icon-info" />
          {{ info }}
        </li>
        <li v-if="businessHours.length > 0">
          <i class="icon-clock" />
          <business-hour-dropdown class="address-element__business-hour-dropdown" :rows="businessHours" />
        </li>
        <li v-if="phoneNumbers.length > 0">
          <i class="icon-phone" />
          <div class="address-element__phone-wrap">
            <div v-for="(phoneNumber, i) in phoneNumbers"
                 :key="i"
                 v-text="phoneNumber" />
          </div>
        </li>
        <li v-if="supportCreditCard">
          <i class="icon-credit-card" />
          接受信用卡
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import BusinessHourDropdown from '@landing/common/components/BusinessHourDropdown'
import elementMixin from '@landing/common/mixins/elementMixin'
import {
  TYPE_ADDRESS_IMAGE,
  TYPE_ADDRESS_GOOGLE_MAP
} from '@landing/common/consts/address'

export default {
  name: 'AddressElement',
  components: {
    BusinessHourDropdown
  },
  mixins: [elementMixin],
  props: {
    data: Object
  },
  computedProps: [
    'href',
    'type',
    'html',
    'image',
    'name',
    'address',
    'addressDesc',
    'info',
    'businessHours',
    'phoneNumbers',
    'supportCreditCard'
  ],
  TYPE_ADDRESS_IMAGE,
  TYPE_ADDRESS_GOOGLE_MAP
}
</script>

<style lang="scss" scoped>
.address-element {
  padding-top: 36px;
  padding-bottom: 20px;
  padding-left: $article-horizontal-padding;
  padding-right: $article-horizontal-padding;
  min-height: 300px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > div {
    flex-basis: 50%;
    flex-grow: 1;
  }
}
.address-element__content {
  min-width: 320px;
  padding-left: 20px;
}
.address-element__heading {
  color: #2c2c2c;
  font-size: 21px;
  line-height: 1;
  font-weight: 900;
}
.address-element__list {
  margin-top: 20px;
  font-size: 1rem;
  li {
    margin: 10px 0;
    > i {
      display: inline-block;
      @include size(30px);
      line-height: 30px;
      text-align: center;
      vertical-align: middle;
      transform: translateY(-1px);
    }
    > i.icon-phone,
    > i.icon-clock {
      vertical-align: top;
    }
  }
}
.address-element__phone-wrap {
  padding-top: 2px;
  display: inline-block;
}
.address-element__business-hour-dropdown {
  padding-top: 3px;
}
.address-element__img img {
  max-width: 100%;
  height: auto;
}
.address-element__jumbotron {
  padding-bottom: 14px;
}
</style>
