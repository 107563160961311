const getFirstImgRow = self => {
  if (self.item) {
    return self.item.imgRows[0]
  }
  return null
}

export default {
  data() {
    return {
      productImgRow: getFirstImgRow(this)
    }
  },
  watch: {
    item() {
      this.productImgRow = getFirstImgRow(this)
    }
  },
  methods: {
    setProductImgRow(row) {
      this.productImgRow = row
    }
  },
  computed: {
    activeProductKey() {
      const row = this.productImgRow
      return row ? row.key : ''
    },
    productImgSrc() {
      const { productImgRow } = this
      if (productImgRow) {
        return productImgRow.src
      }
      return ''
    }
  }
}
