import http from '../plugins/http'
import { uuid } from '../utils'

const buildParams = (params = {}) => {
  const eventId = uuid()
  return Object.assign({}, params, { eventId })
}

const getBaseUrl = page => {
  if (page.aliasName) {
    return `/${page.aliasName}`
  }
  if (page.isEntry) {
    return '/entry'
  }
  return `/contents/${page.id}`
}

class FacebookConversion {

  static pageView(page) {
    const params = buildParams()
    const baseUrl = getBaseUrl(page)
    http.post(`${baseUrl}/sse_pv.json`, params)
    return params.eventId
  }

  static viewContent(page) {
    const params = buildParams()
    const baseUrl = getBaseUrl(page)
    http.post(`${baseUrl}/sse_vc.json`, params)
    return params.eventId
  }

  // AddToCart 只有銷售頁會觸發
  static addToCart(page, orderProductId) {
    const params = buildParams({ orderProductId })
    http.post(`/${page.aliasName}/sse_a2c.json`, params)
    return params.eventId
  }

  // Purchase 只有銷售頁會觸發
  static purchase(page, orderId, orderKey) {
    const params = buildParams({ id: orderId, key: orderKey })
    http.post(`/${page.aliasName}/sse_pc.json`, params)
    return params.eventId
  }
}

export default FacebookConversion
