import { get, isStr } from '@landing/common/utils'

class HumanError {

  static toString(err) {
    if (isStr(err)) {
      return err
    }
    const errStr = get(err, 'response.data.errors')
    if (isStr(errStr)) {
      return errStr
    }
    const message = get(err, 'payload.response.data.message')
    if (message) {
      return message
    }
    if (err.payload) {
      return err.payload.message
    }
    if (err.message) {
      return err.message
    }
    return String(err)
  }

  static getMessage(err) {
    const errorStr = HumanError.toString(err)
    if (errorStr === 'Network Error') {
      return '網路連線錯誤，請檢查網路狀態'
    }
    if (errorStr === 'Request failed with status code 404') {
      return '伺服器回應 404，有可能是商家換網址了'
    }
    if (/timeout of \d+ms exceeded/.test(errorStr)) {
      return '網路連線逾時'
    }
    return errorStr
  }
}

export default HumanError
