<template>
  <div class="coupon-form card">
    <button v-if="mode === 'show'"
            data-cy="coupon-btn"
            class="btn coupon-form__btn-mode"
            @click="mode = 'edit'">
      我要輸入優惠券
    </button>

    <form v-if="mode ==='edit'" class="form" @submit.stop.prevent="handleSubmit">
      <label class="coupon-form__first-label">
        <span>折扣碼</span>
        <input-text v-model="couponCode" data-cy="coupon-input" :error="!! errorMessage" />
        <div v-if="errorMessage" class="field-error">{{ errorMessage }}</div>
        <div class="field-info">若有優惠券，請先填寫折扣碼再結帳哦</div>
        <button class="coupon-form__btn-use-coupon btn fw"
                type="submit"
                data-cy="coupon-submit"
                :disabled="isLoadingCoupon">使用優惠券</button>
      </label>
    </form>
  </div>
</template>

<script>
import InputText from '@landing/landing-common/components/InputText'
import { setCoupon } from '@landing/landing-desktop/redux/reducers/cart'
import { addToastItem } from '@landing/landing-desktop/redux/reducers/toast'
import HumanError from '@landing/landing-common/models/HumanError'

export default {
  name: 'CouponForm',
  components: {
    InputText
  },
  data() {
    return {
      mode: 'show',
      couponCode: '',
      errorMessage: '',
      isLoadingCoupon: false
    }
  },
  beforeCreate() {
    this.mapDispatch({ setCoupon, addToastItem })
  },
  methods: {
    async handleSubmit() {
      if (! this.couponCode) {
        this.errorMessage = '請填寫折扣碼'
        return
      }
      try {
        this.isLoadingCoupon = true
        await this.setCoupon(this.couponCode)
        this.mode = 'show'
        this.addToastItem({ message: '優惠券套用成功' })
      }
      catch (err) {
        const { errors } = err.payload.response.data || {}
        if (errors && errors.couponCode) {
          this.errorMessage = errors.couponCode[0]
          return
        }
        const message = HumanError.getMessage(err)
        if (message) {
          this.addToastItem({ message })
        }
      }
      finally {
        this.isLoadingCoupon = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.coupon-form {
  width: 290px;
  padding: 14px 0;
}
.coupon-form__message {
  text-align: center;
  padding: 0 0 7px;
  color: $black5;
}
.coupon-form__btn-mode {
  display: block;
}
.coupon-form__first-label > span {
  padding-top: 0;
}
.coupon-form__btn-use-coupon {
  margin-top: 20px;
}
</style>
