<template>
  <div class="business-hour-dropdown"
       :class="{ 'business-hour-dropdown--collapsed': collapsed }"
       @click="toggleDropdown">
    <span :class="statusClassname" v-text="text" />
    <i class="icon-chevron-down" />
    <div v-if="collapsed"
         class="business-hour-dropdown__menu">
      <div v-for="row in computedRows"
           :key="row.key"
           class="business-hour-dropdown__menu-item">
        <div class="business-hour-dropdown__weekday"
             v-text="row.weekdayText" />

        <div v-if="! row.work" v-text="row.offText" />
        <div v-else-if="row.open24Hours" v-text="row.open24HoursText" />
        <div v-else>
          <div v-for="t in row.timeRangeTexts" :key="t.key">
            {{ t.startAt }} ~ {{ t.endAt }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fillBusinessHourRow from '@landing/common/utils/fillBusinessHourRow'

export default {
  name: 'BusinessHourDropdown',
  props: {
    rows: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      collapsed: true
    }
  },
  computed: {
    statusClassname() {
      return this.isOpening() ? 'open' : 'off'
    },
    text() {
      const row = this.getCurrentRow()
      return row ? row.statusText : '休息中'
    },
    computedRows() {
      return fillBusinessHourRow(this.rows)
    }
  },
  methods: {
    toggleDropdown() {
      this.collapsed = (! this.collapsed)
    },
    getCurrentRow() {
      const weekday = new Date().getDay()
      return this.computedRows.find(row => (row.weekday === weekday) && row.work)
    },
    isOpening() {
      const currentRow = this.getCurrentRow()
      if (! currentRow) {
        return false
      }
      if (currentRow.open24Hours) {
        return true
      }
      if (currentRow.getCurrentTimeRange()) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.business-hour-dropdown {
  cursor: pointer;
  display: inline-block;
  .open {
    color: #25b47e;
  }
  .off {
    color: #cd3d64;
  }
  .icon-chevron-down {
    transition: .3s all;
    display: inline-block;
    font-size: 10px;
    transform: translateY(-1px);
    margin-left: 7px;
  }
  &.business-hour-dropdown--collapsed .icon-chevron-down {
    transform: rotate(180deg) translateY(2px);
  }
}
.business-hour-dropdown__menu-item {
  display: flex;
  margin: 7px 0;
}
.business-hour-dropdown__weekday {
  margin-right: 1em;
}
</style>
