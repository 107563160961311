const COUNTDOWN_COOKIE_KEY = 'countdown_date'
const toSeconds = ms => parseInt(ms / 1000, 10)

class Countdown {

  static getCountdownTimeFromCookie() {
    const { cookie } = document
    if (cookie.includes(`${COUNTDOWN_COOKIE_KEY}=`)) {
      return cookie.split(`${COUNTDOWN_COOKIE_KEY}=`)[1]
        .split(';')[0]
    }
    return null
  }

  static setCountdownTimeToCookie(date) {
    const currentPath = location.pathname
    const expire = new Date(date)

    // 6 小時空白, 避免被人發現一直倒數
    const countdownSleepTime = (6 * 60 * 60 * 1000)
    expire.setTime(expire.getTime() + countdownSleepTime)
    const cookieStr = `${COUNTDOWN_COOKIE_KEY}=${date}; expires=${expire.toGMTString()}; path=${currentPath}`
    document.cookie = cookieStr
  }

  static createCountdownTime(days) {
    const daysTime = parseInt(days, 10) * 86400 * 1000
    let date = new Date()
    date = date.setTime(date.getTime() + daysTime)
    date = new Date(date)

    const dateStr = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    Countdown.setCountdownTimeToCookie(dateStr)
    return dateStr
  }

  static getCountdownTime(days) {
    const countdownTime = Countdown.getCountdownTimeFromCookie()
    return countdownTime ? countdownTime : Countdown.createCountdownTime(days)
  }

  static getDiffTimeData(timeStr) {

    const endSecs = (() => {
      const [year, month, date] = timeStr.split('/')
      return toSeconds(+new Date(year, month - 1, date, 0, 0, 0))
    })()

    const now = toSeconds(+new Date())

    let days = 0
    let hours = 0
    let minutes = 0
    let seconds = 0

    if (now >= endSecs) {
      return { days, hours, minutes, seconds }
    }
    let diffSecs = endSecs - now
    days = Math.floor(diffSecs / 86400)
    diffSecs -= days * 86400

    hours = Math.floor(diffSecs / 3600)
    diffSecs -= hours * 3600

    minutes = Math.floor(diffSecs / 60)
    diffSecs -= minutes * 60

    seconds = diffSecs

    return { days, hours, minutes, seconds }
  }
}

export default Countdown
