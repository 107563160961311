import { animateScrollTo } from '../utils'
import { SHRANK_SITE_NAV_HEIGHT } from '../consts'

export default {
  data() {
    return this.mapState({
      navbarHeight: 'app.navbarHeight'
    })
  },
  methods: {
    scrollToProductList() {
      const dom = document.getElementById('product-list')
      const offset = -(SHRANK_SITE_NAV_HEIGHT + this.navbarHeight)
      animateScrollTo(dom, { speed: 30, offset })
    }
  }
}
