import match from './match'

export default function getXdeliveryTrackingData(code) {
  // https://bit.ly/3tw4aC6
  // XD_#{xdelivery_id}_#{tracking_code}
  let res = match(code, /^XD_(.+)_(.+)/)
  if (res[2]) {
    return {
      shippingNo: res[1],
      trackingCode: res[2]
    }
  }
  res = match(code, /^XD_(.+)/)
  if (res[1]) {
    return {
      shippingNo: res[1],
      trackingCode: null
    }
  }
  return null
}
