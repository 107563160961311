<template>
  <svg width="48px" height="48px" viewBox="0 0 48 48">
    <g id="btn-up" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <polyline stroke="#555555" stroke-width="4" points="14 28 24 18 34 28" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'UpIconSvg'
}
</script>
