<template>
  <form class="order-item-menu-promo" @submit.prevent="handleSubmit">
    <div class="order-item-menu-promo__title">{{ item.title }}</div>
    <div v-if="item.products.length === 0" class="order-item-menu-promo__no-products">
      目前沒有任何產品可以選擇，請稍後再試
    </div>
    <div v-else class="order-item-menu-promo__main-box">
      <div class="order-item-menu-promo__main">
        <order-item-menu-product-list class="order-item-menu-promo__product-list"
                                      :items="item.products"
                                      :page="page"
                                      @item-click="selectProduct" />
        <div class="order-item-menu-promo__promo-list">
          <div v-if="(item.promoType === 'discounts' && discount)"
               class="alert alert-info">
            請混搭選擇 <strong class="num" v-text="quantity" /> 個商品即可享有 <strong class="num" v-text="discount" /> 折優惠<template v-if="hasDiscountRange">，<br>如需加購請選擇 {{ discountRange }} 個以此類推</template>
          </div>
          <div v-else-if="item.promoType === 'discounts'" class="alert alert-info">
            請混搭選擇 <strong class="num" v-text="quantity" /> 個商品
            <template v-if="hasDiscountRange">，<br>如需加購請選擇 {{ discountRange }} 個以此類推</template>
          </div>
          <div v-else class="alert alert-info fixed-price-info">
            請選擇 <strong class="num" v-text="quantity" /> 個產品，原價 <deleted-price class="fixed-price-info__original-price" v-text="originalPrice" />，現在特價只要 <strong class="num" v-text="price" /> 元
          </div>
          <div v-if="selectedItemsCount === 0"
               class="order-item-menu-promo__tips">
            你還未選擇任何商品，請從左側清單挑選
          </div>
          <order-item-menu-user-promo-list v-else
                                           :items="userPromoItems"
                                           class="user-promo-items__list"
                                           @plus="handlePlusPromoItem"
                                           @minus="handleMinusPromoItem"
                                           @remove="removeUserPromoItem" />
        </div>
      </div>
      <div class="order-item-menu-promo__checkout-bar">
        <promo-hint :item="item" :selected-items="userPromoItems" :cart="cart" />
        <button class="btn-primary fw"
                type="submit"
                data-cy="promo-submit"
                :disabled="isPrimaryBtnDisabled">加到購物車</button>
      </div>
    </div>

    <close-btn class="order-item-menu-promo__close-btn" @click="$emit('close')" />
    <portal to="promo-product">
      <order-item-menu-promo-product :item="promoProduct"
                                     :max="quantity"
                                     @submit="addItemToUserPromoItems"
                                     @close="clearSelectedProduct" />
    </portal>
  </form>
</template>

<script>
import Product from '@landing/landing-common/models/Product'
import { getApStr } from '@landing/landing-desktop/utils'

import CloseBtn from './CloseBtn'
import DeletedPrice from './DeletedPrice'
import OrderItemMenuProductList from './OrderItemMenuProductList'
import OrderItemMenuPromoProduct from './OrderItemMenuPromoProduct'
import OrderItemMenuUserPromoList from './OrderItemMenuUserPromoList'
import PromoHint from '@landing/landing-common/components/PromoHint'
import discountText from '@landing-desktop/utils/discountText'

export default {
  name: 'OrderItemMenuPromo',
  components: {
    CloseBtn,
    DeletedPrice,
    OrderItemMenuPromoProduct,
    OrderItemMenuUserPromoList,
    OrderItemMenuProductList,
    PromoHint
  },
  props: {
    item: Object,
    cart: Object,
    page: Object
  },
  data() {
    return {
      promoProduct: null,
      userPromoItems: []
    }
  },
  computed: {
    isPrimaryBtnDisabled() {
      return (this.item.products.length === 0)
    },
    isDiscountType() {
      return (this.item.promoType === 'discounts')
    },
    originalPrice() {
      return this.item.promoDetails.originalPrice
    },
    quantity() {
      return this.item.promoDetails.qty
    },
    discount() {
      return discountText(this.item.promoDetails.rate)
    },
    hasDiscountRange() {
      return this.item.promoDetails.interval > 0
    },
    discountRange() {
      const { qty, interval } = this.item.promoDetails
      return `${getApStr({ qty, interval })  }...`
    },
    price() {
      return this.item.promoDetails.price
    },
    selectedItemsCount() {
      return this.userPromoItems.reduce((total, row) => row.qty + total, 0)
    }
  },
  methods: {
    selectProduct(product) {
      this.promoProduct = new Product(product)
    },
    clearSelectedProduct() {
      this.promoProduct = null
    },
    removeUserPromoItem(item) {
      this.userPromoItems = this.userPromoItems.filter(row => row.uuid !== item.uuid)
    },
    handlePlusPromoItem(item) {
      const isDiscountType = (this.item.promoType === 'discounts')
      this.userPromoItems = this.userPromoItems.map(row => {
        const sameId = (row.uuid === item.uuid)
        if (isDiscountType && sameId) {
          return Object.assign({}, row, { qty: row.qty + 1 })
        }
        if (sameId && (row.qty < this.quantity)) {
          return Object.assign({}, row, { qty: row.qty + 1 })
        }
        return row
      })
    },
    handleMinusPromoItem(item) {
      this.userPromoItems = this.userPromoItems.map(row => {
        if ((row.uuid === item.uuid) && (row.qty > 1)) {
          return Object.assign({}, row, { qty: row.qty - 1 })
        }
        return row
      })
    },
    handleSubmit() {
      const { id, title, image, promoDetails } = this.item
      this.$emit('submit', {
        id,
        type: 'promo',
        title,
        image,
        qty: 1,
        price: promoDetails.price,
        products: this.userPromoItems
      })
    },
    addItemToUserPromoItems(item) {

      const existedItem = this.userPromoItems.find(row => {
        const sameProduct = (row.id === item.id)
        const sameSpec = (row.specId === item.specId)
        return sameProduct && sameSpec
      })
      if (existedItem) {
        existedItem.qty += item.qty
      }
      else {
        const productIndices = this.item.products.reduce((obj, row, index) => {
          obj[row.id] = index
          return obj
        }, {})
        this.userPromoItems.push(item)
        // sort items by given product order
        this.userPromoItems.sort((a, b) => {
          if (a.id === b.id) {
            return a.specId - b.specId
          }
          return productIndices[a.id] - productIndices[b.id]
        })
      }
      this.clearSelectedProduct()
    }
  }
}
</script>

<style lang="scss" scoped>
.order-item-menu-promo {
  border-radius: 4px;
  overflow: hidden;

  width: 64vw;
  height: 84vh;
  min-width: 820px;
  min-height: 428px;

  position: relative;
  background-color: $white1;
  padding: 16px 21px;
}
$height-checkout-bar: 60px;
.order-item-menu-promo__main-box {
  padding-top: .6rem;
  // height - title
  height: calc(100% - 36px);
}
.order-item-menu-promo__main {
  display: flex;
  justify-content: space-between;
  height: calc(100% - #{$height-checkout-bar});
}
.no-flexbox .order-item-menu-promo__main {
  @include clearfix;
  .order-item-menu-promo__product-list,
  .order-item-menu-promo__promo-list {
    float: left;
  }
}
.order-item-menu-promo__title {
  color: $black3;
  font-size: 1.2em;
  font-weight: 500;
}
.order-item-menu-promo__product-list {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 40%;
  width: 40%;
  overflow-y: auto;
}
.order-item-menu-promo__promo-list {
  flex-grow: 1;
  padding-left: 14px;
}
.user-promo-items__list {
  margin-bottom: 40px;
  height: calc(100% - #{$height-checkout-bar});
  overflow-y: auto;
  padding-bottom: 74px;
}
.order-item-menu-promo__close-btn {
  position: absolute;
  right: 0;
  top: 0;
}
.btn-primary {
  margin-left: 1em;
  width: 290px;
}
.order-item-menu-promo__checkout-bar {
  padding-top: .7rem;
  padding-bottom: .7rem;
  border-bottom-right-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.no-flexbox .order-item-menu-promo__checkout-bar {
  display: block;
  .btn-primary {
    display: inline-block;
    vertical-align: middle;
  }
}
.order-item-menu-promo__tips {
  margin-top: 20px;
  font-size: 1.2em;
}
strong.num {
  color: $red4;
}
.fixed-price-info {
  .fixed-price-info__original-price {
    color: $black5;
  }
}
.order-item-menu-promo__no-products {
  text-align: center;
  padding: 120px 0;
  font-size: 1.2em;
}
</style>
