import url from 'url'

const { parse } = url

// https://github.com/superlanding/landing/issues/1381
export default function setHttpsIfNeeded(href, refUrl) {
  if (! refUrl) {
    return href
  }
  const refData = parse(refUrl)
  const data = parse(href)
  if ((refData.protocol === 'https:') && (refData.host === data.host)) {
    return String(href).replace(/^http:/, 'https:')
  }
  return href
}
