<template>
  <div>
    <pulse-loader />
  </div>
</template>

<script>
import { PulseLoader } from '@saeris/vue-spinners'

export default {
  name: 'ComponentLoading',
  components: {
    PulseLoader
  }
}
</script>
