import getAlias from '@landing/landing-common/utils/getAlias'
import http from '@landing/landing-desktop/plugins/http'
import createEpic from '../createEpic'
import { SET_INVOICE, SET_INVOICE_SUCCESS,
  SET_INVOICE_FAIL } from '../reducers/order'
import {
  catchError,
  exhaustMap,
  from,
  map,
  of,
  takeWhile,
  tap
} from '@landing/landing-desktop/utils'

let isSettingInvoice = false

const setInvoice = (action$, state$) => [
  SET_INVOICE,
  takeWhile(() => ! isSettingInvoice),
  tap(() => isSettingInvoice = true),
  exhaustMap(action => {
    const { orderId, key, data } = action
    return from(http.put(`/${getAlias(state$)}/orders/${orderId}/${key}/invoice.json`, data))
      .pipe(
        map(res => ({
          type: SET_INVOICE_SUCCESS,
          payload: res.data,
        })),
        catchError(err => of({ type: SET_INVOICE_FAIL, payload: err }))
      )
  }),
  tap(() => isSettingInvoice = false)
]

export default [
  createEpic(setInvoice)
]
