import getScrollTop from '@superlanding/getscrolltop'
import px from '@superlanding/topixel'

// 這個 mixin 是為了防止視窗打開時
// 視窗後面的 body 捲軸還可以被捲動

let scrollTop = 0
let openedBy = null

const disableScroll = self => {
  if (openedBy && (openedBy !== self)) {
    return
  }
  const { body } = document
  scrollTop = getScrollTop()
  body.classList.add('modal-open')
  body.style.top = px(-scrollTop)
  openedBy = self
}

const enableScroll = self => {
  if (openedBy && (openedBy !== self)) {
    return
  }
  const { body } = document
  body.classList.remove('modal-open')
  body.style.top = 0
  // desktop chrome
  document.documentElement.scrollTop = scrollTop
  // iphone chrome
  body.scrollTop = scrollTop
  openedBy = null
}

const handleVisibleStatus = (self, visible) => {
  return visible ? disableScroll(self) : enableScroll(self)
}

export default prop => {

  return {
    watch: {
      [prop](value) {
        handleVisibleStatus(this, value)
      }
    },
    mounted() {
      handleVisibleStatus(this, this[prop])
    },
    beforeDestroy() {
      enableScroll(this)
    }
  }
}
