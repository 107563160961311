import Vue from '../consts/vue'
import { get, isServer, isClient, mapDispatch } from '../utils'

Vue.mixin({
  beforeCreate() {
    this.mapDispatch = mapDispatch
    this.isServer = isServer
    this.isClient = isClient
    this.get = get
    if (this.$route) {
      this.query = this.$route.query
    }
  }
})
