import { throttle } from '../utils'
import { setWindowSize } from '../redux/reducers/windowSize'

export default {
  created() {
    if (typeof window === 'undefined') {
      return
    }
    this.mapDispatch({ setWindowSize })
    handleResize.call(this)
    this.on(window, 'resize', throttle(handleResize.bind(this), 50))
  }
}

function handleResize() {
  this.setWindowSize({
    width: window.innerWidth,
    height: window.innerHeight
  })
}
