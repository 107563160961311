import fromUnixTime from 'date-fns/fromUnixTime'

export default function unixTimeToCurrentDate(timestamp) {
  const d = fromUnixTime(timestamp)
  const date = new Date()
  date.setHours(d.getHours())
  date.setMinutes(d.getMinutes())
  date.setSeconds(d.getSeconds())
  return date
}
