<template>
  <div class="order-item-menu-product-list">
    <button v-for="product in items"
            :key="product.id"
            data-cy="promo-modal-item"
            type="button"
            class="order-item-menu-product-list__item"
            @click.stop.prevent="$emit('item-click', product)">

      <template v-if="hasImageCol">
        <app-image v-if="product.image"
                   class="order-item-menu-product-list__item-img"
                   :src="product.image"
                   :alt="product.title" />
        <div v-else class="order-item-menu-product-list__img-placeholder" />
      </template>

      <div class="order-item-menu-product-list__text-content">
        <div class="order-item-menu-product-list__item-title"
             v-text="product.title" />
        <preorder-info v-if="product.isPreorder"
                       class="order-item-menu-product-list__preorder-info"
                       :item="product"
                       mode="grid" />
        <div v-if="productPriceVisible"
             class="order-item-menu-product-list__price">
          售價 <span v-text="dollar(product.price)" />
        </div>
        <div v-if="product.strengthening" class="txt-strengthening" v-text="product.strengthening" />
        <div v-if="product.additional" class="txt-additional" v-text="product.additional" />
      </div>
      <fa-icon class="arrow-icon" icon="chevron-right" />
    </button>
  </div>
</template>

<script>
import AppImage from './AppImage'
import PreorderInfo from '@landing/landing-common/components/PreorderInfo'
import dollar from '@landing/landing-common/utils/dollar'

export default {
  name: 'OrderItemMenuProductList',
  components: {
    AppImage,
    PreorderInfo
  },
  props: {
    items: Array,
    page: Object
  },
  computed: {
    productPriceVisible() {
      const { page } = this
      return page && (page.layout.promoProductPrice === 'visible')
    },
    hasImageCol() {
      return this.items.some(item => item.image)
    }
  },
  methods: {
    dollar
  }
}
</script>

<style lang="scss" scoped>
.arrow-icon {
  margin-top: 3px;
  color: $black7;
}
.order-item-menu-product-list {
  height: 100%;
  overflow-y: auto;
}
.order-item-menu-product-list__item {
  margin-bottom: 7px;
  transition: .2s all;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  width: 100%;
  background-color: transparent;
  border: 0;
  outline: 0;
  // IE11 needs more space to display scrollbar
  padding-right: 21px;
  min-height: 70px;
  &:hover {
    background-color: $gray12;
  }
}
.no-flexbox .order-item-menu-product-list__item {
  @include clearfix;
  text-align: left;
  .order-item-menu-product-list__item-img,
  .order-item-menu-product-list__img-placeholder,
  .order-item-menu-product-list__item-title,
  .arrow-icon {
    display: inline-block;
    vertical-align: middle;
  }
  .arrow-icon {
    float: right;
    margin-top: 27px;
    margin-bottom: 27px;
  }
}
.order-item-menu-product-list__text-content {
  flex-grow: 1;
  padding: 0 12px;
  text-align: left;
}
.order-item-menu-product-list__item-title {
  flex-grow: 1;
  flex-shrink: 1;
  color: #000;
}
.order-item-menu-product-list__price,
.txt-strengthening,
.txt-additional {
  font-size: .8rem;
}
.order-item-menu-product-list__price {
  font-size: 0.9rem;
  color: #555;
  margin-top: .2rem;
  span {
    margin-left: .2em;
  }
}
.txt-strengthening {
  line-height: 1.8;
  margin-top: .1rem;
}
.txt-additional {
  margin-top: .1rem;
  white-space: pre-wrap;
}
.order-item-menu-product-list__item-img {
  margin-top: 4px;
  width: 70px;
  height: auto;
}
.order-item-menu-product-list__img-placeholder {
  @include size(70px);
  flex-shrink: 0;
}
.order-item-menu-product-list__preorder-info {
  margin-top: .3em;
  font-size: 0.9rem;
}
</style>
