<template>
  <popup-menu :is-visible="isVisible"
              @backdrop-click="close"
              @close="close">
    <div class="cart-modal">
      <h2 class="cart-modal__title">購物車</h2>
      <div class="cart-modal__content">
        <cart-modal-error v-if="isCartLoadingFailed" :error="errorLoadingCart" @reload="$emit('reload')" />
        <cart-modal-loading v-else-if="isLoadingCart" @close="close" />
        <cart-modal-empty v-else-if="isCartEmpty"
                          @close="close" />
        <div v-else-if="hasCartItems" class="cart-modal__list">
          <cart-modal-list :items="cartItems"
                           @remove-item="showConfirmBeforeRemoving" />
          <price-list :page="page" :cart="cart" />
          <coupon-form v-if="page.withCoupon" class="cart-modal__coupon-form" />
        </div>
      </div>
      <checkout-bar v-if="cartItems.length > 0"
                    class="cart-modal__checkout-bar"
                    :cart="cart"
                    @checkout="handleCheckout" />
      <close-btn class="cart-modal__close-btn" @click="$emit('close')" />
    </div>
  </popup-menu>
</template>

<script>
import PopupMenu from '@landing/landing-desktop/components/PopupMenu'
import PriceList from '@landing/landing-desktop/components/PriceList'
import CloseBtn from '@landing/landing-desktop/components/CloseBtn'
import CartModalEmpty from '@landing/landing-desktop/components/CartModalEmpty'
import CartModalLoading from '@landing/landing-desktop/components/CartModalLoading'
import CartModalError from '@landing/landing-desktop/components/CartModalError'
import CartModalList from '@landing/landing-desktop/components/CartModalList'
import CheckoutBar from '@landing/landing-desktop/components/CheckoutBar'
import CouponForm from './CouponForm'
import HumanError from '@landing/landing-common/models/HumanError'
import { hideCartModal } from '@landing/landing-desktop/redux/reducers/app'
import { removeOrderProduct, addOrderProduct } from '@landing/landing-desktop/redux/reducers/cart'
import { addToastItem } from '@landing/landing-desktop/redux/reducers/toast'
import { showConfirm } from '@landing/landing-desktop/redux/reducers/confirm'

export default {
  name: 'CartModal',
  components: {
    CloseBtn,
    CouponForm,
    CartModalLoading,
    CartModalEmpty,
    CartModalError,
    CartModalList,
    CheckoutBar,
    PopupMenu,
    PriceList
  },
  props: {
    isVisible: Boolean
  },
  data() {
    return {
      couponErrorMessage: '',
      ...this.mapState({
        site: 'page.page.site',
        page: 'page.page',
        isCartLoaded: 'cart.isCartLoaded',
        isLoadingCart: 'cart.isLoadingCart',
        isCartLoadingFailed: 'cart.isCartLoadingFailed',
        errorLoadingCart: 'cart.errorLoadingCart',
        cart: 'cart.cart',
        cartItems: 'cart.cart.items'
      })
    }
  },
  computed: {
    isCartEmpty() {
      return this.isCartLoaded && (this.cartItems.length === 0)
    },
    hasCartItems() {
      return this.isCartLoaded && (this.cartItems.length > 0)
    }
  },
  beforeCreate() {
    this.mapDispatch({
      addOrderProduct,
      addToastItem,
      hideCartModal,
      removeOrderProduct,
      showConfirm
    })
  },
  methods: {
    getRemoveSuccessMessage(item) {
      if (item.type === 'product') {
        return `已刪除 ${item.qty} ${item.unitName}${item.title}`
      }
      return `已刪除${item.title}`
    },
    handleCheckout() {
      const params = { aliasName: this.cart.page.aliasName }
      const { query, cart } = this
      const trackingData = {
        cartId: cart.id,
        cart,
        totalOrderAmount: cart.totalOrderAmountWithDiscount,
        title: this.page.title
      }
      if (this.page.layout.checkout === 'multi_step') {
        query.step = 1
        trackingData.step = 1
      }
      this.$tracker.checkout(trackingData)
      this.hideCartModal()
      this.$router.push({ name: 'CheckoutPage', params, query })
    },
    close() {
      this.$emit('close')
    },
    removeItem(item) {
      if (item.isLoading) {
        return
      }
      item.isLoading = true
      this.removeOrderProduct(item.id)
        .then(() => this.addToastItem({ message: this.getRemoveSuccessMessage(item) }))
        .catch(err => {
          const message = HumanError.getMessage(err)
          if (message) {
            return this.addToastItem({ message })
          }
          this.addToastItem({ message: `${item.title}刪除失敗` })
        })
        .finally(() => {
          item.isLoading = false
        })
    },
    showConfirmBeforeRemoving(item) {
      this.showConfirm({
        message: `你確定要刪除${item.title}嗎?`,
        confirm: () => this.removeItem(item)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-modal {
  position: relative;
  overflow: hidden;
  width: 912px;
  max-width: 94vw;
  background-color: $white1;
  padding-bottom: 80px;
}
.cart-modal__title {
  color: $black12;
  font-weight: 400;
  padding: 23px;
  font-size: 1.5em;
  margin: 0;
}
.cart-modal__content {
  overflow: auto;
  max-height: 70vh;
}
.vertical-slide-enter-active {
  transition-duration: .16s
}
.vertical-slide-leave-active {
  transition-duration: .16s
}
.cart-modal__list {
  margin-left: 32px;
  margin-right: 32px;
  padding-bottom: 130px;
}
.cart-modal__close-btn {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
