// 聯盟網 - 程式串接說明文件
// https://drive.google.com/file/d/1KePLLEeSSC6XleKGwmEkXC2aOW2iW6vq/view

// 聯盟網 - 測試用廠商後台截圖
// https://github.com/superlanding/landing/issues/1222#issuecomment-712700669

// 驗證聯盟網是否有串接成功的方式 (綜合聯盟網 Lily 與工程師的說法)
// 點擊紀錄在 https://partner.affiliates.one/advertisers/affiliate_stats/clicks
// 交易記錄在 https://partner.affiliates.one/advertisers/affiliate_stats/captured
// 瀏覽紀錄無法得知，如果有疑問的話對方只會檢查你的頁面是否有埋瀏覽碼

// 交易紀錄要成功建立必須搭配瀏覽紀錄 ( pageView )
// 例如:
// 1. 使用者點擊 https://track.afftck.com/track/clicks/5844/d024c2b68f5076d2f88afa73967cd948206749cd?subid_1=test
// 2. 導向 https://staging.super-landing.com/affiliates/?utm_medium=cpa&utm_source=affiliates&utm_term=d92f402bd4161f76dc54a933fd3208fa&vtm_channel=affiliatescomtw&vtm_stat_id=d92f402bd4161f76dc54a933fd3208fa&vtmz=true
// 3. 呼叫 pageView
// 4. 呼叫 purchase

import TrackerBase from './TrackerBase'
import { get } from '../utils'

const affiliatesScriptId = 'affiliates-script'

class TrackerAffiliates extends TrackerBase {

  constructor({ page }) {
    super()
    this.page = page
  }

  init() {
    const affiliatesSiteId = get(this, 'page.affiliates.siteId')
    if (! affiliatesSiteId) {
      return this
    }
    this.affiliatesSiteId = affiliatesSiteId
    this.enabled = true
    return this
  }

  pageView(options) {
    // https://github.com/superlanding/affiliates/issues/1
    // 訂單頁不要 trace，避免 purchase 資料送錯
    if (options.isOrderPage) {
      return
    }
    this._loadScript({
      whiteLabel: {
        id: 8,
        siteId: this.affiliatesSiteId,
        domain: 't.adotone.com'
      },
      locale: 'en-US',
      mkt: true
    })
  }

  viewContent() {
  }

  addToCart() {
  }

  checkout() {
  }

  purchase({ orderId, totalOrderAmount }) {
    this._loadScript({
      whiteLabel: {
        id: 8,
        siteId: this.affiliatesSiteId,
        domain: 't.adotone.com'
      },
      conversion: true,
      conversionData: {
        step: 'sale',
        // revenue: '置入 CPA 金額 / 分潤金額',
        orderTotal: totalOrderAmount,
        order: orderId
      },
      locale: 'en-US',
      mkt: true
    })
  }

  _removePrevScriptTag() {
    const script = document.getElementById(affiliatesScriptId)
    if (script) {
      document.head.removeChild(script)
    }
  }

  _loadScript(options) {

    this._removePrevScriptTag()

    window.VARemoteLoadOptions = options

    const script = document.createElement('script')
    script.setAttribute('async', '')
    script.src = 'https://cdn.adotone.com/javascripts/va.js'
    script.id = affiliatesScriptId

    const [firstScript] = document.head.getElementsByTagName('script')
    document.head.insertBefore(script, firstScript || null)
  }
}

export default TrackerAffiliates
