<template>
  <popup-menu v-if="item"
              :is-visible="isVisible"
              @backdrop-click="close"
              @close="close">
    <!-- DO NOT REMOVE ref menu -->
    <!-- It is created for container SalesPage to manipulate animation -->
    <order-item-menu-product v-if="isProduct"
                             ref="menu"
                             :item="item"
                             @submit="handleSubmit"
                             @close="close" />

    <order-item-menu-promo v-if="isPromo"
                           ref="menu"
                           :item="item"
                           :cart="cart"
                           :page="page"
                           @submit="handleSubmit"
                           @close="close" />
  </popup-menu>
</template>

<script>
import isValidDiscountCount from '@common/utils/isValidDiscountCount'
import PopupMenu from '@landing/landing-desktop/components/PopupMenu'
import OrderItemMenuProduct from '@landing/landing-desktop/components/OrderItemMenuProduct'
import OrderItemMenuPromo from '@landing/landing-desktop/components/OrderItemMenuPromo'
import Promo from '@landing/landing-common/models/Promo'
import Product from '@landing/landing-common/models/Product'
import { showAlert } from './../redux/reducers/alert'
import { getApStr } from '@landing/landing-desktop/utils'

export default {
  name: 'OrderItemMenu',
  components: {
    OrderItemMenuProduct,
    OrderItemMenuPromo,
    PopupMenu
  },
  props: {
    isVisible: Boolean,
    item: Object
  },
  data() {
    return this.mapState({
      page: 'page.page',
      cart: 'cart.cart',
      currentPromoProduct: 'app.currentPromoProduct'
    })
  },
  computed: {
    isPromo() {
      return this.item instanceof Promo
    },
    isProduct() {
      return this.item instanceof Product
    },
    isBackBtnVisible() {
      return Boolean(this.currentPromoProduct)
    }
  },
  beforeCreate() {
    this.mapDispatch({ showAlert })
  },
  methods: {
    validatePromo(data) {
      const { promoType } = this.item
      const { qty, interval, rate } = this.item.promoDetails
      const productCount = data.products.reduce((count, row) => count + row.qty, 0)

      if (productCount === 0) {
        return {
          message: '請先選擇組合商品',
          btnText: '好'
        }
      }
      if (productCount < qty) {
        return {
          message: `請再選擇 ${qty - productCount} 個商品`,
          btnText: '好'
        }
      }
      if ((promoType === 'discounts') && (! isValidDiscountCount(productCount, qty, interval))) {
        const apStr = getApStr({ qty, interval })
        if (rate >= 1) {
          return {
            message: `請把商品數量調整為 ${apStr} ...個`,
            btnText: '好'
          }
        }
        if (interval === 0) {
          return {
            message: `請把商品數量調整為 ${qty} 個才有折扣優惠`,
            btnText: '好'
          }
        }
        return {
          message: `請把商品數量調整為 ${apStr} ...個才有折扣優惠`,
          btnText: '好'
        }
      }
      if ((promoType !== 'discounts') && (productCount > qty)) {
        return {
          message: `選太多了啦! 請把商品數量調整為 ${qty} 個`,
          btnText: '好'
        }
      }
    },
    handleSubmit(data) {
      let error
      if (this.isPromo) {
        error = this.validatePromo(data)
      }
      if (error) {
        return this.showAlert(error)
      }
      this.$emit('submit', data)
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
