<template>
  <button class="btn-icon remove-btn" @click.stop.prevent="$emit('click')">
    <fa-icon icon="trash-alt" />
  </button>
</template>

<script>
export default {
  name: 'RemoveBtn'
}
</script>

<style lang="scss" scoped>
.remove-btn {
  display: block;
  color: $black7;
  @include size(40px);
  padding: 0;
}
</style>
