<template>
  <div class="ig-post-element" data-cy="ig-post-elem" :class="getAlignClassName(align)" v-html="html" />
</template>

<script>
import alignMixin from '@landing/common/mixins/alignMixin'
import elementMixin from '@landing/common/mixins/elementMixin'

export default {
  name: 'IgPostElement',
  mixins: [alignMixin, elementMixin],
  props: {
    data: Object
  },
  computedProps: [
    'align',
    'html'
  ]
}
</script>

<style lang="scss" scoped>
.ig-post-element {
  padding: 20px;
}
.align-left {
  display: flex;
  justify-content: flex-start;
}
.align-center {
  display: flex;
  justify-content: center;
}
.align-right {
  display: flex;
  justify-content: flex-end;
}
</style>
