export const PRODUCT_LAYOUT_LIST = 'list'
export const PRODUCT_LAYOUT_GRID = 'grid'

export const PRODUCT_LAYOUT_ITEMS = [
  { text: '清單', value: PRODUCT_LAYOUT_LIST, hint: "產品較少時使用（ 產品版面較大 ）" },
  { text: '格狀', value: PRODUCT_LAYOUT_GRID, hint: "產品較多時使用（ 產品版面較小 ）" }
]

export const FORM_LAYOUT_STANDARD = 'standard'
export const FORM_LAYOUT_MULTI_STEP = 'multi_step'

export const FORM_LAYOUT_ITEMS = [
  { text: '一般', value: FORM_LAYOUT_STANDARD, hint: 'Google 表單模式' },
  { text: '多步驟', value: FORM_LAYOUT_MULTI_STEP, hint: '購物流程優化' },
]

export const PRODUCT_PRIORITY_PRODUCT_FIRST = 'product_first'
export const PRODUCT_PRIORITY_PROMO_FIRST = 'promo_first'

export const PRODUCT_PRIORITY_ITEMS = [
  { text: '任選優惠在上', value: PRODUCT_PRIORITY_PROMO_FIRST, hint: "任選優惠會顯示在產品的上方" },
  { text: '產品在上', value: PRODUCT_PRIORITY_PRODUCT_FIRST, hint: "產品會顯示在任選優惠的上方" }
]

export const PAGE_COL_RECEIVE_TIME_NONE = 'none'
export const PAGE_COL_RECEIVE_TIME_OPTIONAL = 'optional'
export const PAGE_COL_RECEIVE_TIME_ITEMS = [
  { text: '不顯示', value: PAGE_COL_RECEIVE_TIME_NONE },
  { text: '選填', value: PAGE_COL_RECEIVE_TIME_OPTIONAL }
]

export const PAGE_COL_RECEIVE_DATE_NONE = 'none'

export const PAGE_COL_RECEIVE_DATE_MUST = 'must'

export const PAGE_COL_RECEIVE_DATE_ITEMS = [
  { text: '不顯示', value: PAGE_COL_RECEIVE_DATE_NONE },
  { text: '必填', value: PAGE_COL_RECEIVE_DATE_MUST }
]

export const PAGE_COL_TEL_NONE = 'none'

export const PAGE_COL_TEL_INTL = 'intl'

export const PAGE_COL_TEL_ITEMS = [
  { text: '預設', value: PAGE_COL_TEL_NONE, hint: '文字框輸入' },
  { text: '使用國碼', value: PAGE_COL_TEL_INTL, hint: '文字框前面會有國碼選項供使用者選擇' }
]

export const PAGE_COL_EMAIL_NONE = 'none'

export const PAGE_COL_EMAIL_MUST = 'must'

export const PAGE_COL_EMAIL_OPTIONAL = 'optional'

export const PAGE_COL_EMAIL_ITEMS = [
  { text: '不顯示', value: PAGE_COL_EMAIL_NONE },
  { text: '必填', value: PAGE_COL_EMAIL_MUST },
  { text: '選填', value: PAGE_COL_EMAIL_OPTIONAL }
]

export const PAGE_COL_LINE_NONE = 'none'

export const PAGE_COL_LINE_OPTIONAL = 'optional'

export const PAGE_COL_LINE_ITEMS = [
  { text: '不顯示', value: PAGE_COL_LINE_NONE },
  { text: '選填', value: PAGE_COL_LINE_OPTIONAL }
]

export const PAGE_COL_BIRTH_NONE = 'none'

export const PAGE_COL_BIRTH_MUST = 'must'

export const PAGE_COL_BIRTH_OPTIONAL = 'optional'

export const PAGE_COL_BIRTH_ITEMS = [
  { text: '不顯示', value: PAGE_COL_BIRTH_NONE },
  { text: '必填', value: PAGE_COL_BIRTH_MUST },
  { text: '選填', value: PAGE_COL_BIRTH_OPTIONAL }
]

export const LAYOUT_DISCOUNT_CHINESE = 'chinese'

export const LAYOUT_DISCOUNT_ENGLISH = 'english'

export const LAYOUT_DISCOUNT_ITEMS = [
  { text: '折扣', value: LAYOUT_DISCOUNT_CHINESE, hint: '例: 92 折' },
  { text: 'Discount', value: LAYOUT_DISCOUNT_ENGLISH, hint: '例: 8% off' }
]

export const LAYOUT_PROMO_PRODUCT_PRICE_HIDDEN = 'hidden'

export const LAYOUT_PROMO_PRODUCT_PRICE_VISIBLE = 'visible'

export const LAYOUT_PROMO_PRODUCT_PRICE_ITEMS = [
  { text: '隱藏', value: LAYOUT_PROMO_PRODUCT_PRICE_HIDDEN },
  { text: '顯示', value: LAYOUT_PROMO_PRODUCT_PRICE_VISIBLE }
]
