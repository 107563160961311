import {
  CLASS_NAME_ADDRESS,
  CLASS_NAME_BLOG_HEADING,
  CLASS_NAME_FB_PAGE,
  CLASS_NAME_IG_POST,
  CLASS_NAME_IMAGE,
  CLASS_NAME_IMAGE_SLIDER,
  CLASS_NAME_LIST,
  CLASS_NAME_PARAGRAPH,
  CLASS_NAME_QUOTE,
  CLASS_NAME_TITLE,
  CLASS_NAME_YOUTUBE,
  CLASS_NAME_BTN
} from '@landing/common/consts/component'

export default {
  methods: {
    isBlogHeading(component) {
      return component.className === CLASS_NAME_BLOG_HEADING
    },
    isTitle(component) {
      return component.className === CLASS_NAME_TITLE
    },
    isImage(component) {
      return component.className === CLASS_NAME_IMAGE
    },
    isImageSlider(component) {
      return component.className === CLASS_NAME_IMAGE_SLIDER
    },
    isYoutube(component) {
      return component.className === CLASS_NAME_YOUTUBE
    },
    isParagraph(component) {
      return component.className === CLASS_NAME_PARAGRAPH
    },
    isQuote(component) {
      return component.className === CLASS_NAME_QUOTE
    },
    isList(component) {
      return component.className === CLASS_NAME_LIST
    },
    isIgPost(component) {
      return component.className === CLASS_NAME_IG_POST
    },
    isAddress(component) {
      return component.className === CLASS_NAME_ADDRESS
    },
    isFbPage(component) {
      return component.className === CLASS_NAME_FB_PAGE
    },
    isBtn(component) {
      return component.className === CLASS_NAME_BTN
    }
  }
}
