<template>
  <div class="editor-content" data-cy="editor-content">
    <template v-for="(row, i) in page.content.elems">
      <address-element v-if="isAddress(row)" :key="row.id" :data="row" />
      <blog-heading-element v-if="isBlogHeading(row)" :key="row.id" :data="row" />
      <btn-element v-if="isBtn(row)" :key="row.id" :data="row" />
      <fb-page-element v-if="isFbPage(row)" :key="row.id" :data="row" />
      <heading-element v-if="isTitle(row)" :key="row.id" :data="row" />
      <ig-post-element v-if="isIgPost(row)" :key="row.id" :data="row" />
      <image-element v-if="isImage(row)"
                     :ref="componentRef(row)"
                     :key="row.id"
                     :data="row"
                     :lazy-thumb="i > 5"
                     @click="handleImageClick"
                     @load="handleImageLoad"
                     @error="handleImageError" />
      <image-slider-element v-if="isImageSlider(row)" :key="row.id" :data="row" />
      <list-element v-if="isList(row)" :key="row.id" :data="row" />
      <paragraph-element v-if="isParagraph(row)" :key="row.id" :data="row" />
      <quote-element v-if="isQuote(row)" :key="row.id" :data="row" />
      <youtube-element v-if="isYoutube(row)" :key="row.id" :data="row" />
    </template>
  </div>
</template>

<script>
import AddressElement from '@common/components/AddressElement'
import BlogHeadingElement from '@common/components/BlogHeadingElement'
import BtnElement from '@common/components/BtnElement'
import FbPageElement from '@common/components/FbPageElement'
import HeadingElement from '@common/components/HeadingElement'
import IgPostElement from '@common/components/IgPostElement'
import ImageElement from '@common/components/ImageElement'
import ImageSliderElement from '@common/components/ImageSliderElement'
import ListElement from '@common/components/ListElement'
import ParagraphElement from '@common/components/ParagraphElement'
import QuoteElement from '@common/components/QuoteElement'
import YoutubeElement from '@common/components/YoutubeElement'

import componentTypeMixin from '@common/mixins/componentTypeMixin'

import {
  CLASS_NAME_TITLE,
  CLASS_NAME_IMAGE,
  CLASS_NAME_YOUTUBE,
  TYPE_IMAGE_LINK_SHOPPING_CART
} from '@common/consts/component'

export default {
  name: 'EditorContent',
  components: {
    AddressElement,
    BlogHeadingElement,
    BtnElement,
    FbPageElement,
    HeadingElement,
    IgPostElement,
    ImageElement,
    ImageSliderElement,
    ListElement,
    ParagraphElement,
    QuoteElement,
    YoutubeElement
  },
  mixins: [componentTypeMixin],
  props: {
    page: Object
  },
  data() {
    return this.mapState({
      windowWidth: 'windowSize.width'
    })
  },
  computed: {
    hasSiteNav() {
      const { site } = this.page
      return site && site.navbar.enabled
    }
  },
  watch: {
    windowWidth() {
      this.setImageWidth()
    }
  },
  created() {
    this.setImageSrcs()
  },
  mounted() {
    this.lazyLoadImage()
  },
  methods: {
    componentRef(c) {
      return `component-${c.id}`
    },
    handleImageClick({ event, type }) {
      if (type === TYPE_IMAGE_LINK_SHOPPING_CART) {
        this.$emit('scroll-to-product-list')
        event.preventDefault()
        event.stopPropagation()
      }
    },
    handleImageError(item) {
      this.handleImageLoad(item)
    },
    handleImageLoad(item) {
      this.srcs = this.srcs.filter(src => item.data.src !== src)
    },
    isImage(row) {
      return row.className === CLASS_NAME_IMAGE
    },
    isTitle(row) {
      return row.className === CLASS_NAME_TITLE
    },
    isYoutube(row) {
      return row.className === CLASS_NAME_YOUTUBE
    },
    lazyLoadImage() {
      requestIdleCallback(() => {
        const [firstSrc] = this.srcs
        if (firstSrc) {
          const image = new Image()
          const handleImageLoad = () => {
            this.srcs = this.srcs.filter(src => src !== firstSrc)
            if (this.srcs.length > 0) {
              this.lazyLoadImage()
            }
          }
          image.onload = handleImageLoad
          image.onerror = handleImageLoad
          image.src = firstSrc
        }
      })
    },
    setImageSrcs() {
      this.srcs = this.page.content.elems.filter(row => row.className === CLASS_NAME_IMAGE)
        .map(row => row.data.src)
    },
    setImageWidth() {
      this.page.content.elems.filter(c => this.isImage(c))
        .forEach(c => {
          const [imageComponent] = this.$refs[this.componentRef(c)] || []
          if (imageComponent) {
            imageComponent.setContentWidth()
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.editor-content {
  overflow: hidden;
}
</style>
