<template>
  <div class="quote-type2">
    <div class="quote-type2__quote">
      <slot />
    </div>
    <div v-if="this.$slots.author" class="quote-type2__author">
      <slot name="author" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuoteType2'
}
</script>

<style lang="scss" scoped>
.quote-type2__quote {
  padding: 14px 20px;
  font-size: 24px;
  color: #555;
  border-left: 4px solid #2c2c2c;
}
.quote-type2__author {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  margin-top: 30px;
  text-align: right;
  &:before {
    content: '────';
    margin-right: 10px;
  }
}
</style>
