import Vue from '@landing-desktop/consts/vue'

export default function setConst(page) {
  if (! page) {
    return
  }
  const { $consts } = Vue.prototype
  const { layout } = page
  $consts.currency = page.currency

  if (layout) {
    $consts.layoutDiscount = layout.discount
  }
}
