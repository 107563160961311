<template>
  <svg width="48px" height="48px" viewBox="0 0 48 48">
    <title>Facebook Messenger</title>
    <desc>Created with Sketch.</desc>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M48,24 C48,10.7451563 37.2548437,0 24,0 C10.7451563,0 0,10.7451563 0,24 C0,35.9789063 8.77640625,45.9079688 20.25,47.7084375 L20.25,30.9375 L14.15625,30.9375 L14.15625,24 L20.25,24 L20.25,18.7125 C20.25,12.6975 23.833125,9.375 29.3151563,9.375 C31.9401563,9.375 34.6875,9.84375 34.6875,9.84375 L34.6875,15.75 L31.66125,15.75 C28.68,15.75 27.75,17.6001563 27.75,19.5 L27.75,24 L34.40625,24 L33.3421875,30.9375 L27.75,30.9375 L27.75,47.7084375 C39.2235937,45.9079688 48,35.9789063 48,24 Z" fill-rule="nonzero" />
      <path d="M33.3421875,30.9375 L34.40625,24 L27.75,24 L27.75,19.5 C27.75,17.6020312 28.68,15.75 31.66125,15.75 L34.6875,15.75 L34.6875,9.84375 C34.6875,9.84375 31.9410938,9.375 29.3151563,9.375 C23.833125,9.375 20.25,12.6975 20.25,18.7125 L20.25,24 L14.15625,24 L14.15625,30.9375 L20.25,30.9375 L20.25,47.7084375 C22.7348874,48.0971871 25.2651126,48.0971871 27.75,47.7084375 L27.75,30.9375 L33.3421875,30.9375 Z" fill="#FFFFFF" fill-rule="nonzero" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'FbIconSvg'
}
</script>
