<template>
  <img v-if="! withSrc"
       class="app-image"
       src="../assets/images/image-placeholder.svg"
       alt="placeholder image">
  <img v-else-if="hasError"
       class="app-image"
       src="../assets/images/image-error.svg"
       alt="error image"
       @load="$emit('error')">
  <img v-else-if="lazy"
       v-observe-visibility="{ callback: handleVisibilityChange, once: true }"
       v-bind="attrs"
       :alt="alt"
       @load="$emit('load')" @error="hasError = true">
  <img v-else
       :src="src"
       :alt="alt"
       @load="$emit('load')" @error="hasError = true">
</template>

<script>
export default {
  name: 'AppImage',
  props: {
    src: String,
    alt: String,
    lazy: Boolean
  },
  data() {
    return {
      hasError: false,
      attrs: {}
    }
  },
  computed: {
    withSrc() {
      return ((this.src || '').length > 0)
    }
  },
  watch: {
    src() {
      this.hasError = false
    }
  },
  methods: {
    loadImage() {
      this.attrs = { src: this.src }
    },
    handleVisibilityChange(isVisible) {
      if (isVisible) {
        this.loadImage()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-image {
  background-color: $gray12;
  color: $gray1;
  @include flex-center;
}
</style>
