function fallbackCopyText(text) {

  return new Promise((resolve, reject) => {

    const textArea = document.createElement('textarea')
    textArea.value = text

    // avoid scrolling to bottom
    textArea.style.position = 'fixed'
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    let res

    try {
      res = document.execCommand('copy');
      if (res) {
        resolve('Fallback: Copying text command was successful')
      }
      else {
        reject('Fallback: Copying text command was failed')
      }
    }
    catch (err) {
      reject(`Fallback: Oops, unable to copy ${err}`)
    }
    document.body.removeChild(textArea)
  })
}

export default function copyText(text) {
  if (! navigator.clipboard) {
    return fallbackCopyText(text)
  }
  return navigator.clipboard.writeText(text)
}
