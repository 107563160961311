import IntlAddressBase from './Base'

class MalaysiaAddress extends IntlAddressBase {

  constructor(props) {
    super(props)
  }
}

export default MalaysiaAddress
