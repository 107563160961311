import extend from '@superlanding/extend'

export default function setQuery(q) {
  const query = extend({}, this.$route.query, q)

  Object.keys(query)
    .forEach(prop => {
      const value = query[prop]
      if (value === null) {
        delete query[prop]
      }
    })

  this.$router.replace({ query })
}
