<template>
  <div class="sales-page" :style="containerStyle">
    <app-nav :page="page"
             :shrank="shrank"
             :mounted="setNavHeight"
             :updated="setNavHeight"
             :site-nav-visible="hasSiteNav"
             :navbar-visible="hasNavbar" />
    <editor-content class="sales-page__editor-content"
                    :page="page"
                    @scroll-to-product-list="scrollToProductList" />

    <div v-if="page.discountLevels.length > 0"
         class="sales-page__discount-lv-list"
         :class="`sales-page__discount-lv-list--${page.layout.products}-mode`">
      <discount-lv-list ref="discountLvList"
                        :items="page.discountLevels"
                        :layout-discount="$consts.layoutDiscount"
                        :total-order-amount-with-coupon="cart.totalOrderAmountWithCoupon" />
    </div>

    <product-list ref="productList"
                  class="sales-page__product-list"
                  :class="`sales-page__product-list--${page.layout.products}-mode`"
                  :products="page.products"
                  :addtional-products="page.addtionalProducts"
                  :promos="page.promos"
                  :priority="page.layout.priority"
                  :mode="page.layout.products"
                  :product-banner="page.productBanner.desktop"
                  :addtional-product-banner="page.addtionalProductBanner.desktop"
                  @send-to-cart="handleSendToCart" />
    <fixed-toolbar :page="page"
                   :count-in-cart="cartItems.length"
                   :is-loading-cart="isLoadingCart"
                   :tooltip-message="tooltipMessage"
                   :use-flying-animation="useFlyingAnimation"
                   @click-cart="handleCartClick"
                   @cart-btn-pos="setCartBtnPos" />
    <app-footer class="sales-page__app-footer" :page="page" />
    <cart-modal :is-visible="isCartModalVisible"
                @reload="reloadCart"
                @close="hideCartModal" />
    <order-item-menu ref="orderItemMenu"
                     :is-visible="isOrderItemMenuVisible"
                     :item="selectedOrderItem"
                     @submit="addItemToCart"
                     @close="hideOrderItemMenu" />
    <div v-if="applicableDiscountItem"
         class="sales-page__discount-lv-title"
         :class="{ 'sales-page__discount-lv-title--visible': discountHintVisible }"
         :style="discountItemStyle">
      <discount-lv-title :item="applicableDiscountItem"
                         :layout-discount="$consts.layoutDiscount"
                         :short-by="applicableDiscountItem.amount - cart.totalOrderAmountWithCoupon" />
    </div>
    <portal-target name="promo-product" />
    <free-product-set-menu :is-visible="isFreeProductSetMenuVisible"
                           :item="freeProductSet"
                           @submit="handleFreeProductSetMenuSubmit" />
    <app-alert :is-visible="alert.isVisible"
               :data="alert.data"
               @close="hideAlert" />
    <app-confirm :is-visible="confirm.isVisible"
                 :data="confirm.data"
                 @close="hideConfirm" />
    <app-toast :items="toast.items" @remove-toast-item="removeToastItem" />
    <center-toast :text="toast.centerToastText" />
  </div>
</template>

<script>
import CartModal from '@landing/landing-desktop/containers/CartModal'
import OrderItemMenu from '@landing/landing-desktop/containers/OrderItemMenu'
import ProductList from '@landing/landing-desktop/containers/ProductList'
import AppAlert from '@landing/landing-desktop/components/AppAlert'
import AppConfirm from '@landing/landing-desktop/components/AppConfirm'
import AppFooter from '@landing/landing-desktop/components/AppFooter'
import AppNav from '@landing/landing-desktop/components/AppNav'
import AppToast from '@landing/landing-desktop/components/AppToast'
import CenterToast from '@landing/landing-desktop/components/CenterToast'
import DiscountLvList from '@landing-common/components/DiscountLvList'
import DiscountLvTitle from '@common/components/DiscountLvTitle'
import EditorContent from '@landing/landing-desktop/components/EditorContent'
import FixedToolbar from '@landing/landing-desktop/components/FixedToolbar'
import FreeProductSetMenu from '@landing/landing-desktop/components/FreeProductSetMenu'
import alertMixin from '@landing/landing-desktop/mixins/alertMixin'
import appNavMixin from '@landing/landing-desktop/mixins/appNavMixin'
import cartMixin from '@landing/landing-desktop/mixins/cartMixin'
import confirmMixin from '@landing/landing-desktop/mixins/confirmMixin'
import domMixin from '@landing/common/mixins/domMixin'
import discountHintMixin from '@landing-desktop/mixins/discountHintMixin'
import productListMixin from '@landing/landing-desktop/mixins/productListMixin'
import toastMixin from '@landing/landing-desktop/mixins/toastMixin'
import windowSizeMixin from '@landing/landing-desktop/mixins/windowSizeMixin'
import { setTrackerLoaded } from '@landing/landing-desktop/redux/reducers/app'

export default {
  name: 'SalesPage',
  components: {
    AppAlert,
    AppConfirm,
    AppFooter,
    AppNav,
    AppToast,
    CartModal,
    CenterToast,
    DiscountLvList,
    DiscountLvTitle,
    EditorContent,
    FixedToolbar,
    FreeProductSetMenu,
    OrderItemMenu,
    ProductList
  },
  mixins: [
    domMixin,
    alertMixin,
    appNavMixin,
    cartMixin,
    confirmMixin,
    discountHintMixin,
    productListMixin,
    toastMixin,
    windowSizeMixin
  ],
  inheritAttrs: false,
  data() {
    return this.mapState({
      cart: 'cart.cart',
      page: 'page.page'
    })
  },
  beforeCreate() {
    this.mapDispatch({ setTrackerLoaded })
    if (this.isClient) {
      this.$tracker.enable()
      this.cartDataReady = () => {
        this.$tracker.setCart(this.cart)
        this.setTrackerLoaded(true)
        this.$tracker.pageView({ step: 1 })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sales-page__editor-content,
.sales-page__product-list,
.sales-page__discount-lv-list,
.sales-page__app-footer {
  @include desktop-width;
  margin: 0 auto;
}
.sales-page__product-list.sales-page__product-list--list-mode,
.sales-page__discount-lv-list.sales-page__discount-lv-list--list-mode {
  width: $width-xs;
  @media (min-width: $screen-sm) {
    width: $width-sm;
  }
  @media (min-width: $screen-md) {
    width: $width-md;
  }
  @media (min-width: $screen-lg) {
    width: 750px;
  }
}
.sales-page__discount-lv-list {
  margin-top: 1.5rem;
}
.sales-page__discount-lv-title {
  transition: .3s transform;
  transform: translateX(-100%);
  max-width: 350px;
  line-height: 2.2;
  padding: .5rem;
  font-size: .75rem;
  background: #f4f4f4;
  position: fixed;
  left: 0;
  &.sales-page__discount-lv-title--visible {
    transform: translateX(0);
  }
}
</style>
