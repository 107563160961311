<template>
  <div class="dot-bar">
    <div v-for="i in range(count)"
         :key="i"
         class="dot-bar__dot"
         :class="dotClass(i)"
         @click.stop.prevent="$emit('click', i)" />
  </div>
</template>

<script>
import { range } from '@common/utils'

export default {
  name: 'DotBar',
  props: {
    current: {
      type: Number,
      required: true
    },
    count: {
      type: Number,
      required: true
    }
  },
  methods: {
    range,
    dotClass(i) {
      return (this.current === i) ? 'dot-bar__dot--active' : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.dot-bar {
  display: flex;
  width: fit-content;
}
.dot-bar__dot {
  @include size(14px);
  cursor: pointer;
  transition: .3s opacity, .3s transform;
  background: #fff;
  border-radius: 100%;
  opacity: .3;
  margin-left: .3rem;
  margin-right: .3rem;
  &:hover {
    opacity: .8;
    transform: scale(1.4);
  }
  &.dot-bar__dot--active {
    opacity: .8;
  }
}
</style>
