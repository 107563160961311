class TrackerBase {

  constructor() {
    this.enabled = false
  }

  init() {
    return this
  }

  pageView() {
  }

  viewContent() {
  }

  addToCart() {
  }

  checkout() {
  }

  purchase() {
  }
}

export default TrackerBase
