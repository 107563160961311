import Vue from './consts/vue'
import SalesPage from './pages/SalesPage'
import getSiteAliases from '../landing-common/utils/getSiteAliases'
import { Router, get } from './utils'

Vue.use(Router)

export function createRouter(props = {}) {

  const site = get(props, 'page.site') || get(props, 'site')
  const aliasName = get(props, 'page.aliasName')
  let siteAliases = getSiteAliases(site)
  let defaultPath = aliasName ? `/${aliasName}` : null

  if ((! defaultPath) && (siteAliases.length > 0)) {
    defaultPath = siteAliases[0]
    siteAliases = siteAliases.filter(name => name !== defaultPath)
  }
  else if (defaultPath) {
    siteAliases = siteAliases.filter(name => name !== defaultPath)
  }

  let checkPage = (to, next) => {
    if (! props.page) {
      // https://github.com/vuejs/vue-router/issues/724#issuecomment-349966378
      return next({ name: 'NotFoundPage', params: { '0': to.path } })
    }
    next()
    // only check props.page once
    checkPage = (to, next) => next()
  }
  const beforeEnter = (to, from, next) => checkPage(to, next)

  const routes = [
    {
      name: 'CheckoutPage',
      path: '/:aliasName/checkout',
      component: () => import('./pages/CheckoutPage'),
      props,
      beforeEnter
    },
    {
      name: 'OrderPage',
      path: '/:aliasName/orders/:orderId/:accessKey',
      component: () => import('./pages/OrderPage'),
      props,
      beforeEnter
    },
    {
      name: 'StaticPage',
      path: '/contents/:pageId',
      component: () => import('./pages/StaticPage'),
      props,
      beforeEnter
    },
    {
      name: 'EntryPage',
      path: '/entry',
      component: () => import('./pages/EntryPage'),
      props,
      beforeEnter
    }
  ]
  if (defaultPath && props.page) {

    const extraProps = site ? { alias: ['/'].concat(siteAliases) } : {}
    routes.push({
      name: 'SalesPage',
      path: defaultPath,
      component: SalesPage,
      props,
      ...extraProps
    })
    routes.push({
      name: 'BuyPage',
      path: `${defaultPath}/buy`,
      component: () => import('./pages/BuyPage'),
      props
    })
  }
  routes.push({
    name: 'NotFoundPage',
    path: '*',
    component: () => import('./pages/NotFoundPage'),
    props
  })

  return new Router({
    mode: 'history',
    fallback: false,
    routes,
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition
      }
      if (to.hash) {
        return {
          selector: to.hash
        }
      }
      return { x: 0, y: 0 }
    }
  })
}
