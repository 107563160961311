<template>
  <div class="quote-element" data-cy="quote-elem">
    <component :is="tag">
      <template #default>{{ data.data.quote }}</template>
      <template v-if="author" #author>{{ author }}</template>
    </component>
  </div>
</template>

<script>
import QuoteType1 from '@landing/common/components/QuoteType1'
import QuoteType2 from '@landing/common/components/QuoteType2'

export default {
  name: 'QuoteElement',
  components: {
    QuoteType1,
    QuoteType2
  },
  props: {
    data: Object
  },
  computed: {
    tag() {
      return `quote-type-${this.data.data.type}`
    },
    author() {
      return this.data.data.author
    }
  }
}
</script>

<style lang="scss" scoped>
.quote-element {
  padding: 20px;
}
</style>
