import range from 'lodash.range'

// 轉換每個字需要的 css 物件
// 例如
// [
//   "你好嗎?",
//   "我很好"
// ]
// =>
// [
//   [{}, {}, {}, {}],
//   [{}, {}, {}]
// ]
export default function toLineCssArr(lines) {
  return lines.map(line => {
    return range(0, line.length)
      .map(() => ({}))
  })
}
