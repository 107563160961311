// 這個 mixin 允許你使用 this.on 來註冊事件
// 並且自動在元件要 destroy 之前幫你解除事件監聽
export default {
  beforeCreate() {
    this._listeners = []
  },
  methods: {
    on(dom, eventName, cb, useCapture = false) {
      this._listeners.push({ dom, eventName, cb, useCapture })
      dom.addEventListener(eventName, cb, useCapture)
      return () => this.off(dom, eventName, cb, useCapture)
    },
    off(dom, eventName, cb, useCapture = false) {
      dom.removeEventListener(eventName, cb, useCapture)
      this._listeners = this._listeners.filter(row => {
        return (dom !== row.dom) &&
          (eventName !== row.eventName) &&
          (cb !== row.cb) &&
          (useCapture !== row.useCapture)
      })
    },
    once(dom, eventName, cb, useCapture = false) {
      let off
      const onceCb = (...args) => {
        off()
        cb(...args)
      }
      off = this.on(dom, eventName, onceCb, useCapture)
    }
  },
  beforeDestroy() {
    this._listeners.forEach(row => {
      row.dom.removeEventListener(row.eventName, row.cb, row.useCapture)
    })
  }
}
