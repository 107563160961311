import rootReducer from './reducers'
import rootEpic from './epics'
import {
  applyMiddleware,
  createEpicMiddleware,
  createStore,
  toPromise
} from '../utils'

const epicMiddleware = createEpicMiddleware()
const middlewares = [toPromise, epicMiddleware]
const store = createStore(rootReducer, applyMiddleware(...middlewares))

epicMiddleware.run(rootEpic)

const subscribe = store.subscribe

store._REDUX_UNSUBSCRIPTIONS = []

store.subscribe = func => {
  const unsubscribe = subscribe(func)
  store._REDUX_UNSUBSCRIPTIONS.push(unsubscribe)
  return unsubscribe
}

store.unsubscribeAll = () => {
  store._REDUX_UNSUBSCRIPTIONS.forEach(unsubscribe => unsubscribe())
  store._REDUX_UNSUBSCRIPTIONS.length = 0
}

export default store
