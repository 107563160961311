export const SET_WINDOW_SIZE = '@landing-desktop/windowSize/SET_WINDOW_SIZE'

export const setWindowSize = size => {
  return {
    type: SET_WINDOW_SIZE,
    size
  }
}

const initialState = {
  width: null,
  height: null
}

export default function windowSize(state = initialState, action) {

  switch (action.type) {

    case SET_WINDOW_SIZE: {
      const { width, height } = action.size
      return { ...state, width, height }
    }

    default:
      return state
  }
}
