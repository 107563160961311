import { ofType, map, tap } from '../utils'

const createEpic = getOperators => (action$, state$) => {

  const [arg, ...operators] = getOperators(action$, state$)
  let types = arg
  let id;

  if (typeof arg === 'string') {
    types = [arg]
  }

  return action$.pipe(
    ofType(...types),
    // store id for middleware toPromise
    tap(action => id = action._id),
    ...operators,
    // inject id to new action
    map(action => ({ ...action, _id: id }))
  )
}
export default createEpic
