export default function arrToObj(arr, prop) {
  if (typeof prop === 'undefined') {
    return arr.reduce((obj, v) => {
      obj[v] = true
      return obj
    }, {})
  }
  return arr.reduce((obj, row) => {
    obj[row[prop]] = row
    return obj
  }, {})
}
