import locale from '@landing/common/consts/locale'
import parseIso from 'date-fns/parseISO'
import f from 'date-fns/format'
import isStr from './isStr'

export default function format(strOrDate, pattern) {

  if (isStr(strOrDate)) {
    return f(parseIso(strOrDate), pattern, { locale })
  }
  return f(strOrDate, pattern, { locale })
}
