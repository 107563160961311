<template>
  <div class="fb-page-element" data-cy="fb-page-elem" :class="getAlignClassName(align)">
    <iframe :src="src"
            :width="width"
            :height="height"
            style="border: none; overflow: hidden"
            scrolling="no"
            frameborder="0"
            allowTransparency="true"
            allow="encrypted-media" />
  </div>
</template>

<script>
import alignMixin from '@landing/common/mixins/alignMixin'
import elementMixin from '@landing/common/mixins/elementMixin'
import qs from 'qs'
import { LANDING_FB_APP_ID } from '@landing/common/consts/fb'

export default {
  name: 'FbPageElement',
  mixins: [alignMixin, elementMixin],
  props: {
    data: Object
  },
  computed: {
    src() {
      const query = qs.stringify({
        href: this.href,
        tabs: 'timeline',
        width: this.width,
        height: this.height,
        'small_header': this.smallHeader,
        'adapt_container_width': false,
        'hide_cover': this.hideCover,
        'show_facepile': this.showFacepile,
        appId: LANDING_FB_APP_ID
      })
      return `https://www.facebook.com/plugins/page.php?${query}`
    }
  },
  computedProps: [
    'href',
    'align',
    'height',
    'hideCover',
    'showFacepile',
    'smallHeader',
    'width'
  ]
}
</script>

<style lang="scss" scoped>
.fb-page-element {
  padding-top: 20px;
  padding-bottom: 20px;
}
</style>
