class OrderItem {

  constructor(row) {
    Object.assign(this, row)
    this.isLoading = false
  }

  get key() {
    // type: product or promo
    return `${this.type}:${this.id}`
  }

  get subtotal() {
    if (! Object.prototype.hasOwnProperty.call(this, 'qty')) {
      return this.price
    }
    return this.price * this.qty
  }
}

export default OrderItem
