/* global dataLayer, gtag */
// https://developers.google.com/gtagjs/devguide/routing
// https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce
// https://support.google.com/google-ads/answer/6095821?hl=zh-Hant

import TrackerBase from './TrackerBase'
import Gtag from './Gtag'

class TrackerGa4 extends TrackerBase {

  constructor({ page }) {
    super()
    this.page = page
  }

  init() {
    const { ga, currency } = this.page
    if (! ga) {
      return this
    }
    if (ga.user) {
      this._loadScript(ga.user)
    }
    this._loadScript(ga.global)

    this.enabled = Boolean(ga.global) || Boolean(ga.user)

    return this
  }

  // 進入頁面 (自動事件)
  //  每次網頁載入或有效網站變更了瀏覽器記錄狀態時
  //
  // event:
  //  page_view
  //
  // params:
  //  page_location 網頁網址
  //  page_referrer 上一頁網址
  //
  pageView() {
  }

  // 看完頁面 (自動事件)
  //  網頁垂直捲動深度達 90%
  //
  // event:
  //  scroll
  //
  viewContent() {
  }

  // 加入購物車
  //
  // event:
  //  add_to_cart
  //
  // https://developers.google.com/analyticsevguides/collection/ga4/ecommerce?client_type=gtag&sjid=4118611902810034201-AP#add_or_remove_an_item_from_a_shopping_cart
  //
  addToCart({ productId, title, price, qty, totalAmount, currency, cart }) {
    gtag("event", "add_to_cart", {
      currency,
      value: totalAmount,
      items: [
        {
          item_id: productId,
          item_name: title,
          price,
          quantity: qty
        }
      ]
    });

  }

  // 開始結帳
  //
  // event:
  //  begin_checkout
  //
  // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag&sjid=4118611902810034201-AP#add_or_remove_an_item_from_a_shopping_cart
  //
  checkout({ cart, page: { currency } }) {
    const { items, totalOrderAmount } = cart
    gtag("event", "begin_checkout", {
      currency,
      value: totalOrderAmount,
      items: items.map((item, index) => {
        return {
          item_id: item.id,
          item_name: item.title,
          index,
          price: item.price,
          quantity: item.qty
        }
      })
    });
  }

  // 完成結帳
  //
  // event:
  //  purchase
  //
  // https://developers.google.com/analytics/devguides/collection/ga4/set-up-ecommerce
  //
  purchase({ orderId, totalOrderAmount, currency, order, page }) {
    const { shippingFee, items } = order
    gtag("event", "purchase", {
      transaction_id: orderId,
      value: totalOrderAmount,
      shipping: shippingFee,
      currency,
      items: items.map(item => {
        return {
          item_id: item.id,
          item_name: item.title,
          price: item.price,
          item_category: page.aliasName,
          quantity: item.qty
        }
      })
    })
  }

  _loadScript(gtagId) {
    new Gtag(gtagId).config()
  }
}

export default TrackerGa4
