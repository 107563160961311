<template>
  <div class="quote-type1">
    <div class="quote-type1__quote">
      <i class="icon-quote" />
      <slot />
      <i class="icon-quote icon-quote--reversed" />
    </div>
    <div v-if="this.$slots.author" class="quote-type1__author">
      <slot name="author" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuoteType1'
}
</script>

<style lang="scss" scoped>
.quote-type1__quote {
  white-space: pre-wrap;
  line-height: 1.8;
  margin: 10px;
  position: relative;
  padding: 20px;
  font-size: 24px;
  color: #555;
  text-align: center;
  border: 2px solid #555;

  .icon-quote {
    position: absolute;
    text-align: left;
    display: block;
    background-color: #fff;
    padding: 8px;
  }

  $offset: -20px;
  .icon-quote {
    top: $offset;
    left: $offset;
  }
  .icon-quote.icon-quote--reversed {
    top: auto;
    left: auto;
    bottom: $offset;
    right: $offset;
    transform: rotate(180deg);
  }
}
.quote-type1__author {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  margin-top: 30px;
  text-align: right;
  &:before {
    content: '────';
    margin-right: 10px;
  }
}
</style>
