import IntlAddressBase from './Base'

class TaiwanAddress extends IntlAddressBase {

  constructor(props) {
    super(props)
  }

  toString() {
    // 新北市新莊區新北大道2段217號8樓
    const line = ['state', 'city', 'addr1', 'addr2'].map(prop => this[prop])
      .join('')
    if (this.zipcode) {
      return `${this.zipcode} ${line}`
    }
    return line
  }
}

export default TaiwanAddress
