<template>
  <nav v-if="isNavContainerVisible" class="app-nav" :class="wrapperClass">
    <div v-if="siteNavVisible"
         ref="siteNav"
         data-cy="site-nav"
         class="site-nav"
         :class="{ 'site-nav--shrank': shrank }">
      <a class="site-nav__logo" :href="getSiteUrl(page.site)">
        <app-image class="site-nav__brand"
                   :src="page.site.logoUrl.desktop"
                   :alt="page.site.title"
                   @load="handleMounted"
                   @error="handleMounted" />
      </a>
      <div class="site-nav__seperator" />
      <ul class="site-nav__menu">
        <li v-for="item in page.site.navbar.items" :key="item.id">
          <nav-dropdown v-if="isDropdown(item)"
                        :item="item"
                        @anchor-click="handleAnchorClick" />
          <a v-if="isAnchor(item)"
             :href="item.url"
             @click="handleAnchorClick({ event: $event, url: item.url })">{{ item.title }}</a>
        </li>
      </ul>
    </div>
    <navbar-element v-if="navbarVisible" ref="navbar" :data="navbar" />
  </nav>
</template>

<script>
import NavbarElement from '@landing/common/components/NavbarElement'
import getSiteUrl from '@landing/common/utils/getSiteUrl'
import { parseUrl } from '@landing/landing-desktop/utils'
import AppImage from './AppImage'
import NavDropdown from './NavDropdown'

export default {
  name: 'AppNav',
  components: {
    AppImage,
    NavDropdown,
    NavbarElement
  },
  props: {
    shrank: Boolean,
    page: Object,
    mounted: Function,
    updated: Function,
    siteNavVisible: Boolean,
    navbarVisible: Boolean
  },
  computed: {
    wrapperClass() {
      return {
        'app-nav--shrank': this.shrank,
        'app-nav--has-navbar': this.navbarVisible,
        'app-nav--has-navbar-only': this.navbarVisible && (! this.siteNavVisible)
      }
    },
    navbar() {
      return this.get(this, 'page.content.navbar')
    },
    isNavContainerVisible() {
      return this.siteNavVisible || this.navbarVisible
    }
  },
  mounted() {
    this.emitMountedIfNeeded()
  },
  updated() {
    if (this.updated) {
      this.updated(this.getElements())
    }
  },
  methods: {
    getSiteUrl,
    emitMountedIfNeeded() {
      // 1. 如果有 siteNav 且 site.logoUrl 沒東西
      // 這時因為 AppImage 是顯示 placeholder
      // 所以不會觸發 @error="handleMounted"，要執行 this.mounted
      if (this.siteNavVisible && (! this.get(this, 'page.site.logoUrl.desktop'))) {
        return this.mounted(this.getElements())
      }
      // 2. 沒有 siteNav，不會觸發 handleMounted
      // 所以這裡要執行 this.mounted
      if ((! this.siteNavVisible) && this.mounted) {
        this.mounted(this.getElements())
      }
    },
    handleAnchorClick({ event, url }) {

      const { host, path, pathname } = parseUrl(url)
      const isHistorySupported = window.history && history.pushState
      const sameOrigin = (host === location.host)
      const isSalesPage = /^\/[A-Za-z0-9-_]+$/.test(pathname)
      const isStaticPage = /^\/contents\/\w+$/.test(pathname)

      if (isHistorySupported && sameOrigin && (isSalesPage || isStaticPage)) {
        event.preventDefault()
        event.stopPropagation()
        this.$router.push(path)
      }
    },
    isDropdown(item) {
      return item.type === 'NavCategory'
    },
    isAnchor(item) {
      return ['NavStaticPage', 'Page'].includes(item.type)
    },
    getElements() {
      const { siteNav, navbar } = this.$refs
      return {
        siteNav,
        navbar
      }
    },
    handleMounted() {
      if (this.mounted) {
        this.mounted(this.getElements())
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-nav {
  background-color: $white1;
}
.app-nav.app-nav--shrank {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid $gray3;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, .3);
}
.app-nav.app-nav--shrank.app-nav--has-navbar {
  border-bottom: 0;
  box-shadow: none;
}
.app-nav.app-nav--has-navbar-only {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
}
.site-nav {
  font-size: .875em;
  @include desktop-width;
  margin: 0 auto;
  background-color: #fff;
  padding-top: 26px;
}
.site-nav__logo {
  transition: .2s all;
  display: block;
}
.site-nav__brand {
  margin: 0 auto 14px;
  max-width: 140px;
  height: auto;
  display: block;
}
.no-flexbox .site-nav.site-nav--shrank {
  .site-nav__logo,
  .site-nav__seperator {
    margin-bottom: 7px;
  }
  .site-nav__menu {
    display: inline-block;
    max-width: 700px;
  }
}
.site-nav.site-nav--shrank {
  padding-top: 0;
  display: flex;
  align-items: center;
  .site-nav__logo {
    display: inline-block;
  }
  .site-nav__brand {
    margin: 0;
    height: 32px;
    width: auto;
    max-width: none;
  }
  .site-nav__seperator {
    display: inline-block;
    width: 1px;
    height: 28px;
    background-color: $gray10;
    margin-left: 14px;
    margin-right: 30px;
  }
  .site-nav__menu {
    white-space: nowrap;
    overflow-x: auto;
  }
}
.site-nav__menu {
  color: $black6;
  a {
    display: inline-block;
    color: $black6;
    padding: 15px 18px;
  }
  display: block;
  text-align: center;
  > li {
    transition: .2s background-color;
    display: inline-block;
    &:hover {
      background-color: $gray10;
    }
  }
}
</style>
