import { get } from '@landing/common/utils'
import TrackerBase from '@landing/landing-common/models/TrackerBase'

class TrackerCustom extends TrackerBase {

  constructor({ page }) {
    super()
    this.page = page
  }

  init() {
    const trackings = get(this.page, 'trackings') || {}
    ;['pageView', 'viewContent', 'addToCart', 'purchase'].forEach(method => {
      const script = trackings[method]
      if (script !== '') {
        // eslint-disable-next-line no-unused-vars
        this[method] = (params) => {
          try {
            eval(script)
          }
          catch (err) {
            // eslint-disable-next-line no-console
            console.error(`TrackerCustom Error: ${err}`)
          }
        }
      }
    })
    this.enabled = true
    return this
  }
}

export default TrackerCustom
