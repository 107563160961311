import { render, staticRenderFns } from "./PrevBtn.vue?vue&type=template&id=0943817c&scoped=true&"
import script from "./PrevBtn.vue?vue&type=script&lang=js&"
export * from "./PrevBtn.vue?vue&type=script&lang=js&"
import style0 from "./PrevBtn.vue?vue&type=style&index=0&id=0943817c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0943817c",
  null
  
)

export default component.exports