export default {
  data() {
    return {
      errorImageMap: {}
    }
  },
  methods: {
    hasImageError(src) {
      return this.errorImageMap[src]
    },
    saveErrorImageSrc(src) {
      this.$set(this.errorImageMap, src, true)
    }
  }
}
