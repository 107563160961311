<template>
  <div>
    <span>滿 <strong class="txt-danger" v-text="comma(item.amount)" /> 元</span>
    {{ ' ➔ ' }}
    <template v-if="item.isDiscountAmount">
      折 <strong v-text="comma(item.discountsAmount)" /> 元
      <strong v-if="item.desc" v-text="' + ' + item.desc" />
    </template>
    <template v-else-if="item.isDiscountRate && (layoutDiscount === $options.LAYOUT_DISCOUNT_ENGLISH)">
      <strong v-text="discountPercent(item._discountsRate)" />% Off
      <strong v-if="item.desc" v-text="' + ' + item.desc" />
    </template>
    <template v-else-if="item.isDiscountRate">
      打 <strong v-text="item.discountsRate" /> 折
      <strong v-if="item.desc" v-text="' + ' + item.desc" />
    </template>
    <strong v-else-if="item.isDiscountNone" v-text="item.desc" />
    <strong v-if="used" class="discount-lv-title__tag">
      <check-svg class="discount-lv-title__check-svg" />
      此金額已達標 !
    </strong>
    <strong v-else-if="shortBy > 0" class="discount-lv-title__tag">
      還差 <span class="discount-lv-title__num" v-text="comma(shortBy)" /> 元
    </strong>
  </div>
</template>

<script>
import CheckSvg from '@landing/common/components/CheckSvg'
import comma from '@superlanding/commaseparate'
import { LAYOUT_DISCOUNT_ENGLISH } from '@common/consts/pageLayout'
import { discountPercent } from '@common/utils/discountText'

export default {
  name: 'DiscountLvTitle',
  components: {
    CheckSvg
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    layoutDiscount: String,
    used: Boolean,
    shortBy: Number
  },
  methods: {
    comma,
    discountPercent
  },
  LAYOUT_DISCOUNT_ENGLISH
}
</script>

<style lang="scss" scoped>
.discount-lv-title__tag {
  background-color: #555;
  color: #fff;
  border-radius: 2px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 400;
  margin-left: 1em;
  white-space: nowrap;
}
.discount-lv-title__check-svg {
  margin-right: .2em;
}
.discount-lv-title__num {
  font-weight: 900;
}
</style>
