import Keyboard, { ENTER_KEY, ESC_KEY } from '@landing/common/models/Keyboard'

export default {
  mounted() {
    this.on(window, 'keyup', handleKeyUp.bind(this))
  }
}

function handleKeyUp(event) {
  const key = Keyboard.getKey(event)
  if (key === ENTER_KEY) {
    this.handleKeyUp('Enter')
  }
  if (key === ESC_KEY) {
    this.handleKeyUp('Escape')
  }
}
