import getAlias from '@landing/landing-common/utils/getAlias'
import http from '@landing/landing-desktop/plugins/http'
import createEpic from '../createEpic'
import { GET_CART_DATA, GET_CART_DATA_SUCCESS, GET_CART_DATA_FAIL,
  ADD_ORDER_PRODUCT, ADD_ORDER_PRODUCT_SUCCESS, ADD_ORDER_PRODUCT_FAIL,
  REMOVE_ORDER_PRODUCT, REMOVE_ORDER_PRODUCT_SUCCESS,
  REMOVE_ORDER_PRODUCT_FAIL, ADD_FREE_PRODUCT_SET,
  ADD_ORDER_PROMO, ADD_ORDER_PROMO_SUCCESS, ADD_ORDER_PROMO_FAIL,
  ADD_FREE_PRODUCT_SET_SUCCESS, ADD_FREE_PRODUCT_SET_FAIL,
  SET_COUPON, SET_COUPON_SUCCESS, SET_COUPON_FAIL,
  SUBMIT_MULTI_STEP_FORM, SUBMIT_MULTI_STEP_FORM_SUCCESS,
  SUBMIT_MULTI_STEP_FORM_FAIL, SUBMIT_STANDARD_FORM,
  SUBMIT_STANDARD_FORM_SUCCESS, SUBMIT_STANDARD_FORM_FAIL
} from '../reducers/cart'
import { defer, of, from, exhaustMap, map, retryWhen,
  catchError, takeWhile, tap } from '@landing/landing-desktop/utils'
import retryStrategy from '@landing-common/utils/retryStrategy'

const strategy = retryStrategy({ excludedStatusCodes: [400, 404] })

let isSubmittingStandardForm = false
let isSubmittingMultiStepForm = false

const submitStandardForm = (action$, state$) => [
  SUBMIT_STANDARD_FORM,
  takeWhile(() => ! isSubmittingStandardForm),
  tap(() => isSubmittingStandardForm = true),
  exhaustMap(action => {
    return from(http.post(`/${getAlias(state$)}/cart.json`, action.data))
      .pipe(
        map(res => ({
          type: SUBMIT_STANDARD_FORM_SUCCESS,
          payload: res.data,
        })),
        catchError(err => of({ type: SUBMIT_STANDARD_FORM_FAIL, payload: err }))
      )
  }),
  tap(() => isSubmittingStandardForm = false)
]

const submitMultiStepForm = (action$, state$) => [
  SUBMIT_MULTI_STEP_FORM,
  takeWhile(() => ! isSubmittingMultiStepForm),
  tap(() => isSubmittingMultiStepForm = true),
  exhaustMap(action => {
    return from(http.post(`/${getAlias(state$)}/cart/next.json`, action.data))
      .pipe(
        map(res => ({
          type: SUBMIT_MULTI_STEP_FORM_SUCCESS,
          payload: res.data,
        })),
        catchError(err => of({ type: SUBMIT_MULTI_STEP_FORM_FAIL, payload: err }))
      )
  }),
  tap(() => isSubmittingMultiStepForm = false)
]

const setCoupon = (action$, state$) => [
  SET_COUPON,
  exhaustMap(action => {
    return from(http.post(`/${getAlias(state$)}/cart/coupon.json`, action.data))
      .pipe(
        map(res => ({
          type: SET_COUPON_SUCCESS,
          payload: res.data,
        })),
        catchError(err => of({ type: SET_COUPON_FAIL, payload: err }))
      )
  })
]

const getCartData = (action$, state$) => [
  GET_CART_DATA,
  exhaustMap(() => {
    return from(http.get(`/${getAlias(state$)}/cart.json`, { timeout: 2000 }))
      .pipe(
        map(res => ({ type: GET_CART_DATA_SUCCESS, payload: res.data })),
        catchError(err => of({ type: GET_CART_DATA_FAIL, payload: err }))
      )
  })
]

const addFreeProductSet = (action$, state$) => [
  ADD_FREE_PRODUCT_SET,
  exhaustMap(action => {
    const { data, orderProductId } = action
    return from(http.post(`/${getAlias(state$)}/order_products/${orderProductId}/free_product_set.json`, data))
      .pipe(
        map(res => ({
          type: ADD_FREE_PRODUCT_SET_SUCCESS,
          orderProductId,
          payload: res.data,
        })),
        catchError(err => of({ type: ADD_FREE_PRODUCT_SET_FAIL, payload: err }))
      )
  })
]

const addOrderProduct = (action$, state$) => [
  ADD_ORDER_PRODUCT,
  exhaustMap(action => {
    const params = { id: action.productId }
    return defer(() => http.post(`/${getAlias(state$)}/products.json`, action.data, { params }))
      .pipe(
        map(res => ({
          type: ADD_ORDER_PRODUCT_SUCCESS,
          payload: res.data,
          options: action.options
        })),
        retryWhen(strategy),
        catchError(err => of({ type: ADD_ORDER_PRODUCT_FAIL, payload: err }))
      )
  })
]

const addOrderPromo = (action$, state$) => [
  ADD_ORDER_PROMO,
  exhaustMap(action => {
    const params = { id: action.promoId }
    return defer(() => http.post(`/${getAlias(state$)}/promos.json`, action.data, { params }))
      .pipe(
        map(res => ({
          type: ADD_ORDER_PROMO_SUCCESS,
          payload: res.data
        })),
        retryWhen(strategy),
        catchError(err => of({ type: ADD_ORDER_PROMO_FAIL, payload: err }))
      )
  })
]

const removeOrderProduct = (action$, state$) => [
  REMOVE_ORDER_PRODUCT,
  exhaustMap(action => {
    const { orderProductId } = action
    return from(http.delete(`/${getAlias(state$)}/products/${orderProductId}`))
      .pipe(
        map(res => ({
          type: REMOVE_ORDER_PRODUCT_SUCCESS,
          payload: res.data,
          orderProductId
        })),
        catchError(err => of({ type: REMOVE_ORDER_PRODUCT_FAIL, payload: err }))
      )
  })
]

export default [
  createEpic(setCoupon),
  createEpic(getCartData),
  createEpic(addFreeProductSet),
  createEpic(addOrderProduct),
  createEpic(addOrderPromo),
  createEpic(removeOrderProduct),
  createEpic(submitStandardForm),
  createEpic(submitMultiStepForm)
]
