import TrackerBase from './TrackerBase'

class TrackerHotjar extends TrackerBase {

  constructor({ page }) {
    super()
    this.page = page
  }

  init() {
    const { hotjarId } = this.page
    if (! hotjarId) {
      return this
    }
    this._loadScript(hotjarId)
    this.enabled = true
    return this
  }

  pageView() {
  }

  viewContent() {
  }

  addToCart() {
  }

  checkout() {
  }

  purchase() {
  }

  _loadScript(hotjarId) {

    const hjid = parseInt(hotjarId, 10)

    // https://insights.hotjar.com/site/list
    ;(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={ hjid,hjsv:6 };
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  }
}

export default TrackerHotjar
