import { hideConfirm, showConfirm } from '../redux/reducers/confirm'

export default {
  data() {
    return this.mapState({ confirm: 'confirm' })
  },
  beforeCreate() {
    this.mapDispatch({ hideConfirm, showConfirm })
  }
}
