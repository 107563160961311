import IntlAddressBase from './Base'

class SingaporeAddress extends IntlAddressBase {

  constructor(props) {
    super(props)
  }
}

export default SingaporeAddress
