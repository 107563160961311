<template>
  <svg width="48px" height="48px" viewBox="0 0 48 48">
    <title>購物車</title>
    <desc>Created with Sketch.</desc>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <circle cx="24" cy="24" r="24" />
      <g transform="translate(11.000000, 13.000000)">
        <path fill="#FFFFFF" fill-rule="nonzero" d="M22.0050057,13.9112682 L23.9747145,5.24671738 C24.1169229,4.62112015 23.6412978,4.02539062 22.9995893,4.02539062 L6.63366838,4.02539062 L6.25175161,2.15876312 C6.15658492,1.69350174 5.74708482,1.359375 5.27204303,1.359375 L1,1.359375 C0.447708449,1.359375 0,1.80697403 0,2.35913086 L0,3.02563477 C0,3.5777916 0.447708449,4.02539062 1,4.02539062 L3.91179268,4.02539062 L6.83879343,18.3316887 C6.13854325,18.7342987 5.66666813,19.4891977 5.66666813,20.3547363 C5.66666813,21.6430884 6.71133507,22.6875 8,22.6875 C9.28866906,22.6875 10.333336,21.6430884 10.333336,20.3547363 C10.333336,19.7018124 10.0647109,19.1119148 9.63233582,18.6884766 L18.3676297,18.6884766 C17.9352963,19.1119148 17.6666712,19.7018124 17.6666712,20.3547363 C17.6666712,21.6430884 18.7113382,22.6875 20,22.6875 C21.2886722,22.6875 22.3333391,21.6430884 22.3333391,20.3547363 C22.3333391,19.4311285 21.796339,18.6329901 21.0175471,18.2550407 L21.2474221,17.2437877 C21.3896305,16.6181905 20.9140054,16.0224609 20.2722969,16.0224609 L9.08821068,16.0224609 L8.81550227,14.6894531 L21.0298804,14.6894531 C21.4967972,14.6894531 21.9015473,14.3664487 22.0050057,13.9112682 Z" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CartIconSvg'
}
</script>
