/* global fbq */
import TrackerBase from './TrackerBase'
import FacebookConversion from './FacebookConversion'
import StringFilter from './StringFilter'

class TrackerFacebook extends TrackerBase {

  constructor({ page }) {
    super()
    this.page = page
  }

  init() {
    const { facebookPixel1, facebookPixel2,
      facebookPixelConversionApi, facebookPixel2ConversionApi } = this.page

    if ((! facebookPixel1) && (! facebookPixel2)) {
      return this
    }
    this._loadScript()
    this._initFbq()
    this.enabled = true
    this.conversionApiEnabled = (facebookPixelConversionApi || facebookPixel2ConversionApi)
    return this
  }

  pageView(params) {
    let eventId
    if (this.conversionApiEnabled) {
      eventId = FacebookConversion.pageView(this.page)
    }
    this._track('PageView', this._buildParams(params), eventId)
  }

  viewContent(params) {
    let eventId
    if (this.conversionApiEnabled) {
      eventId = FacebookConversion.viewContent(this.page)
    }
    this._track('ViewContent', this._buildParams(params), eventId)
  }

  addToCart({ title, price, qty, totalAmount, currency, productId }) {
    let eventId
    const params = {
      productTitle: StringFilter.filterEmoji(title),
      productQty: qty,
      productPrice: price,
      value: totalAmount,
      currency
    }
    if (this.conversionApiEnabled && this.page.aliasName) {
      eventId = FacebookConversion.addToCart(this.page, productId)
    }
    this._track('AddToCart', this._buildParams(params), eventId)
  }

  // 結帳過程只需要打一次 InitialCheckout
  checkout({ totalOrderAmount }) {
    this._track('InitiateCheckout', this._buildParams({ totalOrderAmount }))
  }

  purchase({ totalOrderAmount, currency, orderMobile, orderEmail, order, orderKey }) {

    let eventId

    // https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching/
    // 要確認 init 第二次之前的資料會不會把 email 與電話 merge 進去
    this._initFbq({ em: orderEmail, ph: orderMobile })

    if (this.conversionApiEnabled && this.page.aliasName) {
      eventId = FacebookConversion.purchase(this.page, order.id, orderKey)
    }

    let purchase_params = { value: totalOrderAmount, currency }
    // 如果有 Facebook 外部編號就帶入
    if (order.facebookExternalId) {
      purchase_params = Object.assign(purchase_params, { externalId: order.facebookExternalId })
    }

    this._track('Purchase', this._buildParams(purchase_params), eventId)
  }

  _initFbq(params = {}) {
    const { facebookPixel1, facebookPixel2 } = this.page

    ;[facebookPixel1, facebookPixel2].forEach(pixel => {
      pixel && fbq('init', pixel, params, { agent: "plSuperlanding" })
    })
  }

  _track(event, params, eventId) {
    if (eventId) {
      return fbq('track', event, params, { eventID: eventId })
    }
    fbq('track', event, params)
  }

  _buildParams(params) {
    const { idPrefix, title, aliasName } = this.page
    const res = Object.assign({
      pageId: idPrefix,
      pageTitle: StringFilter.filterEmoji(title),
      pageAlias: aliasName
    }, params)

    // 移除 title 的 emoji
    if (res.title) {
      res.title = StringFilter.filterEmoji(res.title)
    }

    // 避免 facebook business tools terms violation
    delete res.page
    delete res.cart
    delete res.site
    delete res.order
    delete res.sortedProductMap
    delete res.isOrderPage

    return res
  }

  _loadScript() {
    // from https://developers.facebook.com/docs/facebook-pixel/implementation/
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');

    // 避免臉書自動偵測 history API 的 pushState
    // https://developers.facebook.com/ads/blog/
    // post/v2/2017/05/29/tagging-a-single-page-application-facebook-pixel/
    fbq.disablePushState = true

    // https://gist.github.com/sunderls/dfd5293a8b8f24a4ef37189a1d8c1b46#file-fbevents-js-L662
    // 看原始碼發現可以直接設定 allowDuplicatePageViews
    // 讓 SPA 架構的程式發送多個 page view
    fbq.allowDuplicatePageViews = true
  }
}

export default TrackerFacebook
