<template>
  <div data-cy="youtube-elem" class="youtube-element">
    <iframe v-if="src"
            :src="src"
            frameBorder="0"
            @load="handleLoad"
            @mouseover="$emit('mouseover')"
            @mouseleave="$emit('mouseleave')" />
    <transition name="fade" appear>
      <img v-show="! loaded"
           v-observe-visibility="{ callback: handleVisibilityChange, once: true }"
           class="youtube-element__thumb"
           :src="`//img.youtube.com/vi/${videoId}/sddefault.jpg`"
           alt="thumb">
    </transition>
  </div>
</template>

<script>
import qs from 'qs'

export default {
  name: 'YoutubeElement',
  props: {
    data: Object
  },
  data() {
    return {
      src: '',
      loaded: false
    }
  },
  computed: {
    youtubeParams() {
      // https://developers.google.com/youtube/player_parameters#rel
      return qs.stringify({
        rel: 0,
        modestbranding: 1
      })
    },
    videoId() {
      return this.data.data.videoId
    }
  },
  watch: {
    data: {
      handler() {
        this.src = ''
        this.loaded = false
        // first tick to remove iframe
        // and second tick to append new iframe
        this.$nextTick(() => this.$nextTick(() => this.loadVideo()))
      },
      deep: true
    }
  },
  methods: {
    handleLoad() {
      this.loaded = true
    },
    loadVideo() {
      this.src = `//www.youtube.com/embed/${this.videoId}?${this.youtubeParams}`
    },
    handleVisibilityChange(isVisible) {
      if (isVisible) {
        this.loadVideo()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.youtube-element {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.youtube-element__thumb {
  transition: opacity .23s linear;
  @include absolute-center;
  width: 100%;
}
</style>
