import { uuid } from '@landing/landing-desktop/utils'

export const ADD_TOAST_ITEM = '@landing-desktop/cart/ADD_TOAST_ITEM'
export const REMOVE_TOAST_ITEM = '@landing-desktop/cart/REMOVE_TOAST_ITEM'

export const SET_CENTER_TOAST = '@landing-desktop/cart/SET_CENTER_TOAST'

export const setCenterToast = text => {
  return {
    type: SET_CENTER_TOAST,
    text
  }
}

export const addToastItem = data => {
  const id = uuid()
  const btnFunc = data.btnFunc || (() => {})
  return {
    type: ADD_TOAST_ITEM,
    data: {
      id,
      message: data.message,
      btnText: data.btnText,
      btnFunc: btnFunc.bind(null, id)
    }
  }
}

export const removeToastItem = id => {
  return {
    type: REMOVE_TOAST_ITEM,
    id
  }
}

const initialState = {
  items: [],
  centerToastText: ''
}

export default function toast(state = initialState, action) {

  switch (action.type) {
    case ADD_TOAST_ITEM:
      return { ...state, items: [...state.items, action.data] }
    case REMOVE_TOAST_ITEM:
      return { ...state, items: state.items.filter(item => item.id !== action.id) }
    case SET_CENTER_TOAST:
      return { ...state, centerToastText: action.text }
    default:
      return state
  }
}
