<template>
  <div v-if="selected > 0" class="promo-hint" :class="classname">
    <div>
      你已選擇 <span :class="selectedClass" v-text="selected" />
      <span class="promo-hint__qty">/ {{ qty }}</span>
    </div>
    <div v-if="selectedCountValid"
         class="promo-hint__fixed-hint">
      <span>
        原價 {{ comma(originalPrice) }} 折扣後 {{ comma(price) }}
        <strong>( 省 {{ comma(discount) }} )</strong>
      </span>
      <span v-if="freeShippingFulfilled"
            class="promo-hint__free-shipping-hint txt-free">(金額達到免運門檻)</span>
    </div>
  </div>
  <div v-else />
</template>

<script>
import { comma } from '@landing/landing-common/utils'
import dollar from '@landing/landing-common/utils/dollar'
import isValidDiscountCount from '@common/utils/isValidDiscountCount'

export default {
  name: 'PromoHint',
  props: {
    item: {
      type: Object,
      required: true
    },
    selectedItems: {
      type: Array,
      required: true
    },
    cart: Object
  },
  computed: {
    classname() {
      return this.selectedCountValid ? '' : 'promo-hint--invalid'
    },
    freeShippingFulfilled() {
      const { cart } = this
      return this.selectedCountValid && cart && (this.price >= cart.freeShippingBalance)
    },
    selected() {
      return this.selectedItems.reduce((total, row) => row.qty + total, 0)
    },
    selectedClass() {
      return this.selectedCountValid ? '' : 'txt-danger'
    },
    qty() {
      return this.item.promoDetails.qty
    },
    originalPrice() {
      if (this.isFixedPriceType) {
        return this.item.promoDetails.originalPrice
      }
      // 後台如果選折數，這裡就用 selectedItems 去計算
      return this.selectedItems.reduce((sum, p) => {
        return sum + (p.qty * p.price)
      }, 0)
    },
    price() {
      if (this.isFixedPriceType) {
        return this.item.promoDetails.price
      }
      return this.discountedPrice
    },
    discount() {
      return (this.originalPrice - this.price)
    },
    isDiscountType() {
      return (this.item.promoType === 'discounts')
    },
    isFixedPriceType() {
      return (this.item.promoType === 'static_price')
    },
    selectedCountValid() {
      if (this.isDiscountType) {
        const { interval } = this.item.promoDetails
        return isValidDiscountCount(this.selected, this.qty, interval)
      }
      return (this.selected === this.qty)
    },
    discountedPrice() {
      const price = this.selectedItems.reduce((sum, p) => {
        return sum + (p.qty * p.price)
      }, 0) * this.item.promoDetails.rate
      return Math.ceil(price)
    }
  },
  methods: {
    comma,
    dollar
  }
}
</script>

<style lang="scss" scoped>
.promo-hint {
  font-size: 1.2em;
  color: $black5;
}
.no-flexbox .promo-hint {
  display: inline-block;
  vertical-align: middle;
}
.promo-hint__qty {
  font-size: .6em;
  color: $black7;
}
.promo-hint__fixed-hint {
  font-size: 14px;
  display: flex;
  align-items: center;
  .txt-free {
    display: inline-block;
  }
}
.promo-hint__free-shipping-hint {
  margin-left: .4em;
}
@media (max-width: $screen-sm) {
  .promo-hint {
    font-size: 1em;
    &.promo-hint--invalid {
      display: flex;
      align-items: center;
    }
  }
  .promo-hint__fixed-hint {
    display: block;
    margin-left: 0;
    margin-top: .2em;
  }
  .promo-hint__free-shipping-hint {
    margin-left: 0;
  }
  .promo-hint__fixed-hint,
  .promo-hint__free-shipping-hint {
    font-size: 12px;
  }
}
</style>
