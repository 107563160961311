import range from 'lodash.range'
import objToStyle from './objToStyle'

export default function markCssArr(lineCssArr, lineMarks) {

  return lineMarks.reduce((arr, mark) => {
    const { from, to, css } = mark
    range(from.ch, to.ch)
      .forEach(ch => {
        if (typeof arr[from.line][ch] !== 'undefined') {
          arr[from.line][ch] = Object.assign({}, arr[from.line][ch], css)
        }
      })
    return arr
  }, lineCssArr)
  .map(arr => {
    return arr.map(obj => objToStyle(obj))
  })
}
