// vue related
import Router from "vue-router";

// @superlanding
import comma from "@superlanding/commaseparate";
import extend from "@superlanding/extend";
import fromChineseDiscount from "@superlanding/fromchinesediscount";
import getChineseDiscount from "@superlanding/getchinesediscount";
import getDomPos from "@superlanding/getdompos";
import getScrollTop from "@superlanding/getscrolltop";
import isClient from "@superlanding/isclient";
import isServer from "@superlanding/isserver";
import mapDispatch from "@superlanding/mapdispatch";
import toInt from "@superlanding/toint";
import toPixel from "@superlanding/topixel";
import toPromise from "@superlanding/topromise";

// date-fns
import add from "date-fns/add";
import addMonths from "date-fns/addMonths";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import endOfDay from "date-fns/endOfDay";
import endOfMonth from "date-fns/endOfMonth";
import endOfWeek from "date-fns/endOfWeek";
import endOfYesterday from "date-fns/endOfYesterday";
import formatDistance from "date-fns/formatDistance";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import fromUnixTime from "date-fns/fromUnixTime";
import getDate from "date-fns/getDate";
import getMonth from "date-fns/getMonth";
import getUnixTime from "date-fns/getUnixTime";
import getYear from "date-fns/getYear";
import isFuture from "date-fns/isFuture";
import isToday from "date-fns/isToday";
import locale from "date-fns/locale/zh-TW";
import parse from "date-fns/parse";
import parseIso from "date-fns/parseISO";
import set from "date-fns/set";
import startOfDay from "date-fns/startOfDay";
import startOfMonth from "date-fns/startOfMonth";
import startOfWeek from "date-fns/startOfWeek";
import startOfYesterday from "date-fns/startOfYesterday";
import sub from "date-fns/sub";
import subMonths from "date-fns/subMonths";
import subWeeks from "date-fns/subWeeks";

// date-fns-tz
import { format as tzFormat } from "date-fns-tz";
import { toDate as tzToDate } from "date-fns-tz";

// lodash
import chunk from "lodash.chunk";
import cloneDeep from "lodash.clonedeep";
import compact from "lodash.compact";
import debounce from "lodash.debounce";
import difference from "lodash.difference";
import first from "lodash.first";
import flattenDeep from "lodash.flattendeep";
import get from "lodash.get";
import groupBy from "lodash.groupby";
import intersection from "lodash.intersection";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import isPlainObject from "lodash.isplainobject";
import keyBy from "lodash.keyby";
import last from "lodash.last";
import merge from "lodash.merge";
import noop from "lodash.noop";
import padStart from "lodash.padstart";
import pick from "lodash.pick";
import range from "lodash.range";
import setProp from "lodash.set";
import sortBy from "lodash.sortby";
import throttle from "lodash.throttle";
import uniq from "lodash.uniq";
import uniqBy from "lodash.uniqby";
import uniqueId from "lodash.uniqueid";
import _map from "lodash.map";

// redux related
import { combineEpics } from "redux-observable";
import { combineReducers } from "redux";
import { connect } from "redux-vuex";
import { createEpicMiddleware } from "redux-observable";
import { createStore, applyMiddleware } from "redux";
import { ofType } from "redux-observable";

// rxjs
import { Observable, Subject, defer, from, of, throwError, timer } from "rxjs";

// rxjs operators
import {
  catchError,
  concatAll,
  concatMap,
  debounceTime,
  delay,
  exhaustMap,
  finalize,
  map,
  mapTo,
  mergeMap,
  retryWhen,
  share,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";

// others
import axios from "axios";
import anchorMe from "anchorme";
import camelize from "camelize";
import filesize from "filesize";
import qs from "qs";
import raf from "raf";
import sanitize from "sanitize-html";
import sanitizeHtml from "sanitize-html";
import tinycolor from "tinycolor2";
import twZipcode from "twzipcode-data";
import url from "url";
import uuid from "uuid/v4";
import { Validator } from "simple-vue-validator";
import { parseDomain } from "parse-domain";
import { PulseLoader } from "@saeris/vue-spinners";

// others ( local )
import arrToObj from "./arrToObj";
import avg from "./avg";
import copyText from "./copyText";
import csvToArr from "./csvToArr";
import dateToBirthStr from "./dateToBirthStr";
import deleteQuery from "./deleteQuery";
import eq from "./eq";
import errToStr from "./errToStr";
import format from "./format";
import getAge from "./getAge";
import getFullUrl from "./getFullUrl";
import getSecondsArr from "./getSecondsArr";
import getWhatsappUrl from "./getWhatsappUrl";
import getXdeliveryTrackingData from "./getXdeliveryTrackingData";
import hasValue from "./hasValue";
import isArr from "./isArr";
import isDef from "./isDef";
import isFn from "./isFn";
import isNum from "./isNum";
import isPrimitive from "./isPrimitive";
import isStr from "./isStr";
import isUndef from "./isUndef";
import lazyload from "./lazyload";
import match from "./match";
import now from "./now";
import percent from "./percent";
import retryWithDelay from "./retryWithDelay";
import rm from "./rm";
import roundX from "./roundX";
import setQuery from "./setQuery";
import toMap from "./toMap";

import hyphenate from "./hyphenate";
import trim from "./trim";
import { isThisMonthBirthday, isNextMonthBirthday } from "./isBirthday";

const px = toPixel;

export {
  // vue related
  Router,
  // @superlanding
  comma,
  getChineseDiscount,
  fromChineseDiscount,
  getDomPos,
  getScrollTop,
  isClient,
  isServer,
  mapDispatch,
  toInt,
  toPixel,
  px,
  toPromise,
  // date-fns
  add,
  addMonths,
  eachDayOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYesterday,
  formatDistance,
  formatDistanceToNow,
  fromUnixTime,
  getDate,
  getMonth,
  getUnixTime,
  getYear,
  isFuture,
  isToday,
  locale,
  parse,
  parseIso,
  set,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYesterday,
  sub,
  subMonths,
  subWeeks,
  // date-fns-tz
  tzFormat,
  tzToDate,
  // lodash
  chunk,
  compact,
  cloneDeep,
  debounce,
  difference,
  first,
  delay,
  get,
  groupBy,
  isEmpty,
  isEqual,
  isPlainObject,
  keyBy,
  merge,
  noop,
  padStart,
  pick,
  range,
  last,
  sortBy,
  setProp,
  throttle,
  intersection,
  uniq,
  uniqBy,
  uniqueId,
  _map,
  // redux related
  applyMiddleware,
  combineEpics,
  combineReducers,
  connect,
  createEpicMiddleware,
  createStore,
  // rxjs
  Observable,
  Subject,
  defer,
  from,
  of,
  throwError,
  timer,
  // rxjs operators
  catchError,
  concatAll,
  concatMap,
  debounceTime,
  exhaustMap,
  finalize,
  map,
  mapTo,
  mergeMap,
  ofType,
  retryWhen,
  share,
  switchMap,
  take,
  takeUntil,
  tap,
  // others
  Validator,
  axios,
  anchorMe,
  camelize,
  filesize,
  parseDomain,
  PulseLoader,
  qs,
  raf,
  sanitize,
  sanitizeHtml,
  tinycolor,
  twZipcode,
  url,
  uuid,
  // others ( local )
  arrToObj,
  avg,
  copyText,
  csvToArr,
  dateToBirthStr,
  deleteQuery,
  eq,
  errToStr,
  extend,
  flattenDeep,
  format,
  getAge,
  getFullUrl,
  getSecondsArr,
  getWhatsappUrl,
  getXdeliveryTrackingData,
  hasValue,
  hyphenate,
  isArr,
  isDef,
  isFn,
  isNum,
  isPrimitive,
  isStr,
  isUndef,
  lazyload,
  match,
  now,
  percent,
  retryWithDelay,
  rm,
  roundX,
  setQuery,
  toMap,
  trim,
  isThisMonthBirthday,
  isNextMonthBirthday,
};
