<template>
  <div class="checkout-bar">
    <div class="checkout-bar__text">
      <div v-if="isFreeShipping" class="checkout-bar__free-shipping">免運費</div>
      <div v-if="isUnderFreeShipping" class="checkout-bar__shipping-fee">還差 <strong>{{ dollar(cart.freeShippingBalance) }}</strong> 免運費</div>
      <div class="checkout-bar__total">
        {{ cart.items.length }} 件商品，總金額 <strong data-cy="final-total-order-amount">{{ currency(total) }}</strong>
      </div>
    </div>
    <button class="btn btn-primary"
            data-cy="cart-submit"
            @click="$emit('checkout')">立即結帳</button>
  </div>
</template>

<script>
import currency from '@landing/landing-common/utils/currency'
import dollar from '@landing/landing-common/utils/dollar'

export default {
  name: 'CheckoutBar',
  props: {
    cart: Object
  },
  computed: {
    isFreeShipping() {
      return (this.cart.freeShippingBalance === 0)
    },
    isUnderFreeShipping() {
      return (this.cart.freeShippingBalance > 0)
    },
    total() {
      return this.cart.totalOrderAmountWithDiscount
    }
  },
  methods: {
    currency,
    dollar
  }
}
</script>

<style lang="scss" scoped>
.checkout-bar {
  z-index: 1;
  padding: 14px;
  background-color: $white1;
  border-top: 1px solid $gray3;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.no-flexbox .checkout-bar {
  display: block;
  @include clearfix;
  .checkout-bar__text {
    display: inline-block;
  }
  .btn-primary {
    float: right;
  }
}
.checkout-bar__free-shipping,
.checkout-bar__shipping-fee {
  margin-bottom: 4px;
  font-size: .875em;
}
.checkout-bar__free-shipping {
  color: $purple2;
}
.checkout-bar__shipping-fee {
  color: $black5;
  strong {
    color: $purple2;
  }
}
.checkout-bar__total {
  font-size: 1.125em;
  strong {
    font-size: 1.25em;
    color: $red4;
  }
}
</style>
