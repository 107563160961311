export const DISCOUNT_LV_TYPE_NONE = 'none'

export const DISCOUNT_LV_TYPE_AMOUNT = 'static_amount'

export const DISCOUNT_LV_TYPE_RATE = 'rate'

export const DISCOUNT_LV_TYPE_ITEMS = [
  { text: '無現金折扣', value: DISCOUNT_LV_TYPE_NONE },
  { text: '固定金額折扣', value: DISCOUNT_LV_TYPE_AMOUNT },
  { text: '打折', value: DISCOUNT_LV_TYPE_RATE }
]
