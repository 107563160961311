import toLineMarks from './renderMarks/toLineMarks'
import toLineCssArr from './renderMarks/toLineCssArr'
import markCssArr from './renderMarks/markCssArr'
import toTagMarks from './renderMarks/toTagMarks'
import renderPre from './renderMarks/renderPre'
import groupBy from 'lodash.groupby'
import sortBy from 'lodash.sortby'
import TagConverter from '@common/models/TagConverter'

export default function renderMarks(content = '', rawMarks = []) {

  if (content === '') {
    return ''
  }

  const lines = content.split('\n')

  // 歷史因素:
  // 舊的資料來的 marks 可能不在 lines 範圍
  // 所以必須檢查是不是在行數內出現
  const marks = rawMarks.filter(mark => {
    return (mark.from.line in lines) && (mark.to.line in lines)
  })

  /**
  * marks: [
  *   { css: 'color: #D0021B;', from: { ch: 4, line: 0 }, to: { ch: 6, line: 0 } },
  *   { css: 'color: #D0021B;', from: { ch: 3, line: 1 }, to: { ch: 4, line: 1 } }
  * ]
  * lines: ['這是一個測試用句子', '這是第二行']
  *                  ----                --
  * 轉換成
  * lineMarks: [
  *   { css: { color: '#D0021B' }, from: { ch: 4, line: 0 }, to: { ch: 6, line: 0 } },
  *   { css: { color: '#D0021B' }, from: { ch: 3, line: 1 }, to: { ch: 4, line: 1 } }
  * ]
  *
  * NOTE:
  * 1. string style 轉成 css object
  * 2. 多行標記轉成單行標記
  */
  const lineMarks = toLineMarks(marks, lines)

  /**
   * lineCssArr: [
   *   [{}, {}, {}, {}, { color: '#D0021B' }, { color: '#D0021B' }, {}, {}, {}, {}],
   *   [{}, {}, {}, { color: '#D0021B' }, {}]
   * ]
   */
  const lineCssArr = toLineCssArr(lines)

  /**
   * markedLineCssArr: [
   *   ['', '', '', '', 'color: #D0021B;', 'color: #D0021B;', '', '', '', ''],
   *   ['', '', '', 'color: #D0021B;', '']
   * ]
   **/
  const markedLineCssArr = markCssArr(lineCssArr, lineMarks)

  const htmlMarks = lineMarks.filter(m => m.tagRow)
    .map(m => {
      return {
        line: m.from.line,
        fromCh: m.from.ch,
        toCh: m.to.ch,
        tagRow: m.tagRow
      }
    })

  htmlMarks.forEach(m => {
    for (let ch = m.fromCh; ch < m.toCh; ch++) {
      // 這裡放一個底線表示他是自訂 HTML 元件
      // 底下在算完標記的分割後會濾掉
      markedLineCssArr[m.line][ch] = '_'
    }
  })

  /**
   * tagMarks: [
   *   { line: 0, css: '', fromCh: 0, toCh: 4 },
   *   { line: 0, css: 'color: #D0021B;', fromCh: 4, toCh: 6 },
   *   { line: 0, css: '', fromCh: 6, toCh: 10 },
   *   { line: 1, css: '', fromCh: 0, toCh: 3 },
   *   { line: 1, css: 'color: #D0021B;', fromCh: 3, toCh: 4 },
   *   { line: 1, 'css': '', 'fromCh': 4, 'toCh': 5 }
   * ]
   */
  const tagMarks = toTagMarks(markedLineCssArr)
    .filter(m => m.css !== '_')    // 濾掉

  const mergedMarks = sortBy(tagMarks.concat(htmlMarks), ['line', 'fromCh'])

  /**
   * tagMarkMap: {
   *   0: [
   *     { line: 0, css: '', fromCh: 0, toCh: 4 },
   *     { line: 0, css: 'color: #D0021B;', fromCh: 4, toCh: 6 },
   *     { line: 0, css: '', fromCh: 6, toCh: 10 },
   *   ],
   *   1: [
   *     { line: 1, css: 'color: #D0021B;', fromCh: 3, toCh: 4 },
   *     { line: 1, 'css': '', 'fromCh': 4, 'toCh': 5 }
   *   ]
   * }
   * */
  const tagMarkMap = groupBy(mergedMarks, 'line')

  return lines.map((line, i) => {

    const tags = tagMarkMap[i]

    if (! tags) {
      return renderPre(line)
    }

    let prevTag
    const lineHtml = tags.reduce((arr, tag) => {

      const { tagRow } = tag
      const styleAttr = tag.css ? ` style="${tag.css}"` : ''
      const start = prevTag ? prevTag.toCh + 1 : 0

      arr.push(line.slice(start, tag.fromCh))

      if (tagRow) {
        const html = TagConverter.tagRowToHtml(tagRow)
        arr.push(html)
      }
      else if (styleAttr) {
        arr.push(`<span${styleAttr}>${line.slice(tag.fromCh, tag.toCh)}</span>`)
      }
      else {
        arr.push(line.slice(tag.fromCh, tag.toCh))
      }
      prevTag = tag
      return arr
    }, [])
    .join('')

    return renderPre(lineHtml)
  })
  .join('')
}
