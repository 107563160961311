<template>
  <div v-if="item.imgRows.length === 1" />
  <div v-else class="product-desc-links">
    <button v-for="row in item.imgRows"
            :key="row.key"
            class="product-desc-links__btn"
            :class="btnClass(row)"
            @click.stop.prevent="$emit('click', row)"
            v-text="row.text" />
  </div>
</template>

<script>
export default {
  name: 'ProductDescLinks',
  props: {
    item: {
      type: Object,
      required: true
    },
    active: String
  },
  methods: {
    btnClass(row) {
      return (row.key === this.active) ? 'product-desc-links__btn--active' : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.product-desc-links {
  display: flex;
  flex-wrap: wrap;
  max-width: 450px;
}
.product-desc-links__btn {
  transition: .3s border-color, .3s color;
  border: 1px solid #ccc;
  background: transparent;
  text-align: left;
  color: #888;
  font-size: .875rem;
  margin-right: .5em;
  margin-bottom: .5em;
  &:last-child {
    margin-right: 0;
  }
  &:hover,
  &.product-desc-links__btn--active {
    color: #555;
    border-color: #555;
  }
}
</style>
