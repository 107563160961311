<template>
  <div class="list-element" data-cy="list-elem" :class="wrapperClassname">
    <ul class="list-element__ul" :class="classname">
      <li v-for="item in data.data.items" :key="item.id">
        <div class="list-element__html" v-html="getHtml(item)" />
      </li>
    </ul>
  </div>
</template>

<script>
import renderMarks from '@landing/common/utils/renderMarks'

export default {
  name: 'ListElement',
  props: {
    data: Object
  },
  computed: {
    classname() {
      return `list-type-${this.data.data.type}`
    },
    wrapperClassname() {
      return `list-element--${this.data.data.align}`
    }
  },
  methods: {
    getHtml(item) {
      return renderMarks(item.value, item.marks)
    }
  }
}
</script>

<style lang="scss">
$list-color: #333;

.list-type-triangle,
.list-type-square,
.list-type-dash {
  list-style-type: none;
  li {
    position: relative;
    display: block;
    white-space: nowrap;
    &:before {
      left: 0;
      position: absolute;
      display: block;
      content: '';
    }
  }
}

.list-type-triangle {
  li {
    padding-left: 20px;
    &:before {
      top: .5em;
    }
  }
}

.list-type-square {
  li {
    padding-left: 20px;
    &:before {
      top: .61em;
    }
  }
}

.list-type-dash {
  li {
    padding-left: 20px;
    &:before {
      top: .6em;
    }
  }
}

.list-type-numeric {
  list-style-type: decimal;
}

.list-type-triangle li:before {
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 10px;
  border-color: transparent transparent transparent $list-color;
}

.list-type-square li:before {
  display: inline-block;
  @include size(10px);
  background-color: $list-color;
}

.list-type-dash li:before {
  display: inline-block;
  width: 10px;
  height: 4px;
  background-color: $list-color;
}
</style>

<style lang="scss" scoped>
.list-element {
  display: flex;
  &.list-element--left {
    justify-content: flex-start;
  }
  &.list-element--center {
    justify-content: center;
  }
  &.list-element--right {
    justify-content: flex-end;
  }
}
.list-element__ul {
  padding: 15px $article-horizontal-padding;
  &.list-type-numeric {
    padding: 10px #{$article-horizontal-padding + 18px};
  }
  li {
    margin: .4em 0;
  }
}
.list-element__html {
  min-height: 22px;
  line-height: 1.9;
}
</style>
