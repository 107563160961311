<template>
  <transition name="top-toast">
    <div v-if="visible" class="top-toast">你的網路現在是離線狀態</div>
  </transition>
</template>

<script>
export default {
  name: 'TopToast',
  props: {
    visible: Boolean
  }
}
</script>

<style lang="scss" scoped>
.top-toast {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  z-index: 3;
  text-align: center;
  background-color: $black5;
  color: $white1;
  padding: 7px;
  width: 260px;
}
</style>
