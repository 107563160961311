<template>
  <div class="input-text">
    <input :id="id"
           :value="value"
           :placeholder="placeholder"
           :max-length="maxLength"
           :class="{ error: error }"
           :disabled="disabled"
           type="text"
           @input="$emit('input', $event.target.value)">
    <transition name="fade" appear>
      <fa-icon v-show="error" class="input-text__icon-exclamation-circle"
               icon="exclamation-circle" />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'InputText',
  props: {
    disabled: Boolean,
    error: Boolean,
    id: String,
    maxLength: String,
    placeholder: String,
    value: String
  }
}
</script>

<style lang="scss" scoped>
.input-text {
  position: relative;
}
.input-text__icon-exclamation-circle {
  font-size: 1.375em;
  color: $red4;
  display: block;
  position: absolute;
  right: 14px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}
input.error {
  padding-right: 50px;
}
</style>
