<template>
  <div class="image-slider-element" :style="style" @click="click">
    <image-slide v-for="(s, i) in slides"
                 :key="i"
                 class="image-slider-element__slide"
                 :style="slideStyle(i)"
                 :src="s.src" />
    <prev-btn class="image-slider-element__prev-btn" @click="prev" />
    <next-btn class="image-slider-element__next-btn" @click="next" />
    <dot-bar class="image-slider-element__dot-bar"
             :count="slideCount"
             :current="current"
             @click="setCurrentSlide" />
  </div>
</template>

<script>
import ImageSlide from '@common/components/ImageSliderElement/ImageSlide'
import PrevBtn from '@common/components/ImageSliderElement/PrevBtn'
import NextBtn from '@common/components/ImageSliderElement/NextBtn'
import DotBar from '@common/components/ImageSliderElement/DotBar'
import { px, roundX, throttle } from '@common/utils'
import domMixin from '@common/mixins/domMixin'
import elementMixin from '@common/mixins/elementMixin'

export default {
  name: 'ImageSliderElement',
  components: {
    ImageSlide,
    PrevBtn,
    NextBtn,
    DotBar
  },
  mixins: [domMixin, elementMixin],
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      current: 0,
      containerWidth: null
    }
  },
  computed: {
    maxImageHeight() {
      const { getSizeByContainerWidth, containerWidth } = this
      return this.slides.reduce((current, slide) => {
        const { height } = getSizeByContainerWidth(slide, containerWidth)
        return (height > current) ? height : current
      }, 0)
    },
    style() {
      if (this.containerWidth === null ) {
        return {}
      }
      return { height: px(this.maxImageHeight) }
    },
    slideCount() {
      return this.slides.length
    }
  },
  mounted() {
    this.setContainerWidth()
    this.on(window, 'resize', this.setContainerWidth)
    if (this.auto) {
      // 自動播放不可以干擾使用者操作
      // 例如影響捲動效能或是干擾換頁行為
      this.autoPlay()
      this.disableAutoPlayWhenUserInteracts()
      this.disableAutoPlayWhenScrolling()
    }
  },
  beforeDestroy() {
    if (this.auto) {
      this.stopAutoPlay()
    }
  },
  methods: {
    spy(name) {
      const fn = this[name]
      this[name] = () => {
        if (this._spyTimer) {
          clearTimeout(this._spyTimer)
        }
        this.stopAutoPlay()
        fn()
        this._spyTimer = setTimeout(() => this.autoPlay(), 3000)
      }
    },
    disableAutoPlayWhenUserInteracts() {
      const { $el } = this
      const hasTouchEvent = ('ontouchstart' in $el)
      if (hasTouchEvent) {
        this.spy('prev')
        this.spy('next')
        return
      }
      this.on($el, 'mouseenter', this.stopAutoPlay)
      this.on($el, 'mouseleave', this.autoPlay)
    },
    disableAutoPlayWhenScrolling() {
      this.on(window, 'scroll', throttle(() => {
        if (this._scrollTimer) {
          clearTimeout(this._scrollTimer)
          this._scrollTimer = null
        }
        this.stopAutoPlay()
        this._scrollTimer = setTimeout(() => this.autoPlay(), 500)
      }, 100))
    },
    autoPlay() {
      const self = this
      this.stopAutoPlay()
      ;(function loop() {
        self.timer = setTimeout(() => {
          self.next()
          loop()
        }, self.duration * 1000)
      })()
    },
    click(event) {
      const halfWidth = this.$el.offsetWidth / 2
      if (event.clientX < halfWidth) {
        this.prev()
      }
      else {
        this.next()
      }
    },
    stopAutoPlay() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
    },
    setCurrentSlide(i) {
      this.current = i
    },
    setContainerWidth() {
      this.containerWidth = this.$el.offsetWidth
    },
    slideStyle(i) {
      // 0 -> 0, 100, 200...
      // 1 -> -100, 0, 100
      // 2 -> -200, -100, 0...
      return {
        transform: `translateX(${(100 * i) - (100 * this.current)}%)`
      }
    },
    getSizeByContainerWidth(slide, containerWidth) {
      const { width, height } = slide
      if (width > containerWidth) {
        const nextWidth = containerWidth
        const nextHeight = roundX(containerWidth / (width / height))
        return { width: nextWidth, height: nextHeight }
      }
      return { width, height }
    },
    prev() {
      this.current -= 1
      if (this.current < 0) {
        this.current += this.slideCount
      }
    },
    next() {
      this.current += 1
      this.current = this.current % this.slideCount
    }
  },
  computedProps: [
    'slides',
    'auto',
    'duration'
  ]
}
</script>

<style lang="scss" scoped>
.image-slider-element {
  position: relative;
  overflow: hidden;
}
.image-slider-element__slide {
  transition: .3s transform;
}
.image-slider-element__prev-btn,
.image-slider-element__next-btn {
  transition: .3s opacity;
  opacity: .5;
  display: block;
  background: transparent;
  padding: 0;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 100%;
  &:hover {
    opacity: .8;
  }
}
.image-slider-element__prev-btn {
  left: 1rem;
}
.image-slider-element__next-btn {
  right: 1rem;
}
.image-slider-element__dot-bar {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 1rem;
}
</style>
