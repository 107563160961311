import app from './app'
import alert from './alert'
import cart from './cart'
import confirm from './confirm'
import order from './order'
import page from './page'
import toast from './toast'
import windowSize from './windowSize'
import { combineReducers } from '@landing/landing-desktop/utils'

export const CLEAR_STORE = '@landing-desktop/root/CLEAR_STORE'

export const clearStore = () => {
  return {
    type: CLEAR_STORE
  }
}

const reducer = combineReducers({
  app,
  alert,
  cart,
  confirm,
  order,
  page,
  toast,
  windowSize
})

const rootReducer = (state, action) => {
  if (action.type === CLEAR_STORE) {
    return reducer(undefined, action)
  }
  return reducer(state, action)
}

export default rootReducer
