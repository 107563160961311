<template>
  <a class="btn-element" data-cy="btn-elem" :href="data.data.url">
    {{ data.data.text }}
    <span v-if="data.data.desc" class="btn-element__desc" v-text="data.data.desc" />
  </a>
</template>

<script>
export default {
  name: 'BtnElement',
  props: {
    data: Object
  }
}
</script>

<style lang="scss" scoped>
.btn-element {
  width: 676px;
  max-width: calc(100% - #{$article-horizontal-padding});
  background: transparent;
  border: 1px solid rgba(51, 51, 51, .35);
  padding: 1rem;
  font-size: 2rem;
  margin: 1rem auto;
  display: block;
  text-align: center;
  color: #333;
}
.btn-element__desc {
  display: block;
  font-size: 1rem;
  margin-top: .2rem;
}
</style>
