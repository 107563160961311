<template>
  <del class="deleted-price">
    <slot />
  </del>
</template>

<script>
export default {
  name: 'DeletedPrice'
}
</script>

<style lang="scss" scoped>
.deleted-price {
  margin: 0 4px;
  text-decoration: none;
  position: relative;
  &:after {
    content: '';
    background-color: $red1;
    height: 2px;
    width: 110%;
    position: absolute;
    top: 50%;
    left: -5%;
    transform: scaleY(.8);
  }
}
</style>
