import hyphenate from '@landing/common/utils/hyphenate'

export default function objToStyle(obj = {}) {
  return Object.keys(obj)
    .map(key => {
      return `${hyphenate(key)}: ${obj[key]};`
    })
    .join('')
}

