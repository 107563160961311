<template>
  <app-backdrop :is-visible="isVisible" @click="handleClick">
    <transition name="pop" appear>
      <div v-if="isVisible" class="app-alert" @click="handleClick">
        <div class="app-alert__modal" @click.stop>
          <close-btn class="app-alert__close-btn"
                     @click="$emit('close')" />
          <div class="app-alert__title">{{ data.title }}</div>
          <div v-if="Array.isArray(data.message)" class="app-alert__message">
            <div v-for="(message, i) in data.message" :key="i">{{ message }}</div>
          </div>
          <div v-else class="app-alert__message">{{ data.message }}</div>
          <button ref="primaryBtn"
                  class="btn btn-secondary"
                  @click="handleClick">
            {{ data.btnText || '確定' }}
          </button>
        </div>
      </div>
    </transition>
  </app-backdrop>
</template>

<script>
import domMixin from '@landing/common/mixins/domMixin'
import getScrollMixin from '@landing/common/mixins/bodyScrollMixin'
import keyMixin from '@landing/landing-desktop/mixins/keyMixin'
import AppBackdrop from './AppBackdrop'
import CloseBtn from './CloseBtn'

const bodyScrollMixin  = getScrollMixin('isVisible')

export default {
  name: 'AppAlert',
  components: {
    AppBackdrop,
    CloseBtn
  },
  mixins: [domMixin, bodyScrollMixin, keyMixin],
  props: {
    isVisible: Boolean,
    data: Object
  },
  computed: {
    title() {
      const { data } = this
      return data ? data.title : ''
    }
  },
  watch: {
    isVisible(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.primaryBtn && this.$refs.primaryBtn.focus()
        })
      }
    }
  },
  methods: {
    handleClick() {
      this.$emit('close')
      const { cb } = this.data
      if (typeof cb === 'function') {
        this.$nextTick(() => cb())
      }
    },
    handleKeyUp(key) {
      if (! this.isVisible) {
        return
      }
      if (key === 'Enter') {
        this.handleClick()
      }
      if (key === 'Escape') {
        this.handleClick()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.no-flexbox .app-alert {
  @include fixed-center;
  text-align: center;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  .app-alert__modal {
    text-align: left;
    display: inline-block;
    vertical-align: middle;
  }
}
.app-alert {
  z-index: 1;
  display: flex;
  justify-content: center;
  @include fixed-center;
}
.app-alert__modal {
  @include clearfix;
  align-self: center;
  position: relative;
  padding: 14px 20px;
  background-color: $white1;
  border-radius: 4px;
  width: 400px;
}
.app-alert__backdrop {
  background-color: $black11;
  @include fixed-center;
  z-index: 1;
}
.app-alert__close-btn {
  @include size(40px);
  position: absolute;
  right: 0;
  top: 0;
}
.app-alert__title {
  padding-right: 34px;
  font-size: 1.3em;
  font-weight: 400;
}
.app-alert__message {
  white-space: pre-wrap;
  word-break: break-all;
  margin: 14px 0 30px;
}
.btn-secondary {
  float: right;
}
</style>
