<template>
  <transition name="fade" appear>
    <div v-if="message" class="cart-tooltip">{{ message }}</div>
  </transition>
</template>

<script>
export default {
  name: 'CartTooltip',
  props: {
    message: String
  }
}
</script>

<style lang="scss" scoped>
.cart-tooltip {
  background-color: $black5;
  color: $white1;
  position: absolute;
  top: 8px;
  right: 62px;
  margin-top: auto;
  margin-bottom: auto;
  white-space: pre;
  border-radius: 4px;
  padding: 5px 10px 4px;
  &:before {
    content: '';
    display: block;
    @include size(10px);
    background-color: $black5;
    position: absolute;
    right: -5px;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    transform: rotate(45deg);
  }
}
</style>
