import toInt from '@superlanding/toint'

export default function parsePaddingStr(str) {

  const oneValuePattern = /^(\d+px|0)$/

  if (oneValuePattern.test(str)) {
    const [, value] = str.match(oneValuePattern)
    const padding = toInt(value)
    return { top: padding, right: padding, bottom: padding, left: padding }
  }

  const twoValuesPattern = /^(\d+px|0)\s+(\d+px|0)$/

  if (twoValuesPattern.test(str)){
    const [, valueY, valueX] = str.match(twoValuesPattern)
    const x = toInt(valueX)
    const y = toInt(valueY)
    return { top: y, right: x, bottom: y, left: x }
  }

  const threeValuesPattern = /^(\d+px|0)\s+(\d+px|0)\s+(\d+px|0)$/

  if (threeValuesPattern.test(str)){
    const [, valueTop, valueX, valueBottom] = str.match(threeValuesPattern)
    const top = toInt(valueTop)
    const x = toInt(valueX)
    const bottom = toInt(valueBottom)
    return { top, right: x, bottom, left: x }
  }

  const fourValuesPattern = /^(\d+px|0)\s+(\d+px|0)\s+(\d+px|0)\s+(\d+px|0)$/

  if (fourValuesPattern.test(str)){
    const [, valueTop, valueRight, valueBottom, valueLeft] = str.match(fourValuesPattern)
    const top = parseInt(valueTop)
    const right = parseInt(valueRight)
    const bottom = parseInt(valueBottom)
    const left = parseInt(valueLeft)
    return { top, right, bottom, left }
  }

  return null
}
