/* global dataLayer, gtag */
// https://developers.google.com/gtagjs/devguide/routing
// https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce
// https://support.google.com/google-ads/answer/6095821?hl=zh-Hant

import TrackerBase from './TrackerBase'
import Gtag from './Gtag'

class TrackerAds extends TrackerBase {

  constructor({ page }) {
    super()
    this.page = page
  }

  init() {
    const { gtagAds } = this.page
    if (! gtagAds) {
      return this
    }

    const [id, label] = gtagAds.split('/')
    this.conversion = { id, label }
    this._loadScript()
    this.enabled = true
    return this
  }

  pageView() {
  }

  viewContent() {
  }

  addToCart() {
  }

  checkout() {
  }

  purchase({ orderId, totalOrderAmount, currency, orderEmail, orderMobile }) {
    // Beta: Set up enhanced conversions manually with the global site tag
    // https://support.google.com/google-ads/answer/9888145
    window.enhanced_conversion_data = {}
    if (orderEmail) {
      window.enhanced_conversion_data.email = orderEmail
    }
    if (orderMobile) {
      window.enhanced_conversion_data.phone_number = orderMobile
    }
    gtag('event', 'conversion', {
      'send_to': `${this.conversion.id}/${this.conversion.label}`,
      'value': totalOrderAmount,
      'currency': currency,
      'transaction_id': orderId
    })
    delete window.enhanced_conversion_data
  }

  _loadScript() {
    new Gtag(this.conversion.id).config()
  }
}

export default TrackerAds
