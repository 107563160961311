<template>
  <div class="product-list-item card"
       data-cy="product-item"
       :class="containerClass">
    <template v-if="mode === 'grid'">
      <app-image class="product-list-item__img"
                 :src="data.image"
                 :alt="data.title"
                 lazy />
    </template>
    <template v-else-if="(mode === 'list') && data.image">
      <app-image class="product-list-item__img"
                 :src="data.image"
                 :alt="data.title"
                 lazy />
    </template>

    <div class="product-list-item__content">
      <div class="product-list-item__title">{{ data.title }}</div>
      <preorder-info v-if="data.isPreorder"
                     class="product-list-item__preorder-info"
                     :item="data"
                     :mode="mode" />
      <div class="product-list-item__sub-content">
        <div class="product-list-item__product-info">
          <div v-if="data.strengthening"
               class="txt-strengthening">
            {{ data.strengthening }}
          </div>
          <div v-if="data.additional" class="txt-additional" v-text="data.additional" />
        </div>

        <div class="product-list-item__price-info">
          <div class="product-list-item__money">
            <div v-if="isOriginalPriceVisible"
                 class="product-list-item__original-price">
              原價 <deleted-price>{{ dollar(data.originalPrice) }}</deleted-price>
            </div>
            <div v-if="textOnSale" class="product-list-item__price">{{ textOnSale }}</div>
          </div>
          <button v-if="isAdditionalPurchase" ref="btn"
                  class="btn-secondary fw"
                  @click="$emit('secondary-btn-click', data)">
            加購
          </button>
          <button v-else
                  ref="btn"
                  class="btn-primary fw"
                  :disabled="outOfStock"
                  @click="$emit('primary-btn-click', data)">
            {{ buttonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PreorderInfo from '@landing/landing-common/components/PreorderInfo'
import dollar from '@landing/landing-common/utils/dollar'
import { comma } from '@landing/landing-common/utils'
import AppImage from './AppImage'
import DeletedPrice from './DeletedPrice'
import discountText from '@landing-desktop/utils/discountText'
import currency from '@common/utils/currency'

export default {
  name: 'ProductListItem',
  components: {
    AppImage,
    DeletedPrice,
    PreorderInfo
  },
  props: {
    data: Object,
    mode: {
      type: String,
      default: 'list'
    },
    isAdditionalPurchase: Boolean
  },
  computed: {
    isOriginalPriceVisible() {
      if (this.isAdditionalPurchase) {
        return false
      }
      const { originalPrice } = this.data
      return Number.isInteger(originalPrice) && (originalPrice > 0)
    },
    containerClass() {
      if (this.data.image) {
        return `product-list-item--${this.mode}-mode`
      }
      return `product-list-item--${this.mode}-mode product-list-item--no-image`
    },
    textOnSale() {
      const { textOnSale, price, promoType } = this.data
      if (promoType === 'discounts') {
        return discountText(this.data.promoDetails.rate)
      }
      if (this.isAdditionalPurchase) {
        return `加購價: ${dollar(price)}`
      }
      if (textOnSale) {
        return textOnSale.replace(/%s/g, comma(price))
          .replace(/%c/g, currency(this.$consts.currency))
      }
      return dollar(price)
    },
    outOfStock() {
      return (this.data.status === 'out_of_stock')
    },
    buttonText() {
      if (this.outOfStock) {
        return '缺貨中'
      }
      return this.data.textButton || '點此選購'
    }
  },
  methods: {
    dollar
  }
}
</script>

<style lang="scss">
.product-list-item__preorder-info.preorder-info {
  margin-top: .7em;
  margin-bottom: .4em;
}
</style>

<style lang="scss" scoped>
.product-list-item {
  overflow: hidden;
  background-color: $white1;
}
.no-flexbox .product-list-item.product-list-item--list-mode {
  display: block;
  white-space: nowrap;
  @include clearfix;
  .product-list-item__img,
  .product-list-item__content,
  .product-list-item__product-info,
  .product-list-item__price-info {
    float: left;
  }
  .product-list-item__product-info {
    width: 200px;
  }

  &.product-list-item--no-image {
    .product-list-item__content {
      float: none;
    }
    .product-list-item__sub-content {
      white-space: nowrap;
      @include clearfix;
    }
    .product-list-item__product-info {
    }
    .product-list-item__price-info {
      float: left;
    }
    .product-list-item__money {
      width: 200px;
    }
    .product-list-item__money,
    .btn-primary,
    .btn-secondary {
      display: inline-block;
      vertical-align: top;
    }
  }
}
.product-list-item.product-list-item--list-mode {
  display: flex;
  align-items: flex-start;

  .product-list-item__img {
    max-width: 210px;
  }
  @media (max-width: $screen-sm) {
    .product-list-item__img {
      max-width: 180px;
    }
  }
  @media (max-width: 560px) {
    .product-list-item__img {
      max-width: 100px;
    }
  }

  .product-list-item__content {
    flex-grow: 1;
    padding: 10px 14px 14px;
  }
  .product-list-item__sub-content {
    min-height: 190px;
    display: flex;
    justify-content: space-between;
    flex: 1;
    border-bottom: 1px solid $gray6;
    @media (max-width: $screen-sm) {
      .btn-primary,
      .btn-secondary {
        width: 100%;
        right: 0;
      }
    }
    @media (max-width: $screen-md) {
      display: block;
      .btn-primary,
      .btn-secondary {
        width: 210px;
        right: 0;
      }
    }
    padding: 4px 16px 20px 0;
  }
  .product-list-item__money {
    margin-top: 0;
  }
  .product-list-item__product-info {
    padding-right: 55px;
    @media (max-width: $screen-md) {
      padding-bottom: 10px;
    }
  }
  .product-list-item__price-info {
    min-height: 140px;
    position: relative;
    min-width: 210px;
    .btn-primary,
    .btn-secondary {
      position: absolute;
      bottom: 0;
    }
  }
  &.product-list-item--no-image {
    .product-list-item__content {
      padding-left: 0;
      padding-bottom: 0;
    }
    .product-list-item__sub-content {
      min-height: initial;
    }
    .product-list-item__product-info {
      width: 210px;
      padding-right: 0;
    }
    .product-list-item__price-info {
      min-height: auto;
      display: flex;
      flex: 1;
      .product-list-item__money {
        margin: 0;
        flex: 1;
      }
      .btn-primary,
      .btn-secondary {
        max-width: 210px;
        position: static;
        align-self: center;
      }
    }
  }
}
.product-list-item.product-list-item--grid-mode {
  display: flex;
  flex-direction: column;
  .product-list-item__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  }
  .product-list-item__sub-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .product-list-item__price-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .product-list-item__img {
    margin: 0 auto;
  }
  .product-list-item__title {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .product-list-item__badge {
    margin-top: .3em;
    margin-bottom: .4em;
  }
}
.product-list-item__img {
  display: block;
  width: 100%;
  height: auto;
}
.product-list-item__title {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 1.2em;
}
.product-list-item__badge {
  margin-right: .4em;
  font-size: .8em;
}
.txt-preorder {
  font-size: .8rem;
  line-height: 1.4;
}
.txt-strengthening {
  font-size: 1em;
  line-height: 1.6em;
}
.txt-additional {
  white-space: pre-wrap;
  font-size: .675em;
  line-height: 1.6em;
}
.product-list-item__original-price {
  color: $black5;
  font-size: 0.857em;
}
.product-list-item__price {
  color: $red4;
  font-weight: 500;
  font-size: 1.2em;
}
.product-list-item__money {
  margin-top: 9px;
  margin-bottom: 20px;
}
</style>
