/* global dataLayer */
import TrackerBase from './TrackerBase'

class TrackerGtm extends TrackerBase {

  constructor({ page }) {
    super()
    this.page = page
  }

  init() {
    const { gtm } = this.page
    if (! gtm) {
      return this
    }
    this._loadScript(gtm)
    this.enabled = true
    return this
  }

  pageView() {
    this._track('PageView', this._buildParams())
  }

  viewContent() {
    this._track('ViewContent', this._buildParams())
  }

  addToCart({ cartId, productId, title, price, qty, totalAmount, currency }) {
    this._track('AddToCart', this._buildParams({
      cartId,
      product: {
        productId,
        title,
        price,
        qty,
        totalAmount
      },
      currency
    }))
  }

  checkout({ totalOrderAmount, step }) {
    this._track('Checkout', this._buildParams({
      totalOrderAmount,
      step
    }))
  }

  purchase({ orderId, totalOrderAmount, currency, page, order }) {
    this._track('Purchase', this._buildParams({
      orderId,
      totalOrderAmount,
      currency
    }))
  }

  _buildParams(params) {
    const { id, idPrefix, title, aliasName } = this.page
    return Object.assign({
      pageId: id,
      pagePrefix: idPrefix,
      pageTitle: title,
      pageAlias: aliasName,
    }, params)
  }

  _track(event, params) {
    dataLayer.push({ ...params, event })
  }

  _loadScript(code) {
    // from https://developers.google.com/tag-manager/quickstart
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({ 'gtm.start':
    new Date().getTime(),event:'gtm.js' });var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?`&l=${l}`:'';j.async=true;j.src=
    `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', code);
  }
}

export default TrackerGtm
