/**
 * adz624 6:53 PM
 * 可以幫我 review 這個嗎？這個 PR 是解決「計算同時使用 coupon code + 滿額優惠的邏輯」
 * 1. <後端> 應該先算 coupon 後的金額，再去判斷有無符合 discount_level (之前都是以原價格做計算，廠商反應不要這樣)
 * 2. <後端> 應該增加一個 totalOrderAmountWithCoupon 給前端顯示差多少錢？應該由這個數字來判斷
 * P.S 若 coupon 滿 1000 折 100, 滿額優惠也是滿 1000 折 100，
 * 滿額優惠是依照扣完 coupon 後做計算，所以需要有以上的數值「totalOrderAmountWithCoupon」
 *
 * adz624 7:03 PM
 * 對了，總之，我現在多一個欄位有辦法區分 coupon 的折扣＆滿額的折扣，所以可以幫我分開顯示嗎？
 * totalOrderAmount -> 原價含運
 * totalOrderAmountWithCoupon -> 原價含運 - Coupon Code
 * totalOrderAmountWithDiscount = totalOrderAmountWithCoupon - 滿額折扣
 */
class Price {

  static getDiscount(item) {
    return item.totalOrderAmountWithDiscount - item.totalOrderAmount
  }

  static getCouponDiscount(item) {
    return item.totalOrderAmountWithCoupon - item.totalOrderAmount
  }

  // 滿額優惠的折扣
  static getPaymentDiscount(item) {
    return  item.totalOrderAmountWithDiscount - item.totalOrderAmountWithCoupon
  }
}

export default Price
