import extend from '@superlanding/extend'
import isStr from '@common/utils/isStr'

export default function deleteQuery(props) {

  const query = extend({}, this.$route.query)

  if (isStr(props)) {
    delete query[props]
    return this.$router.replace({ query })
  }

  props.forEach(prop => {
    delete query[prop]
  })
  this.$router.replace({ query })
}
