import IntlAddressBase from './Base'

class HongKongAddress extends IntlAddressBase {

  constructor(props) {
    super(props)
  }
}

export default HongKongAddress
