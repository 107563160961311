import '@superlanding/promisefinallypolyfill'
import 'intersection-observer'
import 'intl'
import 'intl/locale-data/jsonp/zh-Hant-TW'
import 'number.isinteger'
import 'polyfills/classList'
import 'array-flat-polyfill'
import './scripts/modernizr'
import './scripts/performance-now-polyfill'
import './scripts/request-animation-frame-polyfill'
import './scripts/debug'

import Vue from './consts/vue'
import renderVue from '../renderVue'
import store from './redux/store'
import { createRouter } from './router'
import { connect } from './utils'

import './styles/main.scss'
import './plugins/axios'
import './plugins/fontawesome'
import './plugins/portalVue'
import './plugins/simpleVueValidator'

/* client side only ------------------- */
import 'requestidlecallback'
import './plugins/vueObserveVisibility'
import './plugins/vuejsDatepicker'
import './plugins/sanitizeHtml'
/* ------------------------------------ */

import './mixins/globalMixin'
import App from './pages/App'

connect({ Vue, store })

renderVue('landing-desktop/App', Vue.extend(App), { createRouter, Vue })
