<template>
  <popup-menu :is-visible="hasItem" @backdrop-click="close" @close="close">
    <div v-if="hasItem" class="order-item-menu-promo-product">
      <app-image v-if="item.hasImg"
                 class="order-item-menu-promo-product__img"
                 :class="{ 'order-item-menu-promo-product__img--error': hasImageError }"
                 :src="productImgSrc"
                 :alt="item.title"
                 @error="hasImageError = true" />
      <form class="order-item-menu-promo-product__form form" @submit.prevent="handleSubmit">
        <div class="order-item-menu-promo-product__title"
             v-text="item.title" />

        <preorder-info v-if="item.isPreorder"
                       class="order-item-menu-promo-product__preorder-info"
                       :item="item"
                       mode="list" />

        <product-desc-links class="order-item-menu-promo-product__product-desc-links"
                            :item="item"
                            :active="activeProductKey"
                            @click="setProductImgRow" />

        <div v-if="item.strengthening"
             class="txt-strengthening"
             v-text="item.strengthening" />
        <div v-if="item.additional"
             class="txt-additional" v-text="item.additional" />
        <label v-if="item.hasSpec">
          <span>選擇規格</span>
          <select v-model="selectedSpecId" class="select" data-cy="spec-select">
            <option v-for="o in item.specOptions"
                    :key="o.value"
                    :value="o.value">{{ o.text }}</option>
          </select>
        </label>
        <label>
          <span>選擇數量</span>
          <select v-model="selectedQuantity" class="select" data-cy="qty-select">
            <option v-for="o in item.getQuantityOptionsByNum(max)"
                    :key="o.value"
                    :value="o.value">{{ o.text }}</option>
          </select>
        </label>
        <button ref="primaryBtn" class="btn-primary fw" type="submit">新增到組合</button>
      </form>
      <close-btn class="order-item-menu-promo-product__close-btn"
                 @click="close" />
    </div>
  </popup-menu>
</template>

<script>
import alertMixin from '@landing/landing-desktop/mixins/alertMixin'
import domMixin from '@landing/common/mixins/domMixin'
import keyMixin from '@landing/landing-desktop/mixins/keyMixin'
import { uuid } from '@landing/landing-desktop/utils'
import AppImage from './AppImage'
import CloseBtn from './CloseBtn'
import PopupMenu from './PopupMenu'
import PreorderInfo from '@landing/landing-common/components/PreorderInfo'
import ProductDescLinks from '@landing-common/components/ProductDescLinks'
import productImgMixin from '@landing-common/mixins/productImgMixin'

export default {
  name: 'OrderItemMenuPromoProduct',
  components: {
    AppImage,
    CloseBtn,
    PopupMenu,
    PreorderInfo,
    ProductDescLinks
  },
  mixins: [domMixin, keyMixin, alertMixin, productImgMixin],
  props: {
    item: Object,
    max: Number
  },
  data() {
    return {
      selectedSpecId: null,
      selectedQuantity: null,
      hasImageError: false
    }
  },
  computed: {
    hasItem() {
      return Boolean(this.item)
    }
  },
  watch: {
    item(value) {
      if (value) {
        this.initData(value)
        this.$nextTick(() => {
          this.$refs.primaryBtn && this.$refs.primaryBtn.focus()
        })
      }
    }
  },
  methods: {
    handleKeyUp(key) {
      if (! this.hasItem) {
        return
      }
      if (key === 'Enter') {
        return this.handleSubmit()
      }
      if (key === 'Escape') {
        return this.close()
      }
    },
    initData(item) {
      this.selectedSpecId = item.defaultSelectedSpecId,
      this.selectedQuantity = item.defaultSelectedQuantity
    },
    hasOutOfStockSpec(item, specId) {
      const specOption = item.specOptions.find(o => o.value === specId)
      if (specOption) {
        return (specOption.status === 'out_of_stock')
      }
      return false
    },
    handleSubmit() {
      const { item } = this

      if (this.hasOutOfStockSpec(item, this.selectedSpecId)) {
        return this.showAlert({ message: '此規格缺貨中' })
      }

      const data = {
        id: item.id,
        title: item.title,
        unitName: item.unitName,
        price: item.price,
        specId: this.selectedSpecId,
        qty: this.selectedQuantity,
        uuid: uuid()
      }
      if (item.hasSpec) {
        data.spec = item.specs.find(row => row.id === this.selectedSpecId).title
      }
      if (item.image) {
        data.image = item.image
      }
      this.$emit('submit', data)
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.order-item-menu-promo-product {
  border-radius: 4px;
  background-color: $white1;
  display: flex;
  align-items: center;
  position: relative;
}
.no-flexbox .order-item-menu-promo-product {
  .order-item-menu-promo-product__img,
  .order-item-menu-promo-product__form {
    display: inline-block;
    vertical-align: top;
  }
}
.order-item-menu-promo-product__close-btn {
  position: absolute;
  top: 0;
  right: 0;
}
.order-item-menu-promo-product__img {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: block;
  max-width: 420px;
  height: auto;
  &.order-item-menu-promo-product__img--error {
    @include size(420px);
  }
}
.order-item-menu-promo-product__form {
  max-width: 420px;
  padding: 24px;
  label {
    margin-top: 14px;
    margin-bottom: 14px;
  }
}
.btn-primary {
  margin-top: 48px;
}
.order-item-menu-promo-product__title {
  font-weight: 400;
  font-size: 1.2em;
  margin-bottom: 4px;
  margin-right: 24px;
}
.order-item-menu-promo-product__label {
  color: $blue6;
}
.txt-strengthening {
  font-size: 1rem;
  margin-top: .6rem;
}
.txt-additional {
  font-size: .875rem;
  margin-top: .4rem;
}
.order-item-menu-promo-product__preorder-info {
  margin-top: .5em;
}
.order-item-menu-promo-product__product-desc-links {
  margin-top: 1em;
}
</style>
