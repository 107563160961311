import { get, mergeMap, throwError, timer } from '@landing/common/utils'

const getErrorStatus = res => get(res, 'response.status')

export default function retryStrategy(options = {}) {

  const maxRetryAttempts = options.maxRetryAttempts || 3
  const scalingDuration = options.scalingDuration || 1000
  const excludedStatusCodes = options.excludedStatusCodes || [400, 401, 500, 502]

  return attempts => {
    return attempts.pipe(
      mergeMap((err, i) => {
        const retryAttempt = i + 1;
        const status = getErrorStatus(err)
        if (excludedStatusCodes.find(code => code === status)) {
          return throwError(err)
        }
        if (retryAttempt > maxRetryAttempts) {
          return throwError(err)
        }
        console.info(
          `Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`
        )
        return timer(retryAttempt * scalingDuration)
      })
    )
  }
}
