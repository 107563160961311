<template>
  <div class="cart-modal-list-item-image">
    <app-image v-if="image" class="cart-modal-list-item-image__img"
               :src="image" :alt="item.title" />
  </div>
</template>

<script>
import AppImage from './AppImage'

export default {
  name: 'CartModalListItemImage',
  components: {
    AppImage
  },
  props: {
    item: Object
  },
  computed: {
    image() {
      const { source } = this.item
      return source && source.image
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-modal-list-item-image {
  @include size(70px);
}
.cart-modal-list-item-image__img {
  @include size(70px);
}
</style>
