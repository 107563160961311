import ComponentError from '@landing/common/components/ComponentError'
import ComponentLoading from '@landing/common/components/ComponentLoading'

export default function lazyload(fn) {
  return () => ({
    component: fn(),
    loading: ComponentLoading,
    error: ComponentError
  })
}
