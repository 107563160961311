import createEpic from '../createEpic'
import http from '@landing/landing-desktop/plugins/http'
import { GET_PAGE, GET_PAGE_SUCCESS,
  GET_PAGE_FAIL } from '../reducers/page'
import {
  catchError,
  from,
  map,
  of,
  takeWhile,
  tap,
  exhaustMap
} from '@landing/landing-desktop/utils'

let isGettingPage = false

const getPage = () => [
  GET_PAGE,
  takeWhile(() => ! isGettingPage),
  tap(() => isGettingPage = true),
  exhaustMap(action => {
    return from(http.get(`/${action.alias}.json`))
      .pipe(
        map(res => ({
          type: GET_PAGE_SUCCESS,
          payload: res.data,
        })),
        catchError(err => of({ type: GET_PAGE_FAIL, payload: err }))
      )
  }),
  tap(() => isGettingPage = false)
]

export default [
  createEpic(getPage)
]
