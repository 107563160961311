<template>
  <div class="heading-element" data-cy="heading-elem">
    <h2 :class="getAlignClassName(data.data.align)" v-html="html" />
  </div>
</template>

<script>
import alignMixin from '@landing/common/mixins/alignMixin'
import renderMarks from '@landing/common/utils/renderMarks'

export default {
  name: 'HeadingElement',
  mixins: [alignMixin],
  props: {
    data: Object
  },
  computed: {
    html() {
      const { title, marks } = this.data.data
      return renderMarks(title, marks)
    }
  }
}
</script>

<style lang="scss" scoped>
.heading-element {
  overflow: auto;
  text-align: center;
  padding: 15px $article-horizontal-padding;
  h2 {
    font-family: noto sans tc,sans-serif;
    font-weight: 600;
    color: #111;
    font-size: 1.6rem;
    line-height: 1.4;
    margin: 0;
  }
}
</style>
