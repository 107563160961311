<template>
  <transition-group name="fade" tag="ul" class="promo-list" appear>
    <order-item-menu-user-promo-list-item v-for="item in items"
                                          :key="item.uuid"
                                          :item="item"
                                          @plus="handlePlus"
                                          @minus="handleMinus"
                                          @remove="handleRemove" />
  </transition-group>
</template>

<script>
import OrderItemMenuUserPromoListItem from './OrderItemMenuUserPromoListItem'

export default {
  name: 'OrderItemMenuUserPromoList',
  components: {
    OrderItemMenuUserPromoListItem
  },
  props: {
    items: Array
  },
  methods: {
    handleRemove(item) {
      this.$emit('remove', item)
    },
    handlePlus(item) {
      this.$emit('plus', item)
    },
    handleMinus(item) {
      this.$emit('minus', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.promo-list {
  overflow-y: auto;
  display: block;
  font-size: .875em;
  color: $black5;
}
</style>
