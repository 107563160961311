import range from 'lodash.range'

// 如果是誇行的 mark，把它拆成多個單行
export default function splitMark(mark, lines) {

  const { from, to, css, tagRow } = mark
  const fromLine = from.line
  const toLine = to.line

  return range(fromLine, toLine + 1)
    .map(i => {
      const lineLength = lines[i].length
      if (i === fromLine) {
        const to = {
          line: i,
          ch: lineLength
        }
        return { from, to, css, tagRow }
      }
      if (i === toLine) {
        const from = {
          line: i,
          ch: 0
        }
        return { from, to, css, tagRow }
      }
      return {
        from: { line: i, ch: 0 },
        to: { line: i, ch: lineLength },
        css,
        tagRow
      }
    })
}

