<template>
  <svg width="149px" height="176px" viewBox="0 0 149 176">
    <!-- Generator: Sketch 55.2 (78181) - https://sketchapp.com -->
    <title>icon-cart</title>
    <desc>Created with Sketch.</desc>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-90.000000, -247.000000)">
        <g transform="translate(98.000000, 252.000000)">
          <g transform="translate(0.000000, 54.000000)">
            <polyline stroke="#CACACA" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" points="0 0 21.5644531 0 39.6738281 84.5820313 109.285156 84.5820313" />
            <polyline stroke="#CACACA" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" points="125 9.94140625 113.064453 61.0820313 34.3144531 61.0820313" />
            <circle fill="#CACACA" cx="39.2851562" cy="103.082031" r="13.5" />
            <circle fill="#CACACA" cx="109.285156" cy="103.082031" r="13.5" />
          </g>
          <path d="M39.2851562,3.63436675 L49.3144531,29.5" stroke="#CACACA" stroke-width="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M86.6572266,0 L86.6572266,24.5" stroke="#CACACA" stroke-width="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M136,12.25 L120,28.5" stroke="#CACACA" stroke-width="10" stroke-linecap="round" stroke-linejoin="round" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'EmptyCartIconSvg'
}
</script>
