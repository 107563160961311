import locale from '@landing/common/consts/locale'
import unixTimeToCurrentDate from '@landing/common/utils/unixTimeToCurrentDate'
import format from '@landing/common/utils/format'
import fromUnixTime from 'date-fns/fromUnixTime'
import getDate from 'date-fns/getDate'
import getMonth from 'date-fns/getMonth'
import getUnixTime from 'date-fns/getUnixTime'
import getYear from 'date-fns/getYear'
import set from 'date-fns/set'
import startOfWeek from 'date-fns/startOfWeek'
import uuid from 'uuid/v4'

const defaultProps = {
  weekday: 0,
  work: false,
  timeRanges: []
}

const toTimeFormat = timestamp => {
  const date = fromUnixTime(timestamp)
  return format(date, 'HH:mm')
}

const correctTimestampByDate = (timestamp, date) => {
  const savedDate = fromUnixTime(timestamp)
  const correctDate = set(savedDate, {
    year: getYear(date),
    month: getMonth(date),
    date: getDate(date)
  })

  return getUnixTime(correctDate)
}

class BusinessHourRow {

  constructor(props = {}) {
    Object.assign(this, defaultProps, props)
  }

  clone(props = {}) {
    return new BusinessHourRow(Object.assign({}, this, props))
  }

  addTimeRange(startAt, endAt) {
    const key = uuid()
    const timeRanges = this.timeRanges.slice()
    timeRanges.push({ key, startAt, endAt })
    return this.clone({ timeRanges })
  }

  removeTimeRange(key) {
    const timeRanges = this.timeRanges.filter(range => range.key !== key)
    return this.clone({ timeRanges })
  }

  getCurrentTimeRange() {
    const currentDate = new Date()
    const now = parseInt(+currentDate / 1000, 10)

    return this.timeRanges.find(range => {

      // startAt & endAt 要以當日計算
      const startAt = correctTimestampByDate(range.startAt, currentDate)
      const endAt = correctTimestampByDate(range.endAt, currentDate)

      return (startAt <= now) && (now <= endAt)
    })
  }

  get statusText() {
    if (this.open24Hours) {
      return this.open24HoursText
    }
    const timeRange = this.getCurrentTimeRange()
    if (timeRange) {
      const endText = format(unixTimeToCurrentDate(timeRange.endAt), 'a h:mm ', { locale })
      return `營業中 - 將於${endText}結束營業`
    }
    return '休息中'
  }

  get weekdayText() {
    const date = startOfWeek(new Date(), { weekStartsOn: this.weekday })
    return format(date, 'EEEE', { locale })
  }

  get open24HoursText() {
    return '24 小時營業'
  }

  get workStatusText() {
    return this.work ? '本日營業' : '本日公休'
  }

  get offText() {
    return '休息中'
  }

  get open24Hours() {
    return (this.timeRanges.length === 0)
  }

  get timeRangeTexts() {
    return this.timeRanges.map(timeRange => {
      return {
        key: timeRange.key,
        startAt: toTimeFormat(timeRange.startAt),
        endAt: toTimeFormat(timeRange.endAt)
      }
    })
  }
}

export default BusinessHourRow
