<template>
  <ul class="discount-lv-list">
    <li class="discount-lv-list__heading">滿額優惠活動</li>
    <li v-for="item in items" :key="item.id">
      <discount-lv-title :item="item"
                         :used="isUsed(item)"
                         :layout-discount="layoutDiscount"
                         v-bind="getItemAttrs(item)" />
    </li>
  </ul>
</template>

<script>
import DiscountLvTitle from '@landing/common/components/DiscountLvTitle'
import getNextDiscountLv from '@landing/common/models/DiscountLv/getNextDiscountLv'
import getUsedDiscountLv from '@landing/common/models/DiscountLv/getUsedDiscountLv'

export default {
  name: 'DiscountLvList',
  components: {
    DiscountLvTitle
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    totalOrderAmountWithCoupon: {
      type: Number,
      default: 0
    },
    layoutDiscount: String
  },
  computed: {
    nextItem() {
      return getNextDiscountLv(this.items, this.totalOrderAmountWithCoupon)
    },
    usedItem() {
      return getUsedDiscountLv(this.items, this.totalOrderAmountWithCoupon)
    }
  },
  methods: {
    getItemAttrs(item) {
      const { nextItem, totalOrderAmountWithCoupon } = this
      if (nextItem && (item.id === nextItem.id)) {
        return {
          shortBy: item.amount - totalOrderAmountWithCoupon
        }
      }
      return {}
    },
    isUsed(item) {
      const { usedItem } = this
      return usedItem && (item.id === usedItem.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.discount-lv-list {
  display: inline-block;
  background-color: #f4f4f4;
  border-radius: 4px;
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  li {
    padding: 5px 14px;
  }
}
.discount-lv-list__heading {
  font-size: 16px;
  font-weight: 500;
}
</style>
