import IntlAddressBase from './Base'

class UnknownAddress extends IntlAddressBase {

  constructor(props) {
    super(props)
  }

  toString() {
    return this.addr1
  }
}

export default UnknownAddress
