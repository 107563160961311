import { Observable } from 'rxjs'
import { retryWhen, delay, take } from 'rxjs/operators'

export default function retryWithDelay(fn, options = {}) {

  const delayDuration = options.delay || 1000
  const takeTimes = options.take || 5

  return Observable.create(observer => {
    try {
      fn()
    }
    catch (err) {
      return observer.error(err)
    }
    observer.next()
  })
  .pipe(
    retryWhen(errors => errors.pipe(delay(delayDuration), take(takeTimes)))
  )
}
