import { range, isStr } from '@landing-common/utils'

const maxImgDesc = 5

const defaultUnitList = range(1, 20 + 1).join(',')

const existed = value => isStr(value) && (value.length > 0)

class Product {

  constructor(row) {
    Object.assign(this, row)
    this._showAsAdditionalPurchase = false
  }

  get hasImg() {
    return this.imgRows.length > 0
  }

  get imgRows() {
    const arr = []
    const { image } = this
    if (existed(image)) {
      arr.push({
        key: 'product-img',
        text: '產品',
        src: image
      })
    }
    return arr.concat(this.imgDescRows)
  }

  get imgDescRows() {
    const imgPrefix = 'imageDesc'
    const textPrefix = 'imageDescText'
    return range(1, maxImgDesc + 1)
      .map(i => ({
        key: `product-img-desc-${i}`,
        text: this[`${textPrefix}${i}`],
        src: this[`${imgPrefix}${i}`]
      }))
      .filter(row => existed(row.src) && existed(row.text))
  }

  getQuantityOptionsByNum(num) {
    const { unitName } = this
    return range(1, num + 1)
      .map(unit => ({
        value: unit,
        text: `${unit} ${unitName}`
      }))
  }

  showAsAdditionalPurchase() {
    const newProduct = new Product(this)
    newProduct._showAsAdditionalPurchase = true
    return newProduct
  }

  get isAdditionalPurchase() {
    return this._showAsAdditionalPurchase
  }

  get hasSpec() {
    const { specs } = this
    return specs && (specs.length > 0)
  }

  get specOptions() {
    const specs = this.specs || []
    const getDesc = row => row.desc ? ` ( ${row.desc} )` : ''
    return specs.map(row => {
      return {
        status: row.status,
        value: row.id,
        text: (row.status === 'out_of_stock') ? `${row.title}${getDesc(row)} - 缺貨中` : `${row.title}${getDesc(row)}`
      }
    })
  }

  get quantityOptions() {
    const unitList = this.unitList || defaultUnitList
    const { unitName } = this
    return unitList.split(',')
      .map(unit => parseInt(unit, 10))
      .filter(unit => Number.isInteger(unit) && (unit > 0))
      .map(unit => ({
        value: unit,
        text: `${unit}${unitName} - ${this.price * unit}元`
      }))
  }

  get defaultSelectedSpecId() {
    if (! this.hasSpec) {
      return null
    }
    const { specOptions } = this
    const options = specOptions.filter(row => row.status !== 'out_of_stock')

    if (options.length > 0) {
      return options[0].value
    }

    const outOfStockOptions = specOptions.filter(row => row.status === 'out_of_stock')

    if (outOfStockOptions.length > 0) {
      return outOfStockOptions[0].value
    }
    return null
  }

  get defaultSelectedQuantity() {
    const [option] = this.quantityOptions
    return option ? option.value : null
  }

  get key() {
    if (this._showAsAdditionalPurchase) {
      return `additional-purchase-product-${this.id}`
    }
    return `product-${this.id}`
  }
}

export default Product
