import { NEWEST_FIRST } from '@landing/landing-common/consts/sortMethods'
import Product from '@landing/landing-common/models/Product'

export const SHOW_ORDER_ITEM_MENU = '@landing-desktop/app/SHOW_ORDER_ITEM_MENU'
export const HIDE_ORDER_ITEM_MENU = '@landing-desktop/app/HIDE_ORDER_ITEM_MENU'

export const SHOW_FREE_PRODUCT_SET_MENU = '@landing-desktop/app/SHOW_FREE_PRODUCT_SET_MENU'
export const HIDE_FREE_PRODUCT_SET_MENU = '@landing-desktop/app/HIDE_FREE_PRODUCT_SET_MENU'

export const SET_ONLINE_STATUS = '@landing-desktop/app/SET_ONLINE_STATUS'

export const SET_SORT_METHOD = '@landing-desktop/app/SET_SORT_METHOD'
export const CLEAR_CURRENT_PROMO_PRODUCT = '@landing-desktop/app/CLEAR_CURRENT_PROMO_PRODUCT'
export const SET_SELECTED_PROMO_PRODUCT = '@landing-desktop/app/SET_SELECTED_PROMO_PRODUCT'

export const SHOW_CART_MODAL = '@landing-desktop/app/SHOW_CART_MODAL'
export const HIDE_CART_MODAL = '@landing-desktop/app/HIDE_CART_MODAL'

export const SET_SITE_NAV_HEIGHT = '@landing-desktop/app/SET_SITE_NAV_HEIGHT'

export const SET_TRACKER_LOADED = '@landing-desktop/app/SET_TRACKER_LOADED'

export const setTrackerLoaded = loaded => {
  return {
    type: SET_TRACKER_LOADED,
    loaded
  }
}

export const setSiteNavHeight = ({ siteNavHeight, navbarHeight, shrank }) => {
  return {
    type: SET_SITE_NAV_HEIGHT,
    shrank,
    siteNavHeight,
    navbarHeight
  }
}

export const clearSelectedPromoProduct = () => {
  return {
    type: CLEAR_CURRENT_PROMO_PRODUCT
  }
}

export const setSortMethod = method => {
  return {
    type: SET_SORT_METHOD,
    method
  }
}

export const setOnlineStatus = online => {
  return {
    type: SET_ONLINE_STATUS,
    online
  }
}

export const showOrderItemMenu = item => {
  return {
    type: SHOW_ORDER_ITEM_MENU,
    item
  }
}

export const hideOrderItemMenu = () => {
  return {
    type: HIDE_ORDER_ITEM_MENU
  }
}

export const showFreeProductSetMenu = () => {
  return {
    type: SHOW_FREE_PRODUCT_SET_MENU
  }
}

export const hideFreeProductSetMenu = () => {
  return {
    type: HIDE_FREE_PRODUCT_SET_MENU
  }
}

export const setSelectedPromoProduct = product => {
  return {
    type: SET_SELECTED_PROMO_PRODUCT,
    product
  }
}

export const showCartModal = () => {
  return {
    type: SHOW_CART_MODAL
  }
}

export const hideCartModal = () => {
  return {
    type: HIDE_CART_MODAL
  }
}

const initialState = {
  navbarHeight: 0,
  siteNavHeight: 0,
  shrankSiteNavHeight: 0,
  online: true,
  isOrderItemMenuVisible: false,
  isFreeProductSetMenuVisible: false,
  isCartModalVisible: false,
  selectedOrderItem: null,
  currentPromoProduct: null,
  sortMethod: NEWEST_FIRST,
  trackerLoaded: false
}

export default function app(state = initialState, action) {
  switch (action.type) {
    case SET_TRACKER_LOADED:
      return { ...state, trackerLoaded: action.loaded }
    case SET_SITE_NAV_HEIGHT: {
      if (action.shrank) {
        return {
          ...state,
          shrankSiteNavHeight: action.siteNavHeight,
          navbarHeight: action.navbarHeight
        }
      }
      return {
        ...state,
        siteNavHeight: action.siteNavHeight,
        navbarHeight: action.navbarHeight
      }
    }
    case CLEAR_CURRENT_PROMO_PRODUCT:
      return { ...state, currentPromoProduct: null }
    case SET_SORT_METHOD:
      return { ...state, sortMethod: action.method }
    case SET_ONLINE_STATUS:
      return { ...state, online: action.online }
    case SHOW_ORDER_ITEM_MENU:
      return {
        ...state,
        isOrderItemMenuVisible: true,
        selectedOrderItem: action.item
      }
    case HIDE_ORDER_ITEM_MENU:
      return { ...state, isOrderItemMenuVisible: false }
    case SHOW_FREE_PRODUCT_SET_MENU:
      return { ...state, isFreeProductSetMenuVisible: true }
    case HIDE_FREE_PRODUCT_SET_MENU:
      return { ...state, isFreeProductSetMenuVisible: false }
    case SET_SELECTED_PROMO_PRODUCT:
      return { ...state, currentPromoProduct: new Product(action.product) }
    case SHOW_CART_MODAL:
      return { ...state, isCartModalVisible: true }
    case HIDE_CART_MODAL:
      return { ...state, isCartModalVisible: false }
    default:
      return state
  }
}
