class Promo {

  constructor(row) {
    Object.assign(this, row)
  }

  get price() {
    return this.promoDetails.price
  }

  get originalPrice() {
    return this.promoDetails.originalPrice
  }

  get quantityOptions() {
    return []
  }

  get key() {
    return `promo-${this.id}`
  }
}

export default Promo
