// @superlanding
import comma from '@superlanding/commaseparate'
import getScrollTop from '@superlanding/getscrolltop'
import toPixel from '@superlanding/topixel'
import extend from '@superlanding/extend'

// lodash
import get from 'lodash.get'
import isFunction from 'lodash.isfunction'
import range from 'lodash.range'
import throttle from 'lodash.throttle'
import keyBy from 'lodash.keyby'

// others
import { DateTime } from 'luxon'
import { PulseLoader } from '@saeris/vue-spinners'
import axios from 'axios'
import uuid from 'uuid/v4'

import isStr from '@common/utils/isStr'

export {
  // @superlanding
  comma,
  extend,
  getScrollTop,
  toPixel,

  // lodash
  get,
  isFunction,
  range,
  throttle,
  keyBy,

  // others
  DateTime,
  PulseLoader,
  uuid,
  isStr,
  axios
}
