/* globals Modernizr */
// cartMixin requires domMixin to work
import { addOrderProduct, addOrderPromo,
  getCartData, addFreeProductSet } from '../redux/reducers/cart'
import { showCartModal, hideCartModal, showFreeProductSetMenu,
  hideFreeProductSetMenu, hideOrderItemMenu } from '../redux/reducers/app'
import { setProductSpecStatus } from '../redux/reducers/page'
import HumanError from '@landing/landing-common/models/HumanError'
import { getDomPos, toPixel, throttle } from '../utils'

export default {
  data() {
    return {
      freeProductSet: null,
      freeProductSetOrderProductId: null,
      tooltipMessage: '',
      ...this.mapState({
        cart: 'cart.cart',
        cartItems: 'cart.cart.items',
        isCartModalVisible: 'app.isCartModalVisible',
        isFreeProductSetMenuVisible: 'app.isFreeProductSetMenuVisible',
        isLoadingCart: 'cart.isLoadingCart',
        isOrderItemMenuVisible: 'app.isOrderItemMenuVisible',
        page: 'page.page',
        selectedOrderItem: 'app.selectedOrderItem'
      })
    }
  },
  beforeCreate() {
    this.mapDispatch({
      addFreeProductSet,
      addOrderProduct,
      addOrderPromo,
      getCartData,
      hideCartModal,
      hideFreeProductSetMenu,
      hideOrderItemMenu,
      setProductSpecStatus,
      showCartModal,
      showFreeProductSetMenu
    })
    this._isFlyingItemToCart = false
    this._cartBtnPos = null
    this._tooltipTimer = null
    this._cancelTimer = null
    this._scrollTimes = 0
  },
  created() {
    this._initCart()
  },
  mounted() {
    this._initScrollEvent()
  },
  methods: {
    async _initCart() {
      this.useFlyingAnimation = this._isFlyingAnimationSupported()
      if (this.isClient) {
        try {
          await this.getCartData()
          this._showFreeProductSetMenuIfNeeded()
        }
        catch (err) {
          const message = HumanError.getMessage(err)
          if (message) {
            this.addToastItem({ message })
          }
        }
        if (typeof this.cartDataReady === 'function') {
          this.cartDataReady()
        }
      }
    },
    _initScrollEvent() {
      this.on(window, 'scroll', throttle(this._handleScroll, 100))
      if (this.useFlyingAnimation) {
        this.on(window, 'transitionend', this._handleTransitionEnd)
      }
    },
    _removeAnimatingNode() {
      const node = this.animatingNode
      if (node) {
        this._isFlyingItemToCart = false
        node.parentNode.removeChild(node)
        this.animatingNode = null
      }
    },
    _handleTransitionEnd({ target, propertyName }) {
      const node = this.animatingNode
      if ((target === node) && (propertyName === 'transform')) {
        if (this._cancelTimer) {
          clearTimeout(this._cancelTimer)
          this._cancelTimer = null
        }
        this._removeAnimatingNode()
      }
    },
    handleFreeProductSetMenuSubmit(selectedProductsMap) {
      const { qty } = this.freeProductSet
      const selectedQty = Object.values(selectedProductsMap)
        .reduce((prev, curr) => prev + curr, 0)

      if (selectedQty < qty) {
        return this.showAlert({
          message: `你還可以再選 ${qty - selectedQty} 個!`,
          btnText: '好'
        })
      }
      if (selectedQty > qty) {
        return this.showAlert({
          message: `只能選 ${qty} 個, 你選了 ${selectedQty} 個!`,
          btnText: '好'
        })
      }
      const data = Object.keys(selectedProductsMap)
        .map(prop => ({ id: parseInt(prop, 10), qty: selectedProductsMap[prop] }))

      const orderProductId = this.freeProductSetOrderProductId

      this.addFreeProductSet(orderProductId, data)
        .then(() => {
          this.freeProductSet = null
          this.freeProductSetOrderProductId = null
        })
      this.hideFreeProductSetMenu()
    },
    handleSendToCart({ item, dom }) {
      const data = {
        id: item.id,
        type: 'product',
        title: item.title,
        image: item.image,
        qty: 1,
        price: item.price,
        isAdditionalPurchase: item.isAdditionalPurchase
      }
      const specs = item.specs || []
      if (specs.length > 0) {
        data.specId = item.specs[0].id
      }
      this.addItemToCart(data, dom)
    },
    async addItemToCart(data, animatingDom = null) {

      const isProduct = (data.type === 'product')

      if (this._tooltipTimer) {
        clearTimeout(this._tooltipTimer)
        this._tooltipTimer = null
      }
      this._scrollTimes = 0

      try {
        let res
        if (isProduct) {
          res = await this.addOrderProduct(data)
          this.tooltipMessage = '已新增商品到購物車'
        }
        else {
          res = await this.addOrderPromo(data)
          this.tooltipMessage = '已新增組合到購物車'
        }
        if (animatingDom) {
          this._flyItemToCart(animatingDom)
        }
        if (this.isOrderItemMenuVisible) {
          this._flyItemToCart(this.$refs.orderItemMenu.$refs.menu.$el, 'fixed')
          this.hideOrderItemMenu()
        }

        this._tooltipTimer = setTimeout(() => this.tooltipMessage = '', 3000)
        const { id, title, price, qty } = res.payload.orderProduct
        const { cart } = this
        const { currency } = this.page

        this.$tracker.addToCart({
          productId: id,
          title,
          price,
          qty,
          totalAmount: qty * price,
          currency,
          cart
        })
      }
      catch (err) {
        const specIdError = this.get(err, 'payload.response.data.errors.specId[0]')
        if (specIdError) {
          this.addToastItem({ message: specIdError })
          if (specIdError === '此產品規格可能已被刪除，請重新選擇！') {
            this.setProductSpecStatus({
              productId: data.id,
              specId: data.specId,
              status: 'out_of_stock'
            })
          }
          return
        }
        const message = HumanError.getMessage(err)
        if (message) {
          this.addToastItem({ message })
        }
      }
      this._showFreeProductSetMenuIfNeeded()
    },
    _handleScroll() {
      // 關閉新增商品視窗可能會觸發 _handleScroll
      // 捲動觸發三次才視為一般使用者
      this._scrollTimes += 1
      if (this._scrollTimes > 3) {
        this.tooltipMessage = ''
        this._scrollTimes = 0
      }
    },
    _showFreeProductSetMenuIfNeeded() {

      const itemWithfreeProductSet = this.cartItems.find(item => {
        return item.freeProductSet && (! item.freeProductSet.used)
      })

      if (itemWithfreeProductSet) {
        this.freeProductSetOrderProductId = itemWithfreeProductSet.id
        this.freeProductSet = itemWithfreeProductSet.freeProductSet
        this.showFreeProductSetMenu()
      }
    },
    async handleCartClick() {
      this.tooltipMessage = ''
      try {
        await this.getCartData()
        if (this.cartItems.length === 0) {
          this.showConfirm({
            message: '您目前尚未選購商品無法結帳，是否直接選購商品？',
            confirmBtnText: '好喔',
            cancelBtnText: '先不用',
            confirm: () => this.scrollToProductList()
          })
        }
        else {
          this.showCartModal()
        }
      }
      catch (err) {
        const message = HumanError.getMessage(err)
        if (message) {
          this.addToastItem({ message })
        }
      }
    },
    reloadCart() {
      this.getCartData()
    },
    setCartBtnPos(pos) {
      this._cartBtnPos = pos
    },
    _isFlyingAnimationSupported() {
      if (this.isServer) {
        return false
      }
      if (! Modernizr.csstransitions) {
        return false
      }
      if (! ('ontransitionend' in window)) {
        return false
      }
      // blocks all IE and Edge
      // Edge has window.ontransitionend
      // but never returns event.propertyName as transform and correct event.target
      if (document.documentMode || /Edge/.test(navigator.userAgent)) {
        return false
      }
      return true
    },
    _flyItemToCart(dom, position = 'absolute') {
      if (! this.useFlyingAnimation) {
        return
      }
      if (this._isFlyingItemToCart) {
        return
      }
      this._isFlyingItemToCart = true

      const pos = getDomPos(dom)
      const rect = dom.getBoundingClientRect()
      const node = dom.cloneNode(true)

      this.animatingNode = node

      node.style.transitionProperty = 'transform'
      node.style.transitionDuration = '.3s'
      node.style.perspective = 1000
      node.style.backfaceVisibility = 'hidden'
      node.style.transformOrigin = 'left top'
      node.style.opacity = 0
      node.style.width = toPixel(dom.offsetWidth)
      node.style.height = toPixel(dom.offsetHeight)
      node.style.position = position
      node.style.left = toPixel(pos.x)
      node.style.top = toPixel(pos.y)
      document.body.appendChild(node)

      setTimeout(() => {

        const { _cartBtnPos } = this
        node.style.opacity = 1

        let x = window.innerWidth - 90
        let y = window.innerHeight - 210

        if (_cartBtnPos) {
          x = _cartBtnPos.x
          y = _cartBtnPos.y
        }
        const left = toPixel(x - rect.x)
        const top = toPixel(y - rect.y)
        const ratio = (50 / rect.height).toFixed(2)
        node.style.transform = `translate3d(${left}, ${top}, 0) scale(${ratio})`
      }, 100)

      // for browsers that has an older
      // version of transition implementation
      this._cancelTimer = setTimeout(() => {
        this._removeAnimatingNode()
      }, 500)
    }
  }
}
