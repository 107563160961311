import TaiwanAddress from './IntlAddress/Taiwan'
import MalaysiaAddress from './IntlAddress/Malaysia'
import HongKongAddress from './IntlAddress/HongKong'
import MacauAddress from './IntlAddress/Macau'
import SingaporeAddress from './IntlAddress/Singapore'
import UnknownAddress from './IntlAddress/Unknown'

class IntlAddress {

  static from(props = {}) {
    switch (props.country) {
      case 'Taiwan':
        return TaiwanAddress.from(props)
      case 'Malaysia':
        return MalaysiaAddress.from(props)
      case 'Hong Kong':
        return HongKongAddress.from(props)
      case 'Macau':
        return MacauAddress.from(props)
      case 'Singapore':
        return SingaporeAddress.from(props)
      default:
        return UnknownAddress.from(props)
    }
  }
}

export default IntlAddress
