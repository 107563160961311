export const MARK_ANCHOR = '__ANCHOR__'

class TagConverter {

  static elToTagRow(el) {
    switch (el.tagName) {
      case 'A':
        return {
          type: MARK_ANCHOR,
          text: el.textContent,
          url: el.getAttribute('href'),
          target: el.getAttribute('target'),
          style: el.getAttribute('style'),
          anchorId: el.dataset.anchorId
        }
      default:
        throw new Error(`Undefined el.tagName: ${el.tagName}`)
    }
  }

  static tagRowToEl(row) {
    switch (row.type) {
      case MARK_ANCHOR: {
        const a = document.createElement('a')
        a.textContent = row.text
        a.href = row.url
        a.target = '_blank'
        a.dataset.anchorId = row.anchorId
        if (row.style) {
          a.setAttribute('style', row.style)
        }
        return a
      }
      default:
        throw new Error(`Undefined row.type for tagRowToEl: ${row.type}`)
    }
  }

  static tagRowToHtml(row) {
    switch (row.type) {
      case MARK_ANCHOR: {
        const targetAttr = row.target ? ` target="${row.target}"` : ''
        const styleAttr = row.style ? ` style="${row.style}"` : ''
        return `<a href="${row.url}"${targetAttr}${styleAttr}>${row.text}</a>`
      }
      default:
        throw new Error(`Undefined row.type for tagRowToHtml: ${row.type}`)
    }
  }
}

export default TagConverter
